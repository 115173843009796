<template>
  <div v-if="available && title" class="w-100 text-center lead">{{ item.name }}</div>
  <div class="form-text text-danger">{{ error }}</div>
  <live-table
      v-if="available"
      :store="store"
      ref="table"
      :url="table_url"
      :format="table_format"
      :nonsortable="!item.sortable"
      :downloadable="true"
      :morecount="[50,100,500]"
      @click-item="onClickItem"
      :transpose="item.transpose"
      :params="params"
      :chart="hideChart?null:item.chart"
      :default_filter="applied_filter"
      :hide-table="hideTable"
      :hide-controls="hideControls"
      :single-mode="item.single_mode"
      :aspect="aspect"
      :name="table_name"
  />
  <div v-else>Нет данных</div>
</template>

<script>
import {http} from "@/misc/http";
import LiveTable from "../../components/LiveTable";
import {format_datetime_for_filename} from "@/misc/util";


export default {
  components: {LiveTable},
  props: ["id", "store", "defaultFilterTable", "reportParams", "hideTable", "hideChart", "hideControls", "aspect", "title"],
  data() {
    return {
      item: null, // report
      data: null, // one line of report data
      error: "",
      applied_filter: null,
      time_mode: null,

    }
  },

  async created() {
    this.applied_filter = this.defaultFilterTable;
    await this.initTable();
  },

  methods: {
    async initTable() {
      try {
        this.item = await http.get('/reports/' + this.id);
        this.data = await http.get('/reports/' + this.id + '/execute', {
          start: 0,
          count: 1,
          ...this.params,
          ...this.applied_filter,
        });
      } catch (e) {
        this.error = e.message;
      }
    },

    doFilter(event, timeMode) {
      this.applied_filter = event;
      this.time_mode = timeMode;
      if (this.available) {
        this.$refs.table.doFilter(event);
      } else {
        this.initTable();
      }
    },

    onClickItem(event) {
      if (this.item.clickable) {
        const callID = event.item.id;
        if (callID)
          this.$router.push({name: 'rcall', params: {id: callID}});
        else
          alert("Не найдено поле id для перехода.")
      }
    },

    async makeReport() {
      if (!this.available) throw Error("Отчет недоступен");
      let content = this.item.name + "\n";
      content += await this.$refs.table.makeCSV() + "\n";
      content += this.item.help + "\n";
      return content;
    },

  },

  computed: {
    available() {
      return !!this.data?.items.length;
    },
    table_format() {
      const result = [];
      for (const k in this.data.items[0]) {
        result.push([k, k, (i) => i[k]]);
      }
      return result;
    },
    table_url() {
      return "/reports/" + this.id + "/execute";
    },
    params() {
      return {
        tz_minutes: -(new Date()).getTimezoneOffset(),
        report_params: this.reportParams
      }
    },
    table_name() {
      let s = this.item?.name || "";
      if (this.applied_filter?.t_from)
        s += "_с_" + format_datetime_for_filename(this.applied_filter?.t_from, !this.time_mode);
      if (this.applied_filter?.t_to)
        s += "_до_" + format_datetime_for_filename(this.applied_filter?.t_to, !this.time_mode);
      return s

    },


  },


}
</script>

