function sline(x0, y0, x1, y1) {
  const a = y0 - y1;
  const b = x1 - x0;
  const p = Math.sqrt(a ** 2 + b ** 2)

  return {
    a: a / p,
    b: b / p,
    c: (x0 * y1 - x1 * y0) / p
  }
}

function crossv(sline, x, y0, y1) {
  if (Math.abs(sline.b) < 0.001) return null;
  const y = -(sline.a * x + sline.c) / sline.b;
  if ((y >= y0 && y <= y1) || (y >= y1 && y <= y0)) return {x: x, y: y}
  return null;
}

function crossh(sline, y, x0, x1) {
  if (Math.abs(sline.a) < 0.001) return null;
  const x = -(sline.b * y + sline.c) / sline.a;
  if ((x >= x0 && x <= x1) || (x >= x1 && x <= x0)) return {x: x, y: y}
  return null;
}


function cross_box(sline, x0, y0, x1, y1) {
  const result = [];
  const p0 = crossv(sline, x0, y0, y1);
  if (p0) result.push(p0);
  const p1 = crossh(sline, y0, x0, x1);
  if (p1) result.push(p1);
  const p2 = crossv(sline, x1, y0, y1);
  if (p2) result.push(p2);
  const p3 = crossh(sline, y1, x0, x1);
  if (p3) result.push(p3);
  return result;
}

function connect(b0, b1) {
  const l = sline((b0.x1 + b0.x0) / 2, (b0.y1 + b0.y0) / 2, (b1.x1 + b1.x0) / 2, (b1.y1 + b1.y0) / 2);
  const pa0 = cross_box(l, b0.x0, b0.y0, b0.x1, b0.y1);
  const pa1 = cross_box(l, b1.x0, b1.y0, b1.x1, b1.y1);
  let dist = null;
  let pr0 = null, pr1 = null;

  for (let i = 0; i < pa0.length; i++) {
    for (let j = 0; j < pa1.length; j++) {
      const p0 = pa0[i];
      const p1 = pa1[j];
      const d = (p0.x - p1.x) ** 2 + (p0.y - p1.y) ** 2;
      if (dist === null || d < dist) {
        dist = d;
        pr0 = p0;
        pr1 = p1;
      }
    }
  }

  if (!pr0 || !pr1) {
    console.log("Error connecting boxes.", JSON.stringify(b0), JSON.stringify(b1));
    return {x0: 0, y0: 0, x1: 1, y1: 1};
  }

  return {x0: pr0.x, y0: pr0.y, x1: pr1.x, y1: pr1.y};
}

// Connects 2 boxes with stright line,
export {connect}