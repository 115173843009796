<template>
  <page-header title="Отчеты" icon="bi-file-bar-graph"/>
  <div class="form-text text-danger">{{ error }}</div>

  <fl-input v-model="contains" label="Искать"/>
  <simple-table
      :items="reportsFiltered"
      :format="[['id', 'ID', i=>i.id], ['name', 'Имя', i=>i.name]]"
      @click-item="execute"
  />
</template>

<script>
import {http} from "@/misc/http"
import PageHeader from "@/components/PageHeader";
import {tstore} from "@/tstore";
import SimpleTable from "@/components/SimpleTable";
import FlInput from "@/pages/flow/forms/components/FlInput";

export default {
  components: {FlInput, SimpleTable, PageHeader},
  data() {
    return {
      error: "",

      reports: null,
      contains: "",
    }
  },
  methods: {

    execute(report) {
      delete tstore.report; // очищаем стор так как мог поменяться формат выдачи todo: звиздец лайфхак, хоть бы метод сделал, сам запутался
      this.$router.push({name: 'report', params: {id: report.id}})
    },
  },


  computed: {
    reportsFiltered() {
      return this.reports?.filter(
              report => this.contains ? ("" + report.id + report.name).toLowerCase().includes(this.contains.toLowerCase()) : true
          )
    },
  },
  async created() {
    try {
      const reports = await http.get('/reports');
      this.reports = reports.filter(report => !report.special);
      if (this.reports?.length) this.report_id = this.reports[0].id;
    } catch (e) {
      this.error = e.message;
    }
  },

}
</script>

