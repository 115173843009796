<template>
  <page-header :title="title" icon="bi-people-fill" :back="true"/>
  <f-form ref="form" :error="error" :new-mode="id=='new'" @send="onSend" :nondeletable="true">
    <template v-slot:default="form">
      <f-input v-model="item.name" label="Название" w="9" :disabled="!form.edit"/>
      <f-input v-model="item.domain" label="Домен" w="3" :disabled="!form.edit"/>
      <f-input v-model="item.comment" type="textarea" label="Комментарий" :disabled="!form.edit"/>
      <div class="col">
        <div class="form-check form-switch">
          <input class="form-check-input" type="checkbox" v-model="item.enabled" :disabled="!form.edit">
          <label class="form-check-label">Активный</label>
        </div>
      </div>

    </template>
  </f-form>
</template>

<script>
import {http} from "@/misc/http";
import FInput from "@/components/FInput";
import {format_datetime} from "@/misc/util";
import PageHeader from "@/components/PageHeader";
import FForm from "@/components/FForm";
import {updateItem} from "../../misc/util";

export default {
  components: {FForm, PageHeader, FInput},
  props: ["id"],
  data() {
    return {
      item: {
        name: "Новая",
        comment: "",
        created_at: "",
        domain: "",
        enabled: true
      },
      error: "",
    }
  },

  created() {
    if (!this.isNew) this.updateItem(http.get('/projects/' + this.id));
  },

  methods: {
    onSend() {
      this.updateItem(
          http.post("/projects" + (this.isNew ? '' : '/' + this.id), this.item),
          "project", true
      );
    },

    format_datetime,
    updateItem
  },

  computed: {
    title() {
      return "Компания \"" + this.item.name + "\"";
    },
    isNew() {
      return this.id == "new";
    },
  },


}
</script>

