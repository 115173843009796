<template>
  <div class="user-select-none">
    <div class="d-flex">
      <div class="flex-grow-1">
        <h6 @click="visible=!visible" class="form-label" style="cursor: pointer">
          <i class="bi" :class="'bi-chevron-' + (visible?'down':'right')"/>&nbsp;{{ name }}
        </h6>
      </div>

      <icon-button @click="$emit('do-delete')" v-if="deletable" icon="bi-trash" title="Удалить"/>

    </div>
    <slot v-if="visible"/>
    <hr>
  </div>
</template>

<script>
import IconButton from "../../../../components/IconButton";

export default {
  components: {IconButton},
  props: ["name", "deletable"],
  emits: ["do-delete"],
  data() {
    return {
      visible: true
    }
  },

}
</script>

<style scoped>

</style>