import {http} from "@/misc/http";
import router from "@/router";
import jwtDecode from "jwt-decode";

let timer = null;

function setAutoLogout(context) {

  if (timer) clearTimeout(timer);

  const expires = context.getters.expires;
  let t = expires - new Date().getTime();
  if (t > 0) {
    timer = setTimeout(() => {
      context.dispatch("logout", {redirect: window.location.pathname});
    }, t);
  } else {
    context.dispatch("logout", {redirect: window.location.pathname});
  }

  return true;
}

function clearAutoLogout() {
  if (timer) clearTimeout(timer);
}

const auth = {

  namespaced: true,

  state() {
    return {
      token: null,
      tokenContent: null,
      user: null
    }
  },

  getters: {

    isLoggedIn(state) {
      return !!state.token;
    },

    token(state) {
      return state.token;
    },

    token_content(state) {
      return state.tokenContent;
    },

    expires(state) {
      return state.tokenContent ? state.tokenContent.expires : null;
      // return new Date("2023-03-24T00:57:10");
    },

    userId(state) {
      return state.tokenContent ? state.tokenContent.id : null;
    },

    userRole(state) {
      return state.tokenContent ? state.tokenContent.role : null;
    },

    user(state) {
      return state.user || {fname: "", lname: ""};
    },

    privileged(state) {
      return ['Суперадмин', 'Аналитик'].includes(state.tokenContent?.role);
    },

    privilege(state) {
      return state.user?.privilege;
    },

    userDomain(state, getters) {
      return getters.privileged ? null : state.tokenContent?.domain;
    },

    userProject(state, getters) {
      return getters.privileged ? null : state.tokenContent?.dl_project_id;
    },

  },

  mutations: {
    clearToken(state) {
      state.token = null;
      state.tokenContent = null;
    },

    setToken(state, payload) {
      const token = payload.token;

      let token_content;
      try {
        token_content = jwtDecode(token);
      } catch (e) {
        state.token = null;
        state.tokenContent = null;
        throw Error("Can not decode jwt.");
      }

      state.token = token;
      state.tokenContent = token_content;

      // state.tokenContent.expires = new Date().getTime() + 5000;
    },

    setUser(state, payload) {
      state.user = payload;
    }
  },

  actions: {
    async login(context, payload) {

      const resp = await http.post_form("/auth/login", {
        username: payload.username,
        password: payload.password
      })

      const token = resp.access_token;

      context.commit("setToken", {token: token});
      localStorage.setItem("token", token);
      context.dispatch("setCookie");

      await context.dispatch("loadUser");

      setAutoLogout(context);

    },

    setCookie(context) {
      document.cookie = "token=" + context.getters.token + ";path=/";
    },

    clearCookie() {
      document.cookie = "token=0;path=/";

    },

    logout(context, payload) {
      localStorage.removeItem("token");
      context.commit("clearToken");
      clearAutoLogout();
      context.dispatch("clearCookie");
      router.push({"name": "signin", ...(payload ? {query: {redirect: payload.redirect}} : {})});
    },

    tryLogin(context) {
      const token = localStorage.getItem("token");
      if (token) {
        context.commit("setToken", {token: token});
        context.dispatch("setCookie");
        context.dispatch("loadUser");
        setAutoLogout(context);
      }
    },

    async loadUser(context) {
      const userId = context.getters.userId;
      if (userId) {
        const user = await http.get('/profiles/' + userId);
        context.commit("setUser", user);
      }
    }
  }

}

export default auth;