<template>
  <page-header :title="title" icon="bi-file-bar-graph" :back="true">
    <icon-button v-if="item?.help" icon="bi-question-circle fs-2" @click="helpVisible=!helpVisible" :active="helpVisible"/>
  </page-header>

  <!--  {{ item }}-->

  <div class="form-text text-danger">{{ error }}</div>

  <div v-if="item?.help && helpVisible" class="card mb-2">
    <div class="card-body">
      <div v-html="item?.help"/>
    </div>
  </div>

  <calls-filter
      ref="filter"
      v-if="item?.show_filter"
      @filter="doFilter"
      :show_what="item?.show_what"
      :default_filter="defaultFilter"
      :default_time_mode="defaultTimeMode"
  />

  <form v-if="item?.special">
    <f-input v-model="item.report_params" type="textarea" label="Параметры"/>
    <button @click.prevent="doReload" type="button" class="btn btn-primary mt-2">Применить</button>
  </form>

  <single-report
      v-if="item?.mode=='report'"
      :id="id"
      :ref="setReportRef"
      store="report"
      :default-filter-table="defaultFilterTable"
      :report-params="item.report_params"
  />

  <div v-if="item?.mode=='dashboard'">
    <div v-for="i in Math.floor(item.reports.length/columns)+1" :key="'i'+i" class="row">
      <div
          v-for="j in Math.min(columns, item.reports.length - (i-1)*columns)"
          :key="'j'+i+j"
          :class="colClass"
          :set="reportIndex=(i-1)*columns+j-1"
      >
        <single-report
            :id="item.reports[reportIndex]"
            :ref="setReportRef"
            :default-filter-table="defaultFilterTable"
            :report-params="item.report_params"
            :hide-table="item.reports_params[reportIndex].mode=='chart'"
            :hide-chart="item.reports_params[reportIndex].mode=='table'"
            :hide-controls="true"
            :aspect="aspect"
            :title="true"
        />

      </div>
    </div>

    <div v-if="downloadable" class="text-center">
      <button @click="download" type="button" class="btn btn-primary">Скачать</button>

    </div>

  </div>

</template>

<script>
import PageHeader from "@/components/PageHeader";
import CallsFilter from "../calls/CallsFilter";
import IconButton from "../../components/IconButton";
import FInput from "../../components/FInput";
import {http} from "@/misc/http";
import SingleReport from "@/pages/reports/SingleReport";
import {clickDownloadCSV, truncDate} from "@/misc/util";


export default {
  components: {SingleReport, FInput, IconButton, CallsFilter, PageHeader},
  props: ["id"],
  data() {
    return {
      item: null, // report
      error: "",
      helpVisible: false,
      reportsRefs: [],
    }
  },

  async created() {
    try {
      this.item = await http.get('/reports/' + this.id);
    } catch (e) {
      this.error = e.message;
    }
  },

  beforeUpdate() {
    this.reportsRefs = [];
  },

  // updated() {
  //   console.log(this.reportsRefs)
  // },

  methods: {
    setReportRef(el) {
      if (el) {
        this.reportsRefs.push(el);
      }
    },

    doFilter(e) {
      for (let r of this.reportsRefs) r.doFilter(e, this.$refs.filter.timeMode);
    },

    doReload() {
      for (let r of this.reportsRefs) r.$refs.table.doReload();
    },

    async download() {

      if (!this.toDownload.every(i => this.reportsRefs[i]?.available)) {
        alert("Дождитесь загрузки всех отчетов.");
        return;
      }

      try {
        const content = (await Promise.all(
            this.toDownload.map(async i => await this.reportsRefs[i].makeReport())
        )).join("\n");
        clickDownloadCSV("dashboard.csv", content);
      } catch (e) {
        this.error = e.message;
      }
    },

  },

  computed: {

    title() {
      return (this.item?.mode == 'dashboard' ? "Дашборд" : "Отчет") + " \"" + (this.item?.name || "") + "\"";
    },

    defaultFilter() {
      const filter = {
        interval: this.item.default_interval,
      };
      if (this.item.default_range) {
        let date = truncDate(Date(), this.item.default_range, true);
        date = new Date(date.valueOf() - (date.getTimezoneOffset() * 60000));
        filter.t_from = date.toISOString().substring(0, this.defaultTimeMode ? 16 : 10);
      }
      return filter;
    },

    defaultTimeMode() {
      if (!this.item.default_range) return null;
      return this.item.default_range == 'hour';
    },

    defaultFilterTable() {
      const filter = {
        interval: this.item.default_interval,
      };
      if (this.item.default_range) {
        filter.t_from = truncDate(Date(), this.item.default_range, false).toISOString();
      }
      return filter;
    },

    columns() {
      return this.item.columns;
    },

    colClass() {
      return {
        1: 'col-12',
        2: 'col-6',
        3: 'col-4',
        4: 'col-3',
        6: 'col-2',
        12: 'col-1'
      }[this.columns];
    },

    aspect() {
      return {
        1: 2,
        2: 1.5,
        3: 1,
        4: 0.75,
        6: 0.5,
        12: 0.3
      }[this.columns];
    },

    toDownload() {
      const result = [];
      if (this.item) {
        for (let i = 0; i < this.item.reports_params.length; i++) {
          if (this.item.reports_params[i].mode != 'chart') result.push(i);
        }
      }
      return result;
    },

    downloadable() {
      return !!this.toDownload.length;
      // return this.toDownload.length && this.toDownload.every(i => this.reportsRefs[i]?.available);
    },

  }
}
</script>

