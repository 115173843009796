<template>
  <div
      ref="main"
      class="d-flex align-items-center justify-content-center shadow"
      style="width: 32px; height: 34px"
      draggable="true"
      data-bs-placement="right"
      :title="title"
      @dragstart="$event.dataTransfer.setData('text/plain', name);"
  >
    <i :class="['bi', 'bi-'+icon]" style="font-size: 24px" :style="{color: color}"/>

  </div>

</template>

<script>
import {Tooltip} from "bootstrap";

export default {
  props: ['icon', 'name', 'title', 'color'],
  mounted() {
    this.tooltip = new Tooltip(this.$refs.main);
  },


}
</script>
