<template>
  <div
      v-if="isVisible"
      class="fs-3 align-self-center"
      style="position: fixed; bottom: 10px; transform: translate(-50%, 0)"
      :style="{left: 'calc(var(--menu-width)/2 + ' + offsetY + 'px)' }"
  >
    <icon-button icon="bi-arrow-up-circle" @click="doScroll"/>
  </div>
</template>

<script>
import IconButton from "@/components/IconButton";

export default {
  components: {IconButton},
  data() {
    return {
      isVisible: false,
      offsetY: 10,
    }
  },
  beforeMount() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      this.isVisible = document.documentElement.scrollTop > 100;
      this.offsetY = -document.documentElement.scrollLeft;
    },
    doScroll() {
      window.scrollTo(0, 0);

    }
  },
}
</script>

<style scoped>

</style>