<template>
  <div
      ref="editor"
      class="shadow user-select-none bg-body editor"
      :class="{grid: prefs.show_grid}"
      style="position: relative; overflow: hidden; outline: none; width: 100%; height: 100%"
      @mousemove="onMouseMove"
      @mouseup="onMouseUp"
      @mousedown="onMouseDown"
      @mouseleave="move=null;moveWho=null;dragCoords=null;hoveredItem=null;hoveredCon=null"
      @dragover.prevent="$event.dataTransfer.dropEffect='move'"
      @drop.prevent="onDrop"
      @keydown.delete="deleteSelected"
      @keydown.esc="unselectAll"
      @wheel="onWheel"
      tabindex="0"
  >
    <div
        ref="canvas"
        :style="{
            width:'100%',
            height:'100%',
            position: 'absolute',
            transform: 'scale(' + zoom +')' + ' translate(' + canvasX + 'px, ' + canvasY + 'px)',
            'transform-origin': 'top left',
          }"
    >
      <connection
          v-for="(c, id) in connections"
          @select-con="selectConnection(id)"
          @hover-con="onHoverConnection"
          :key="id"
          :id="id"
          :data="c.data"
          :coords="doConnect(c.src, c.dst)"
          :mode="getConnectionMode(c)"
          :hovered="hoveredCon==id"
      />

      <connection
          v-if="selectedItem && dragCoords"
          :coords="doConnect(selectedItem)"
      />
      <flow-item
          v-for="(i, id) in items"
          :ref="id"
          :key="id"
          :id="id"
          :icon="i.icon"
          :color="$options.color_schemas[prefs.schema][i.type].color"
          :shadow="$options.color_schemas[prefs.schema][i.type].shadow"
          :data="i.data"
          :x="i.x"
          :y="i.y"
          :selected="selectedItem === id"
          :hovered="hoveredItem === id"
          @move-item="undoClear();moveWho=$event"
          @select-item="selectItem($event.id)"
          @hover-item="onHoverItem"
      />

    </div>
    <zoom-pad @zoom="doZoomInOut($event)" :zoom="zoom" style="position: absolute; right: 5px; bottom: 5px"/>
  </div>


</template>

<script>
import FlowItem from "./FlowItem";
import Connection from "./Connection";
import {connect} from "./connect";
import ZoomPad from "./ZoomPad";
import {color_schemas} from "@/pages/flow/blocks";

export default {
  components: {ZoomPad, Connection, FlowItem},
  emits: ["drop-here", "do-select", "add-connection"],
  props: ["prefs"],
  data() {
    return {
      items: {},
      connections: {},
      iid: 1,
      cid: 1,
      canvasX: 0,
      canvasY: 0,
      undo_buffer: null,
      redo_buffer: null,

      dims: {},
      move: null,
      moveWho: null,
      selectedItem: null,
      selectedCon: null,
      conStart: null,
      hoveredItem: null,
      hoveredCon: null,
      dragCoords: null,

      zoom: 1
    }
  },
  mounted() {
    this.updateDims();
  },
  updated() {
    this.updateDims();
  },

  // watch: {
  //   items: {
  //     deep: true,
  //     handler(){
  //       console.log('items changed');
  //     }
  //   },
  //   connctions: {
  //     deep: true,
  //     handler(){
  //       console.log('connections changed');
  //     }
  //
  //   },
  // },

  methods: {
    clear() {
      this.items = {};
      this.connections = {};
      this.iid = 1;
      this.cid = 1;
      this.canvasX = 0;
      this.canvasY = 0;
    },

    positionOnItem(itemId = 1) { // 1 is always start block cause it's undeletable

      const width = this.$refs.editor?.clientWidth || 0;
      const height = this.$refs.editor?.clientHeight || 0;

      const item = this.items[itemId];
      if (item) {
        this.canvasX = -item.x + width / 2 / this.zoom;
        this.canvasY = -item.y + height / 2 / this.zoom;
      } else alert("Такого блока нет, возможно он был удален.");
    },

    updateDims() {
      const dims = {};
      for (const id in this.items) {
        dims[id] = this.$refs[id].getDims();
      }
      if (JSON.stringify(this.dims) !== JSON.stringify(dims))
        this.dims = dims;
    },

    addItem(type, icon, data, x, y, id = null) {
      if (id === null) {
        id = this.iid++;
      } else {
        if (id >= this.iid) this.iid = id + 1;
      }
      if (this.items[id]) throw Error('Item with this id exists!');

      this.items[id] = {
        id: id,
        type: type,
        icon: icon,
        data: data,
        x: x,
        y: y,
      }
    },

    connectionExist(src, dst) {
      for (const cid in this.connections) {
        const con = this.connections[cid];
        if (con.src == src && con.dst == dst) return true;
      }
      return false;
    },

    addConnection(src, dst, data, id = null) {
      if (this.connectionExist(src, dst)) {
        alert(`Такое соединение уже есть!`);
        return;
      }

      if (id === null) {
        id = this.cid++;
      } else {
        if (id >= this.cid) this.cid = id + 1;
      }
      if (this.connections[id]) throw Error('Connection with this id exists!');

      this.connections[id] = {
        id: id,
        src: src,
        dst: dst,
        data: data
      }
    },

    connection(start = true) {
      if (start) {
        this.conStart = this.selectedItem;
      } else {
        if (this.conStart && this.selectedItem) {
          this.undoSave();
          this.$emit("add-connection", {
            src: this.conStart,
            dst: this.selectedItem
          })
        }
      }
    },

    undoMakeBuffer() {
      return {
        items: JSON.parse(JSON.stringify(this.items)),
        connections: JSON.parse(JSON.stringify(this.connections)),
        iid: this.iid,
        cid: this.cid,
        canvasX: this.canvasX,
        canvasY: this.canvasY,
        zoom: this.zoom,
      };
    },

    undoRestoreBuffer(buffer) {
      this.items = buffer.items;
      this.connections = buffer.connections;
      this.iid = buffer.iid;
      this.cid = buffer.cid;
      this.canvasX = buffer.canvasX;
      this.canvasY = buffer.canvasY;
      this.zoom = buffer.zoom;
    },

    undoSave() {
      this.undo_buffer = this.undoMakeBuffer();
      this.redo_buffer = null;
    },

    undoClear(){
      this.undo_buffer = null;
      this.redo_buffer = null;
    },

    undoDo() {
      console.assert(this.undo_buffer);
      this.redo_buffer = this.undoMakeBuffer();
      this.redo_buffer.canvasX = this.undo_buffer.canvasX;
      this.redo_buffer.canvasY = this.undo_buffer.canvasY;
      this.redo_buffer.zoom = this.undo_buffer.zoom;
      this.undoRestoreBuffer(this.undo_buffer);
      this.undo_buffer = null;
    },

    undoRedo() {
      console.assert(this.redo_buffer);
      this.undo_buffer = this.undoMakeBuffer();
      this.undo_buffer.canvasX = this.redo_buffer.canvasX;
      this.undo_buffer.canvasY = this.redo_buffer.canvasY;
      this.undo_buffer.zoom = this.redo_buffer.zoom;
      this.undoRestoreBuffer(this.redo_buffer);
      this.redo_buffer = null;
    },

    deleteSelected() {
      this.undoSave();
      if (this.selectedItem) {
        if (this.items[this.selectedItem].data.undeletable) {
          alert("Этот элемент удалить нельзя.")
          return;
        }

        for (const cid in this.connections) {
          const con = this.connections[cid];
          if (con.src == this.selectedItem || con.dst == this.selectedItem)
            delete this.connections[cid];
        }
        delete this.items[this.selectedItem];
        this.unselectAll();

      } else if (this.selectedCon) {
        delete this.connections[this.selectedCon];
        this.unselectAll();
      }
    },

    selectItem(id) {
      if (!id) throw Error('selectItem should be with id')
      this.selectedItem = id;
      this.selectedCon = null;
      // this.undoSave();
      this.undoClear();
      this.$emit("do-select", {what: 'item', id: id});
    },

    selectConnection(id) {
      if (!id) throw Error('selectConnection should be with id')
      this.selectedItem = null;
      this.selectedCon = id;
      // this.undoSave();
      this.undoClear();
      this.$emit("do-select", {what: 'connection', id: id});
    },

    unselectAll() {
      this.selectedItem = null;
      this.selectedCon = null;
      this.$emit("do-select", null);
    },

    getConnectionMode(con) {
      if (this.selectedCon == con.id || this.selectedItem == con.src) {
        return con.data.type == 'default' ? 'default' :
            con.data.type == 'error' ? 'error' : 'logic';
      } else if (this.selectedItem == con.dst) {
        return 'in';
      }
      return null;

      // return this.selectedCon == con.id ? 'selected' :
      //     this.selectedItem == con.src ? 'out' :
      //         this.selectedItem == con.dst ? 'in' :
      //             null;
    },

    doConnect(srcid, dstid = null) {
      const get_box = (id) => {
        const item = this.items[id];
        const size = this.dims[id];
        return {
          x0: item.x - size.w / 2,
          y0: item.y - size.h / 2,
          x1: item.x + size.w / 2,
          y1: item.y + size.h / 2,
        }
      }

      if (!this.dims[srcid]) return null;
      if (dstid && !this.dims[dstid]) return null;
      if (!dstid && !this.dragCoords) return null;

      return connect(
          get_box(srcid),
          dstid ? get_box(dstid) : {
            x0: this.dragCoords.x1 - 1,
            y0: this.dragCoords.y1 - 1,
            x1: this.dragCoords.x1 + 1,
            y1: this.dragCoords.y1 + 1,
          }
      );
    },

    onDrop(event) {
      this.undoSave();
      const editor = this.$refs.editor.getBoundingClientRect();
      const type = event.dataTransfer.getData("text/plain");
      const x = (event.clientX - editor.x) / this.zoom - this.canvasX;
      const y = (event.clientY - editor.y) / this.zoom - this.canvasY;
      this.$emit("drop-here", {x: x, y: y, type: type})
    },

    onHoverItem(e) {
      if (e.hover) {
        // react on hover only during dragging
        if (this.dragCoords) this.hoveredItem = e.id;
      } else this.hoveredItem = null;
    },

    onHoverConnection(e) {
      this.hoveredCon = e.hover ? e.id : null;
    },

    onMouseDown(e) {
      this.move = {
        x: e.clientX,
        y: e.clientY

      }
    },

    tryFindConnection(e) {
      // try find connection by cross check
      let result = null;
      top: for (let d of ['h', 'v']) {
        for (let i = -8; i <= 8; i++) {
          const el = document.elementFromPoint(e.clientX + (d == 'h' ? i : 0), e.clientY + (d == 'v' ? i : 0));
          if (el.tagName == 'path') {
            result = el.id;
            break top;
          }
        }
      }
      return result;
    },

    onMouseUp(e) {
      if (this.dragCoords && this.hoveredItem && this.moveWho.id != this.hoveredItem) {
        this.undoSave();
        this.$emit("add-connection", {
          src: this.moveWho.id,
          dst: this.hoveredItem
        })
      }

      if (this.move && !this.moveWho) {
        const con = this.tryFindConnection(e);
        if (con === null) {
          this.unselectAll();
        } else {
          this.selectConnection(con);
        }
        this.move = null;
      }

      this.moveWho = null;
      this.dragCoords = null;

    },

    onMouseMove(e) {
      if (this.move && this.moveWho === null) {
        this.moveWho = {
          id: "__canvas__",
          ...this.move
        }
        this.move = null;
      }

      if (this.moveWho) {
        const moveX = -(this.moveWho.x - e.clientX) / this.zoom;
        const moveY = -(this.moveWho.y - e.clientY) / this.zoom;

        if (this.moveWho.id === "__canvas__") {
          this.canvasX += moveX;
          this.canvasY += moveY;
        } else {
          if (this.moveWho.id === this.selectedItem) {
            if (!this.dragCoords) {
              const x = this.items[this.selectedItem].x;
              const y = this.items[this.selectedItem].y;
              this.dragCoords = {x0: x, y0: y, x1: x, y1: y}
            }
            this.dragCoords.x1 += moveX;
            this.dragCoords.y1 += moveY;

          } else {
            this.items[this.moveWho.id].x += moveX;
            this.items[this.moveWho.id].y += moveY;
          }

        }
        this.moveWho.x = e.clientX;
        this.moveWho.y = e.clientY;
      } else {
        this.hoveredCon = this.tryFindConnection(e);
      }
    },

    doZoom(x, y, step) {
      const z0 = this.zoom;
      let z1 = step === null ? 1 : this.zoom + step * 0.1;
      if (z1 < 0.05 || z1 > 3.05) return;
      z1 = Math.round(z1 * 10) / 10;

      this.canvasX += x * (1 / z1 - 1 / z0);
      this.canvasY += y * (1 / z1 - 1 / z0);
      this.zoom = z1;
    },

    doZoomInOut(step) {
      const editor = this.$refs.editor.getBoundingClientRect();
      const x = editor.width / 2;
      const y = editor.height / 2;
      this.doZoom(x, y, step)
    },

    onWheel(e) {
      const editor = this.$refs.editor.getBoundingClientRect();
      const x = e.clientX - editor.x;
      const y = e.clientY - editor.y;
      const step = -Math.round(e.deltaY / 120);
      this.doZoom(x, y, step)
    },


  },

  color_schemas,

}
</script>

<!--suppress CssUnusedSymbol -->
<style scoped>

html.dark .editor {
  border: 1px solid #303030;

}
.grid {
  background-size: 25px 25px;
  background-image: linear-gradient(to right, #f1f1f1 1px, transparent 1px),
  linear-gradient(to bottom, #f1f1f1 1px, transparent 1px);
}

html.dark .grid {
  background-size: 25px 25px;
  background-image: linear-gradient(to right, #303030 1px, transparent 1px),
  linear-gradient(to bottom, #303030 1px, transparent 1px);
}

</style>