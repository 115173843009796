// import fetch from "/usr/local/lib/node_modules/node-fetch/lib/index.js";

import store from "@/store";
import * as contentDisposition from 'content-disposition';

function make_query(obj) {
  let str = [];
  for (let p in obj)
    if (Object.prototype.hasOwnProperty.call(obj, p)) {
      let val = obj[p];
      if (typeof val == 'object') val = JSON.stringify(val)
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(val));
    }
  return str.join("&");
}

const http = {

  // url: (process.env.NODE_ENV == 'development') ? 'http://0.0.0.0' : '',
  // url: (process.env.NODE_ENV == 'development') ? 'http://0.0.0.0' : '',
  api: '/api/v1',


  async request({path, method, query, body, formdata, response = 'json'}) {

    const token = store.getters["auth/token"];
    const headers = token ? {
      'Authorization': 'Bearer ' + token
    } : {}


    const query_str = query ? '?' + make_query(query) : '';
    const url = this.api + path + query_str;

    let request;

    if (formdata) {
      const formdata_ = new FormData();

      for (const k in formdata) formdata_.append(k, formdata[k])

      request = {
        method: method,
        headers: {
          ...headers
        },
        body: formdata_
      }
    } else {
      const body_ = body ? {body: JSON.stringify(body)} : {};

      request = {
        method: method,
        headers: {
          'Content-Type': 'application/json',
          ...headers
        },
        ...body_
      }
    }


    // console.log(url, JSON.stringify(request))

    const resp = await fetch(url, request);

    if (!resp.ok) {
      const text = await resp.text();
      // console.log("Got " + resp.status + " error (" + text + ") while " + method + " " + url)
      let error = '';
      try {
        // Тут такая логика - если фронтенд все сделал правильно,
        // но есть проблема на стороне бэкенда, например такой пользователь уже есть или
        // неправильные имя и пароль, то такую ошибку бэк отдаст в поле detail
        // в человеко-читаемом виде. Ее можно показывать пользователю.
        // Другие ошибки бэк будет отдавать в виде объектов,
        // и эти ошибки для пользователя не предназначены, если такие ошибки появились
        // значит чт ото не то в коде фронтенда.
        error = JSON.parse(text).detail;
        if (typeof error !== "string") error = text;
      } catch (e) {
        error = text;
      }
      throw Error(error);
    }

    if (resp.status == 204) return;

    if (response == 'json') {
      return await resp.json()
    } else if (response == 'blob') {
      const blob = await resp.blob();
      const header = resp.headers.get('Content-Disposition');

      // const parts = header.split(';');
      // let filename = parts[1].split('=')[1];
      // if (filename.startsWith("utf-8'"))
      //   filename = decodeURIComponent(filename.substring(7))

      return [blob, contentDisposition.parse(header).parameters.filename]
    }

  },

  async get(path, query) {
    return await this.request({
      method: 'GET',
      path: path,
      query: query,
    });
  },

  async getBlob(path, query) {
    return await this.request({
      method: 'GET',
      path: path,
      query: query,
      response: 'blob'
    });
  },

  async post(path, data) {
    return await this.request({
      method: 'POST',
      path: path,
      body: data
    })
  },

  async post_form(path, formdata) {
    return await this.request({
      method: 'POST',
      path: path,
      formdata
    })
  },

  async put(path, data) {
    return await this.request({
      method: 'PUT',
      path: path,
      body: data
    })
  },

  async patch(path, data) {
    return await this.request({
      method: 'PATCH',
      path: path,
      body: data
    })
  },

  async delete(path, query) {
    return await this.request({
      method: 'DELETE',
      path: path,
      query: query
    })
  },

}

export {
  http
}