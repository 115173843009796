<template>
  <div class="dropdown">
    <a href="#" class="d-flex align-items-center text-white text-decoration-none dropdown-toggle" id="dropdownUser1" data-bs-toggle="dropdown">

      <i class="bi bi-person-circle"/>&nbsp;&nbsp;&nbsp;

      <span v-if="user">{{ user.fname }}</span>
      <span v-else class="spinner-border spinner-border-sm"/>

    </a>
    <ul class="dropdown-menu dropdown-menu-dark text-small shadow">
      <li><a class="dropdown-item" href="#" @click.prevent="$router.push({name: 'profile'})">Профиль</a></li>
      <li>
        <hr class="dropdown-divider">
      </li>
      <li><a class="dropdown-item" href="#" @click.prevent="$store.dispatch('auth/logout')">Выйти</a></li>
    </ul>
  </div>

</template>

<script>
// import {http} from "@/misc/http";

import {mapGetters} from "vuex";

export default {
  name: "UserMenu",

  // methods: {
  //   ...mapGetters("auth", ["user"])
  // },

  computed: {
    ...mapGetters("auth", ["user"])
  }

  // data() {
  //   return {
  //     user: null
  //   }
  // },
  //
  // async created() {
  //   const userId = this.$store.getters['auth/userId'];
  //   this.user = await http.get('/users/' + userId)
  // }


}
</script>

