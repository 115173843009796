<template>
  <div>
    <canvas ref="chart" class="w-100"></canvas>
  </div>
</template>

<script>
import {Chart} from 'chart.js';

const palette = ["#0bb4ff", "#e60049", "#50e991", "#e6d800", "#9b19f5", "#ffa300", "#dc0ab4", "#b3d4ff", "#00bfa0"];

export default {
  props: ["type", "items", "format", "singleMode", "aspect"],
  mounted() {
    this.config = {
      type: this.type,
      data: this.getData(),
      options: {
        aspectRatio: this.aspect || 2,
        plugins: {
          tooltip: {
            callbacks: {
              label: this.singleMode ? ((context) => context.chart.data.datasets.map(ds => ds.label + ': ' + ds.data[context.dataIndex])) : undefined,
            }
          },
          legend: {
            labels: {
              filter: (context) => this.singleMode ? context.datasetIndex < 1 : true,
            }
          },

        },
      }
    };

    this.chart = new Chart(
        this.$refs.chart,
        // document.getElementById('myChart'),
        this.config
    );
  },

  methods: {
    getData() {
      const labels = [];
      // eslint-disable-next-line no-unused-vars
      const [_, labelsName, f] = this.format[0];
      for (const i of this.items) labels.push(f(i));
      const datasets = [];

      for (let i = 1; i < this.format.length; i++) {
        // eslint-disable-next-line no-unused-vars
        let [_, datasetName, f] = this.format[i];
        const color = palette[(i - 1) % palette.length];
        const dataset = {
          label: datasetName,
          backgroundColor: color,
          borderColor: color,
          data: [],
          hidden: this.singleMode ? i > 1 : false,
        };
        for (const j of this.items) dataset.data.push(f(j));
        datasets.push(dataset);
      }
      return {
        labels: labels,
        datasets: datasets,
      };
    },
    render() {
      if (!this.config) return;
      this.config.data = this.getData();
      this.chart.update();
    }
  },

}
</script>

