<template>
  <page-header title="Файлы" icon="bi-file-arrow-down">
    <icon-button icon="bi-arrow-clockwise" title="Обновить" @click="reload"/>
    <icon-button v-if="userRole=='Суперадмин'" icon="bi-eye" title="Показать все" :active="show_all" @click="show_all=!show_all; $nextTick(reload);"/>
  </page-header>
  <small v-if="!!error" class="text-danger">{{ error }}</small>
  <live-table
      ref="table"
      url="/tasks"
      :format="[
        ['id','ID', (i)=>i.id],
        ['name','Название', (i)=>i.name],
        ['created','Создан', (i)=>format_datetime(i.created + 'Z')],
        ['finished','Завершен', (i)=>i.finished?format_datetime(i.finished + 'Z'):''],
        ['user_id','Пользователь', (i)=>i.user.fname + ' ' + i.user.lname],
        ['status','Статус', (i)=>({'created':'Новая', 'running':'Выполняется', 'complete':'Готово', 'failed':'Ошибка'}[i.status])],
        ['result','Результат', (i)=>i.result],
      ].filter((i)=>i[0]=='user_id'?userRole=='Суперадмин':true)"
      :menu="[
          {name: 'stop', icon:'bi-stop-circle', title:'Остановить', applicable: (item)=>['created', 'running'].includes(item.status)},
          {name: 'download', icon:'bi-download', title:'Скачать', applicable: (item)=>item.status=='complete'},
          {name: 'delete', icon:'bi-x-circle', title:'Удалить', applicable: (item)=>['failed', 'complete'].includes(item.status)},
          ]"
      :params="{show_all: show_all}"
      @click-menu="onClickMenu"
  />

</template>

<script>
import LiveTable from "@/components/LiveTable";
import PageHeader from "@/components/PageHeader";
import {clickDownload} from '@/misc/util';
import {http} from "@/misc/http";
import IconButton from "@/components/IconButton";
import {mapGetters} from "vuex";
import {format_datetime} from "@/misc/util";

export default {
  components: {IconButton, PageHeader, LiveTable},
  data() {
    return {
      error: "",
      show_all: false,
    }
  },
  methods: {

    async onClickMenu(event) {
      try {
        switch (event.name) {

          case 'stop': {
            if (confirm("Остановить?")) {
              await http.put('/tasks/' + event.item.id + '/cancel');
            }
            break;
          }

          case 'delete': {
            let allow = true;
            if (event.item.status == 'complete') allow = confirm("Удалить?");
            if (allow) {
              await http.delete('/tasks/' + event.item.id);
              this.reload();
            }
            break;
          }

          case 'download': {
            const [blob, fn] = await http.getBlob('/tasks/' + event.item.id + '/download');
            clickDownload(blob, fn);
            break;
          }
        }
        this.error = "";
      } catch (e) {
        this.error = e.message;
      }
    },

    reload() {
      this.$refs.table.doReload();
      this.error = "";

    },
    format_datetime

  },
  computed: {
    ...mapGetters('auth', ['userRole'])

  }
}
</script>

