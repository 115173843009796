<template>
<!--    {{ test || 'no' }}-->
  <page-header :title="title" icon="bi-journal-x" :back="true"/>
  <f-form ref="form" :error="error" :new-mode="id=='new'" @send="onSend" @delete="onDelete">
    <template v-slot:default="form">
      <f-input v-model="item.name" label="Название" w="6" :disabled="!form.edit"/>
      <f-input v-model="item.project_id" type="select" label="Компания" w="3" :disabled="!form.edit">
        <option v-for="p in projects" :key="p.id" :value="p.id">{{ p.name }}</option>
      </f-input>
      <f-input v-model="item.dl_project_id" label="Код проекта (если доступно внешним)" w="3" :disabled="!form.edit"/>
      <f-input v-model="item.comment" type="textarea" label="Комментарий" :disabled="!form.edit"/>
      <f-input v-model="item.meta" type="textarea" label="Метаинформация" :disabled="!form.edit"/>
    </template>
  </f-form>
</template>

<script>
import {http} from "@/misc/http";
import FInput from "@/components/FInput";
import {format_datetime} from "@/misc/util";
import PageHeader from "@/components/PageHeader";
import FForm from "@/components/FForm";
import {updateItem} from "@/misc/util";

export default {
  components: {FForm, PageHeader, FInput},
  props: ["id"],
  data() {
    return {
      item: {
        id: null,
        project_id: 1,
        dl_project_id: "",
        name: "Новый",
        comment: "",
        meta: "{}",
      },
      error: "",
      projects: null,
      // test: null,
    }
  },

  created() {
    this.updateItem((async () => {
      // this.test = await http.get('/blacknumbers/' + this.id + '?since=2023-04-29T21:15');
      this.projects = await http.get('/shortcuts/projects');
      return !this.isNew ? await http.get('/blacklists/' + this.id) : null;
    })());

  },

  methods: {
    onSend() {
      this.updateItem(
          http[this.isNew ? 'post' : 'put']("/blacklists", this.item),
          "blacklist", true
      );
    },

    onDelete() {
      this.updateItem(http.delete("/blacklists/" + this.id), "blacklists");
    },

    format_datetime,
    updateItem
  },

  computed: {
    title() {
      return "Блэклист \"" + this.item.name + "\"";
    },
    isNew() {
      return this.id == "new";
    },
  },


}
</script>

