<!--  eslint-disable vue/no-mutating-props-->
<template>
  <span
      v-for="(item, index) in data"
      :key="item"
      class="badge rounded-pill m-1"
      :class="disabled?'bg-secondary':(cls || 'bg-primary')"
      :style="disabled?'':'cursor: pointer'"
      @click="onClick(item, index, 'body')"
  >
    {{ (format || ((i) => i))(item) }}<span v-if="!disabled && !nondeletable">&nbsp;&nbsp;<i @click="onClick(item, index, 'close')" class="bi bi-x-circle-fill"/></span>
  </span>
</template>

<script>
export default {
  props: ["data", "cls", "format", "disabled", "nondeletable"],
  emits: ["clicked"],
  methods: {
    onClick(item, index, where) {
      if (!this.disabled) {
        // eslint-disable-next-line vue/no-mutating-props
        // this.data.splice(index, 1);
        this.$emit('clicked', {index: index, item: item, where: where})
      }
    }
  }
}
</script>

<style scoped>

</style>