<template>
  <!--  <pre> {{ JSON.stringify(filter, null, 4) }} </pre>-->
  <!--      {{ items }}-->
  <small v-if="!!error" class="text-danger">{{ error }}</small>
  <chart v-if="chart" ref="chart" :type="chart" :items="items" :format="format" :single-mode="singleMode" :aspect="aspect"/>

  <table v-if="!hideTable && !transpose" class="table table-hover">

    <thead>
    <tr>
      <th v-for="f in format" :key="f[0]" @click="sortBy(f[0])" scope="col" class="text-nowrap" :style="nonsortable?{}:{cursor: 'pointer'}">{{ f[1] }}<i v-if="sort==f[0]" :class="['bi',asc?'bi-arrow-down-short':'bi-arrow-up-short']"/></th>
      <th v-if="menu"/>
    </tr>
    </thead>

    <tbody>
    <tr :class="{'table-primary': (isSelected || (()=>false))(item, selectedItem)}" v-for="item in items" :key="item.id" @mousedown="onMouseDown" @click="onRowClick(item, $event)" @auxclick="onRowClick(item, $event)" style="cursor: pointer">
      <td v-for="(f, index) in format" :key="item.id+'-'+index">{{ f[2](item) }}</td>
      <td v-if="menu">
        <span class="d-flex gap-1">
            <icon-button
                v-for="i in filterMenu(item)" :key="i"
                :icon="i.icon"
                :title="i.title"
                @click="$emit('clickMenu', {name: i.name, item: item})"
            />
        </span>
      </td>
    </tr>
    </tbody>

    <tfoot v-if="!loading">
    <tr>
      <td :colspan="format.length + (menu?1:0)" class="text-end">
        {{ items.length }} из {{ total }}
      </td>
    </tr>
    </tfoot>

  </table>

  <table v-if="!hideTable && transpose" class="table">
    <tbody>
    <tr v-for="f in format" :key="f[0]">
      <th class="text-nowrap">{{ f[1] }}</th>
      <td v-for="item in items" :key="item.id" class="text-nowrap"> {{ f[2](item) }}</td>
    </tr>
    </tbody>

  </table>


  <div v-if="!hideTable && !hideControls" class="text-center">
    <span v-if="loading" class="spinner-grow spinner-grow-sm"/>
    <div v-else class="d-flex w-100 justify-content-center gap-2">

      <div v-if="remains && morecount?.length" class="btn-group">
        <button @click="showMore(morecount[selectedMorecount])" type="button" class="btn btn-primary">Показать еще {{ morecount[selectedMorecount] }}</button>
        <div class="btn-group" role="group">
          <button type="button" class="btn btn-primary dropdown-toggle" data-bs-toggle="dropdown"/>
          <ul class="dropdown-menu">
            <li v-for="(i,idx) in morecount" :key="i"><a class="dropdown-item" @click="selectedMorecount=idx;showMore(i)">{{ i }}</a></li>
          </ul>
        </div>
      </div>
      <button v-if="remains && !morecount?.length" @click="showMore(morecount || 20)" type="button" class="btn btn-primary">Показать еще {{ morecount || 20 }}</button>
      <button v-if="downloadable && total" @click="download" type="button" class="btn btn-outline-primary">Скачать все {{ total }}</button>
    </div>

  </div>

  <!--      {{ items }}-->


</template>

<script>
import {http} from "@/misc/http";
import {persistent} from "@/tstore";
import {clickDownloadCSV, makeCSV} from "@/misc/util";
import Chart from "./Chart";
import {mapGetters} from "vuex";
import IconButton from "@/components/IconButton";

const MAX_CLIENT_DOWNLOAD_SIZE = 1000;

export default {
  components: {IconButton, Chart},
  props: [
    "url",      // url fetch data from (see ResponseModelMeta in backend)
    "format",   // table format, [[column_field, column_title, (row_item)=>what_to_display ], ...]
    'store',
    'reload',
    'nonsortable',
    'downloadable',
    'downloadGuard',
    'initcount',
    'morecount',
    'params',
    'chart',
    'default_filter',
    'hideTable',
    'singleMode',
    'aspect',
    'menu', // [{name:..., title:..., icon:...}]
    'name', // для экспорта
    'custom_format', // формат для выгрузки на сервере
    'transpose',
    'hideControls',
    'newtab',
    'isSelected', // (item) => isSelected
    'default_sort',
  ],
  emits: ["clickItem", "clickMenu"],

  // eslint-disable-next-line
  setup({store, default_filter, default_sort}) {
    return persistent(store, {
      error: "",
      items: [],
      total: 0,
      loading: false,
      sort: default_sort,
      asc: false,
      filter: default_filter ? Object.assign({}, default_filter) : {},
      selectedMorecount: 0,
      selectedItem: null,
    });
  },
  created() {
    if (!this.sort && !this.nonsortable) this.sort = this.format[0][0];
    if (this.reload) this.items = [];
    if (!this.items.length) this.load();
  },
  // watch: {
  //   items: {
  //     deep:true,
  //     handler(){
  //       if (this.chart) this.$refs.chart.render();
  //     }
  //   }
  // },
  computed: {
    remains() {
      if (this.items.length > this.total) return 0;
      return this.total - this.items.length;
    },
    ...mapGetters('auth', ['token'])

  },
  methods: {
    selectItem(selectedItem) {
      this.selectedItem = selectedItem;
    },

    showMore(n) {
      this.load(n);
    },

    async load(count = null) {
      if (!count) count = this.initcount || 20;
      const start = this.items.length;
      this.loading = true;
      this.error = "";
      try {
        // todo: не добавлять сортировку для nonsortable
        const resp = await http.get(this.url, {
          start: start,
          count: count,
          ...this.sort ? {
            sort: this.sort,
            asc: this.asc,
          } : {},
          ...this.filter,
          ...this.params
        });
        this.items = this.items.concat(resp.items);
        this.total = resp.totalItemsCount;
        if (this.chart) {
          await this.$nextTick(() => {
            this.$refs.chart.render();
          });
        }
      } catch (e) {
        this.error = e.message;
      }
      this.loading = false;
    },

    doFilter(filter) {
      this.filter = filter;
      this.doReload();
    },

    doReload() {
      this.items = [];
      this.load();
    },

    onMouseDown(event) {
      if (!this.newtab) return;
      if (event.button == 1) event.preventDefault();
    },

    onRowClick(item, event) {
      if (event.button > 1) return;
      if (!this.newtab && event.button != 0) return;
      event.preventDefault();
      setTimeout(() =>
          this.$emit("clickItem", {
            item: item, newtab: event.button == 1 || event.ctrlKey
          }), 1);

      // if (this.editable) this.$router.push(this.$route.path + '/' + item.id);
    },

    sortBy(field) {
      if (this.nonsortable) return;
      if (field == this.sort) {
        this.asc = !this.asc;
      } else {
        this.sort = field;
        this.asc = true;
      }
      this.items = [];
      this.load();
    },

    async makeCSV() {
      const resp = await http.get(this.url, {start: 0, count: MAX_CLIENT_DOWNLOAD_SIZE, sort: this.sort, asc: this.asc, ...this.filter, ...this.params});
      if (resp.totalItemsCount > MAX_CLIENT_DOWNLOAD_SIZE) throw Error("Слишком много данных для загрузки на клиенте.");

      const headers = this.format.map(i => i[1]);
      let data = resp.items.map(i => this.format.map(f => f[2](i)));

      if (!this.transpose) {
        return makeCSV(data, headers);
      } else {
        data = [headers].concat(data);
        data = data[0].map((_, colIndex) => data.map(row => row[colIndex]));
        return makeCSV(data, null);
      }
    },

    async download() {
      if (this.downloadGuard) {
        if (!this.downloadGuard(this)) return;
      }
      try {
        if (this.total <= MAX_CLIENT_DOWNLOAD_SIZE) {
          clickDownloadCSV((this.name || "report") + '.csv', await this.makeCSV());
        } else {
          if (this.transpose) throw Error('Загрузка больших транспонированных отчетов не поддерживается.');
          await http.post('/tasks', {
            name: this.name || "report",
            payload: {
              kind: 'download_table',
              url: this.url,
              token: this.token,
              sort: this.sort,
              asc: this.asc,
              filter: this.filter,
              params: this.params,
              custom_format: this.custom_format,
              tz_minutes: -(new Date()).getTimezoneOffset(),
            }
          });
          alert('Данных слишком много, начата отложенная загрузка, проверьте раздел Файлы.');
        }
      } catch (e) {
        this.error = e.message;
      }
    },
    // async download() {
    //   const N = 1000; // максимальный размер скачиваемого на клиенте отчета
    //
    //   if (this.total > N) {
    //     try {
    //       if (this.transpose) throw Error('Загрузка больших транспонированных отчетов не поддерживается.')
    //       await http.post('/tasks', {
    //         name: "Загрузка " + (this.name ? ' ' + this.name : ''),
    //         payload: {
    //           kind: 'download_table',
    //           url: this.url,
    //           token: this.token,
    //           sort: this.sort,
    //           asc: this.asc,
    //           filter: this.filter,
    //           params: this.params,
    //           custom_format: this.custom_format,
    //           tz_minutes: -(new Date()).getTimezoneOffset(),
    //         }
    //       });
    //       alert('Данных слишком много, начата отложенная загрузка, проверьте раздел Файлы.');
    //     } catch (e) {
    //       this.error = e.message;
    //     }
    //   } else {
    //     try {
    //       this.loading = true;
    //       const headers = this.format.map(i => i[1]);
    //       const resp = await http.get(this.url, {start: 0, count: N, sort: this.sort, asc: this.asc, ...this.filter, ...this.params});
    //       let data = resp.items.map(i => this.format.map(f => f[2](i)));
    //       if (!this.transpose) {
    //         clickDownloadCSV(data, headers, 'report.csv');
    //       } else {
    //         data = [headers].concat(data);
    //         data = data[0].map((_, colIndex) => data.map(row => row[colIndex]));
    //         clickDownloadCSV(data, null, 'report.csv');
    //       }
    //       // let result = [];
    //       // let toGo = this.total;
    //       // let start = 0;
    //       // while (toGo > 0) {
    //       //   const resp = await http.get(this.url, {start: start, count: 1000, sort: this.sort, asc: this.asc, ...this.filter, ...this.params});
    //       //   const data = resp.items.map(i => this.format.map(f => f[2](i)));
    //       //   result = result.concat(data)
    //       //   toGo -= 1000;
    //       //   start += 1000;
    //       // }
    //       // clickDownloadCSV(result, headers, 'report.csv');
    //     } catch (e) {
    //       this.error = e.message;
    //     }
    //     this.loading = false;
    //   }
    // },

    filterMenu(item) {
      return this.menu.filter((i) => i.applicable ? i.applicable(item) : true)
    },
  }
}
</script>

<style scoped>
thead th {
  position: sticky;
  top: 0;
  background-color: var(--bs-body-bg)
}

.dark thead th {
  position: sticky;
  top: 0;
  background-color: var(--bs-body-bg-alt)
}

</style>