<template>
  <!--  <form class="row g-2" @keydown.enter.prevent="$emit('send'); edit=false">-->
  <form class="row g-2" @submit.prevent="$emit('send')">
    <small v-if="!!error" class="text-danger">{{ error }}</small>
    <slot :edit="edit"/>
    <div v-if="!noneditable" class="d-flex">
      <button v-if="!edit" @click="edit=true" class="btn btn-outline-primary px-2 m-1">Редактировать</button>
      <button v-if="edit" @click.prevent="onCancel" class="btn btn-outline-primary px-2 m-1">Отменить</button>
      <slot name="actions" :edit="edit"/>
      <button v-if="edit" @click.prevent="$emit('send')" class="btn btn-outline-primary px-3 m-1">OK</button>
      <button v-if="!edit && !newMode && !nondeletable" @click.prevent="onDelete" class="btn btn-outline-danger px-2 m-1 ms-auto">Удалить</button>
    </div>
  </form>

</template>

<script>

export default {
  props: ["newMode", "error", "noneditable", "nondeletable"],
  emits: ["send", "delete"],
  data() {
    return {
      edit: null,
    }
  },

  created() {
    this.edit = this.newMode;
  },

  methods: {
    accept() {
      this.edit = false;
    },

    onCancel() {
      if (this.newMode) {
        this.$router.go(-1);
      } else {
        this.edit = false;
      }
    },

    onDelete() {
      if (confirm("Вы уверены?")) this.$emit('delete');
    }
  }

}
</script>

<style scoped>

</style>