<template>

  <div v-if="type=='checkbox'" class="form-check mb-1 mt-2">
    <input v-model="inputVal" ref="input" class="form-check-input" type="checkbox" :disabled="!!disabled" :id="uid">
    <label v-if="label" class="form-text" style="position: relative; top:-3px" :for="uid">{{ label }}</label>
    <small v-if="error" class="text-danger">{{ error }}</small>
  </div>

  <div v-else class="mb-1">
    <label v-if="label" class="form-text" :for="uid" style="white-space: nowrap">{{ label }}<span v-if="help || helpUrl">&nbsp;<icon-button
        icon="bi-question-circle"
        :title="help"
        :disabled="!helpUrl"
        @click="onHelp"
    />
      </span>
    </label>
    <textarea v-if="type=='textarea'" v-model="inputVal" ref="input" class="form-control form-control-sm" :id="uid" :disabled="!!disabled"/>
    <select v-if="type=='select'" v-model="inputVal" ref="input" class="form-select form-select-sm" :id="uid" :disabled="!!disabled" :size="size" :style="style">
      <slot/>
    </select>
    <div v-if="type=='datalist'" class="d-flex">
      <input v-if="type=='datalist'" v-model="inputVal" ref="input" class="form-control form-control-sm x-datalist" :list="uid+'_opts'" :id="uid" :disabled="!!disabled" placeholder="Type or choose...">
      <icon-button v-if="type=='datalist'" @click="clear" icon="bi-trash" class="text-secondary align-self-center" style="position: relative; right: 1.5rem" :disabled="!!disabled"/>
    </div>
    <datalist v-if="type=='datalist'" :id="uid+'_opts'">
      <slot/>
    </datalist>

    <input v-if="!['textarea','select', 'datalist'].includes(type)" v-model="inputVal" :type="type || 'text'" ref="input" class="form-control form-control-sm" :disabled="!!disabled" :id="uid">
    <small v-if="error" class="text-danger">{{ error }}</small>
  </div>

</template>

<script>
/* eslint-disable vue/no-mutating-props */
import {uuid4} from "@/misc/util";
import IconButton from "@/components/IconButton";

export default {
  components: {IconButton},
  props: ["label", "type", "modelValue", "disabled", "size", "style", "error", "help", "helpUrl"],
  data() {
    return {
      uid: null,
    }
  },
  computed: {
    inputVal: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit('update:modelValue', val);
      }
    }
  },
  mounted() {
    this.uid = uuid4();
  },
  methods: {
    onHelp() {
      window.open(this.helpUrl, '_blank').focus();
    },
    clear() {
        this.$emit('update:modelValue', "");
    }
  },

}
</script>

