<template>
  <page-header title="Вызовы" icon="bi-telephone-inbound"/>
  <!--    <icon-button icon="bi-gear fs-2" title="Фильтровать" :active="filterVisible" @click="toggleFilter"/>-->
  <!--  </page-header>-->
  <!--  {{ projectConfig || "no"}}<br>-->
  <!--  {{ tableParams || "no" }}<br>-->
  <calls-filter
      v-if="filterVisible"
      @filter="onFilter"
      ref="filter"
      store="callsFilter"
  />

  <live-table
      store="calls"
      :name="tableName"
      url="/calls"
      @click-item="onClick"
      ref="table"
      :format="tableFormat"
      :params="tableParams"
      :downloadable="true"
      :download-guard="downloadGuard"
      :morecount="[50,100,500]"
      :custom_format="customFormat"
      :newtab="true"
      :is-selected="(item, selected)=>item.id==selected"
      default_sort="started_at"
  />

</template>

<script>
import {format_datetime, format_datetime_for_filename} from '@/misc/util';
import PageHeader from "../../components/PageHeader";
import LiveTable from "../../components/LiveTable";
import CallsFilter from "./CallsFilter";
import {persistent} from "@/tstore";
import {mapGetters} from "vuex";
import {http} from "@/misc/http";

export default {
  components: {CallsFilter, LiveTable, PageHeader},
  setup() {
    return persistent('callsMain', {
      filterVisible: true,
      projectCode: null,
      projectConfig: null,
      applied_filter: null,
      time_mode: null,
    });
  },
  created() {
    if (this.userProject)
      this.loadProject(this.userProject);

  },
  methods: {
    downloadGuard(table) {
      if (table.total > 5000 && table.sort != "started_at") {
        alert("Выгрузка невозможна, данных слишком много, отсортируйте список по полю Начало и повторите попытку.");
        return false;
      }
      return true;
    },

    async loadProject(dl_project_id) {
      try {
        this.projectCode = await http.get('/codes_view/' + dl_project_id);
        this.projectConfig = JSON.parse(this.projectCode.config);

      } catch (e) {
        this.projectCode = null;
        this.projectConfig = null;
      }
    },


    async onFilter(event) {
      if (event.dl_project_id) {
        await this.loadProject(event.dl_project_id);
      } else {
        this.projectCode = null;
        this.projectConfig = null;
      }

      this.$refs.table.doFilter(event);

      this.applied_filter = event;
      this.time_mode = this.$refs.filter.timeMode;
    },

    onClick(event) {
      this.$refs.table.selectItem(event.item.id);

      if (event.newtab) {
        const route = this.$router.resolve({
          name: 'call',
          params: {id: event.item.id},
          query: {alone: true}
        });
        window.open(route.href, '_blank');
      } else {
        this.$router.push({name: 'call', params: {id: event.item.id}})
      }
    },
    // toggleFilter() {
    //   this.filterVisible = !this.filterVisible;
    //   if (!this.filterVisible) this.$refs.filter.onFilter(true);
    // },
    format_datetime,
  },

  computed: {
    theFormat() {
      const result = [
        ['id', 'ID', (i) => i.id, "id"],
      ];

      if (!this.userDomain)
        result.push(['domain_name', 'Домен', (i) => i.domain_name, "domain_name"]);

      if (!this.userProject)
        result.push(['dl_project_id', 'Код', (i) => i.dl_project_id, "dl_project_id"]);

      if (!this.projectConfig || this.projectConfig.table?.default?.destination_number)
        result.push(['destination_number', 'Номер', (i) => i.destination_number, "destination_number"]);

      if (!this.projectConfig || this.projectConfig.table?.default?.phone)
        result.push(['phone', 'Абонент', (i) => i.phone, "phone"]);

      if (!this.projectConfig || this.projectConfig.table?.default?.started_at)
        result.push(['started_at', 'Начало', (i) => format_datetime(i.started_at).slice(0, -3), "started_at%dt"]);

      if (!this.projectConfig || this.projectConfig.table?.default?.duration)
        result.push(['duration', 'Длит.[c]', (i) => i.duration, "duration"]);

      if (!this.projectConfig || this.projectConfig.table?.default?.call_status)
        result.push(['call_status', 'Статус', (i) => i.call_status?.name_ru, "call_status.name_ru"]);

      if (!this.projectConfig || this.projectConfig.table?.default?.conversation_release_reason)
        result.push(['conversation_release_reason', 'Завершил', (i) => i.conversation_release_reason?.name_ru, "conversation_release_reason.name_ru"]);

      if (!this.projectConfig || this.projectConfig.table?.default?.dl_topic)
        result.push(['dl_topic', 'Тематика', (i) => i.dl_topic, "dl_topic"]);

      if (!this.projectConfig || this.projectConfig.table?.default?.dl_result)
        result.push(['dl_result', 'Результат', (i) => i.dl_result, "dl_result"]);

      if (this.projectConfig)
        for (const v of this.projectConfig.table.variables)
          result.push([v.name, v.description, (i) => i.variables[v.name], "variables." + v.name]);

      return result;
    },
    tableFormat() {
      return this.theFormat.map(i => i.slice(0, 3));
    },
    customFormat() {
      return this.theFormat.map(i => ({name: i[0], description: i[1], value: i[3]}))
    },

    tableParams() {
      return this.projectConfig ?
          {extra_variables: this.projectConfig.table.variables.map(i => i.name).join(',')}
          : undefined;
    },

    tableName() {
      let s = this.applied_filter?.dl_project_id || this.userProject || "Вызовы";
      if (this.applied_filter?.t_from)
        s += "_с_" + format_datetime_for_filename(this.applied_filter?.t_from, !this.time_mode);
      if (this.applied_filter?.t_to)
        s += "_до_" + format_datetime_for_filename(this.applied_filter?.t_to, !this.time_mode);
      return s
    },

    ...mapGetters("auth", ["userDomain", "userProject"])

  }


}
</script>

