<template>
    <div class="d-flex">
      <side-bar/>
      <main class="d-flex flex-column p-3" style="width: var(--main-width)">
        <router-view :key="$route.fullPath"/>
      </main>
    </div>
</template>

<script>
import SideBar from "./SideBar";

export default {
  name: "MainScreen",
  components: {SideBar}
}
</script>

<style>

html, body{
  height: 100%;
}

:root {
  --main-width: calc(100vw - 12rem) !important;
}
</style>