<!--eslint-disable vue/no-mutating-props-->
<template>
  <fl-group :name="name">
    <fl-input v-model="data.type" type="select" label="Вид обработки">
      <option value="default">Переход по умолчанию</option>
      <option value="tts">TTS</option>
      <option value="audio">Аудиофайл</option>
    </fl-input>
    <say-subform
        :script="script"
        :type="data.type"
        :message_params="data"
        :audio_settings="data"
    />
  </fl-group>

</template>

<script>
import FlInput from "../components/FlInput";
import FlGroup from "../components/FlGroup";
import SaySubform from "./SaySubform";

export default {
  components: {SaySubform, FlGroup, FlInput},
  props: ["name", "data", "script"],
}
</script>

<style scoped>

</style>