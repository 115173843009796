<!--eslint-disable vue/no-mutating-props-->
<template>
  <fl-form>
    <fl-group name="Блок 'Email'">
      <fl-input v-model="data.name" label="Название блока"/>
      <fl-input type="textarea" v-model="data.comment" label="Комментарий"/>
    </fl-group>
    <fl-group name="Сообщение">
      <div class="d-flex align-items-end gap-2">
        <fl-input v-model="chip" @keydown.enter.prevent="addChip" label="Адрес" />
        <icon-button icon="bi-plus-circle" title="Добавить" @click="addChip" style="position: relative; top:-0.5rem"/>

      </div>
      <chips :data="data.settings.address" @clicked="data.settings.address.splice($event.index,1)"/>
      <fl-input v-model="data.settings.subject" label="Заголовок"/>
      <fl-input v-model="data.settings.message" type="textarea" label="Сообщение"/>
    </fl-group>
  </fl-form>

</template>

<script>
import FlForm from "./components/FlForm";
import FlGroup from "./components/FlGroup";
import FlInput from "./components/FlInput";
import Chips from "../../../components/Chips";
import IconButton from "../../../components/IconButton";

export default {
  components: {IconButton, Chips, FlInput, FlGroup, FlForm},
  props: ["data"],
  data() {
    return {
      chip: ""
    }
  },
  methods:{
    addChip(e) {
      this.chip.split(',').forEach(i => {
        // eslint-disable-next-line vue/no-mutating-props
        if (i) this.data.settings.address.push(i)
      })
      this.chip = '';
      // e.stopPropagation();
    },

  }
}
</script>

