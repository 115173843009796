<template>
  <div class="btn-group">
    <button @click="$emit('click', selected)" type="button" class="btn btn-outline-primary">{{ selected.title }}</button>
    <div class="btn-group">
      <button type="button" class="btn btn-outline-primary dropdown-toggle" data-bs-toggle="dropdown"/>
      <ul class="dropdown-menu">
        <li v-for="i in items" :key="i"><a class="dropdown-item" @click="$emit('click', i)">{{ i.short || i.title }}</a></li>
      </ul>
    </div>
  </div>

</template>

<script>
export default {
  props: ["items"],
  emits: ["click"],
  data() {
    return {
      selected: null
    }
  },
  created() {
    this.selected = this.items[0];
  }

}
</script>
