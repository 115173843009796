<template>
  <page-header :title="title" icon="bi-gear-fill" :back="true"/>
  <f-form ref="form" :error="error" :new-mode="id=='new'" @send="onSend" @delete="onDelete">
    <template v-slot:default="form">
      <f-input v-model="item.id" label="Код проекта" w="3" :disabled="!form.edit || !isNew"/>
      <f-input v-model="item.project_id" type="select" label="Компания" w="3" :disabled="!form.edit">
        <option v-for="p in projects" :key="p.id" :value="p.id">{{ p.name }}</option>
      </f-input>
      <f-input v-model="item.name" label="Название" w="6" :disabled="!form.edit"/>
      <f-input v-model="item.config" type="textarea" label="JSON конфигурация" :disabled="!form.edit" rows="20"/>
      <f-input v-model="item.comment" type="textarea" label="Комментарий" :disabled="!form.edit"/>
    </template>
  </f-form>
</template>

<script>
import {http} from "@/misc/http";
import FInput from "@/components/FInput";
import {format_datetime} from "@/misc/util";
import PageHeader from "@/components/PageHeader";
import FForm from "@/components/FForm";
import {updateItem} from "@/misc/util";

export default {
  components: {FForm, PageHeader, FInput},
  props: ["id"],
  data() {
    return {
      item: {
        id: "ABC010I",
        name: "Новый",
        config: "{}",
        comment: "",
      },
      error: "",
      projects: null,
    }
  },

  created() {
    this.updateItem((async () => {
      this.projects = await http.get('/shortcuts/projects');
      return !this.isNew ? await http.get('/codes/' + this.id) : null;
    })());
  },

  methods: {
    onSend() {
      this.updateItem(
          http[this.isNew ? 'post' : 'put']("/codes", this.item),
          "code", true
      );
    },

    onDelete() {
      this.updateItem(http.delete("/codes/" + this.id), "codes");
    },

    format_datetime,
    updateItem
  },

  computed: {
    title() {
      return "Проект \"" + this.item.name + "\"";
    },
    isNew() {
      return this.id == "new";
    },
  },


}
</script>

