<!--eslint-disable vue/no-mutating-props-->
<template>
  <fl-form>

    <fl-group name="Блок 'Проверка'">
      <fl-input v-model="data.name" label="Название блока"/>
      <fl-input type="textarea" v-model="data.comment" label="Комментарий"/>
      <div class="form-text">Проверка значений переменных задается в соединениях</div>
    </fl-group>
  </fl-form>

</template>

<script>
import FlForm from "./components/FlForm";
import FlGroup from "./components/FlGroup";
import FlInput from "./components/FlInput";

export default {
  components: {FlInput, FlGroup, FlForm},
  props: ["data"],
}
</script>

