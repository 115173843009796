<template>
  <router-view/>
</template>

<script>

import {mapGetters, mapActions} from "vuex";
// import MainScreen from "@/components/MainScreen";

export default {
  name: 'App',
  // components: {MainScreen},
  computed: {
    ...mapGetters('auth', ['isLoggedIn', 'token_content'])
  },

  methods: {
    ...mapActions('auth', ['logout'])
  },

  created() {
    const prefs = localStorage.getItem('prefs');
    if (prefs) {
      if (JSON.parse(prefs).dark) {
        document.documentElement.classList.add('dark');
        document.documentElement.style.setProperty("color-scheme", 'dark');
      } else {
        document.documentElement.style.setProperty("color-scheme", 'light');
      }
    }
    this.$store.dispatch('auth/tryLogin');
  },

}
</script>
