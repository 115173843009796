<!--eslint-disable vue/no-mutating-props-->
<template>
  <div :style="'margin-left: '+10*level +'px'" class="d-flex">
    <fl-input v-model="data.ops_logic" type="select" class="flex-grow-1">
      <option value="or">ИЛИ</option>
      <option value="and">И</option>
    </fl-input>
    <icon-button v-if="!nondeletable" @click="$emit('delete-me', data.id)" icon="bi-trash" title="Удалить операцию" class="px-1"/>
  </div>

  <div :style="'margin-left: '+10*(level+1) +'px'">
    <fl-ops
        :data="data.ops"
        @do-add="data.ops.push({lvalue: '%var_10%', op:'==', rvalue: '100'})"
    />
  <lex
      v-for="d in data.subops"
      :key="d"
      :data="d"
      :level="level+1"
      @delete-me="deleteSubop"
  />
  <icon-button @click="data.subops.push({ops_logic:'or', ops:[], subops:[], id:uuid4()})" icon="bi-plus-circle-dotted" title="Добавить операцию" class="mx-1"/>
  </div>
</template>

<script>
import FlInput from "@/pages/flow/forms/components/FlInput";
import FlOps from "@/pages/flow/forms/components/FlOps";
import IconButton from "@/components/IconButton";
import {uuid4} from "@/misc/util";

export default {
  name: "Lex",
  components: {IconButton, FlOps, FlInput},
  props: ['data', 'level', 'nondeletable'],
  emits: ['delete-me'],
  methods: {
    deleteSubop(id) {
      // eslint-disable-next-line vue/no-mutating-props
      this.data.subops = this.data.subops.filter(i => i.id != id);
    },
    uuid4
  },
}
</script>

