<template>
  <page-header :title="title" icon="bi-person-lines-fill" :back="true"/>
  <!--  {{ item }}-->
  <f-form ref="form" :error="error" :new-mode="id=='new'" @send="onSend" :nondeletable="true">
    <template v-slot:default="form">

      <f-input v-model="item.login" label="Логин" w="3" :disabled="!form.edit"/>
      <f-input v-model="item.project_id" type="select" label="Компания" w="3" :disabled="!form.edit">
        <option v-for="p in projects" :key="p.id" :value="p.id">{{ p.name }}</option>
      </f-input>
      <f-input v-model="item.role" type="select" label="Роль" w="3" :disabled="!form.edit">
        <option v-for="role in roles" :key="role" :value="role">{{ role }}</option>
      </f-input>

      <f-input v-model="item.dl_project_id" label="Проект (если ограничено)" w="3" :disabled="!form.edit||item.role!='Заказчик'"/>
      <!--      <f-input v-model="item.dl_project_id" type="select" label="" w="3" :disabled="!form.edit||item.role!='Заказчик'">-->
      <!--        <option value="">Нет</option>-->
      <!--        <option v-for="i in options?.dl_project_id" :key="i" :value="i">{{ i }}</option>-->
      <!--      </f-input>-->

      <f-input v-model="item.fname" label="Имя" w="3" :disabled="!form.edit"/>
      <f-input v-model="item.lname" label="Фамилия" w="3" :disabled="!form.edit"/>
      <f-input v-model="item.email" label="Email" w="3" :disabled="!form.edit"/>
      <f-input v-model="item.phone" label="Телефон" w="3" :disabled="!form.edit"/>

      <div v-if="form.edit && !isNew && !item.automated" class="col-12">
        <small>Оставьте поля пустыми или введите данные, чтобы поменять пароль. </small>
      </div>

      <f-input v-if="form.edit && !item.automated" v-model="password1" type="password" label="Пароль" w="6" :disabled="!form.edit"/>
      <f-input v-if="form.edit && !item.automated" v-model="password2" type="password" label="Пароль повторно" w="6" :disabled="!form.edit"/>

      <div v-if="item.role=='Заказчик'" class="d-flex gap-4">
        <div class="form-text align-self-center">Привилегии:</div>
        <fl-input v-model="item.privilege.dialog_designer" type="checkbox" label="Диалог Дизайнер" :disabled="!form.edit"/>
      </div>

      <div class="col-12">
        <div class="form-check form-switch">
          <input class="form-check-input" type="checkbox" v-model="item.enabled" :disabled="!form.edit">
          <label class="form-check-label">Активный</label>
        </div>
        <div class="form-check form-switch">
          <input class="form-check-input" type="checkbox" v-model="item.automated" :disabled="!form.edit || !isNew">
          <label class="form-check-label">Сервис (скрипт, робот и т.п.)</label>
        </div>
      </div>
      <div v-if="token" class="mt-2">{{ token }}</div>

    </template>

    <template v-slot:actions>
      <button v-if="!isNew && item.automated" @click.prevent="onGetToken" class="btn btn-outline-primary px-2 m-1">Получить токен</button>
    </template>
  </f-form>
</template>

<script>
import {http} from "@/misc/http";
import FInput from "@/components/FInput";
import {format_datetime} from "@/misc/util";
import PageHeader from "@/components/PageHeader";
import FForm from "@/components/FForm";
import {mapGetters} from "vuex";
import {updateItem} from "@/misc/util";
import FlInput from "@/pages/flow/forms/components/FlInput";

export default {
  components: {FlInput, FForm, PageHeader, FInput},
  props: ["id"],
  data() {
    return {
      item: {
        login: "user",
        project_id: 1,
        role: "Заказчик",
        privilege: Object.assign({}),
        domain: "",
        dl_project_id: "",
        email: "",
        fname: "",
        lname: "",
        phone: "",
        enabled: true,
        automated: false,
      },
      error: "",
      projects: null,
      roles: null,
      // options: null,
      password1: "",
      password2: "",
      token: "",
    }
  },

  created() {
    this.updateItem((async () => {
      this.projects = await http.get('/shortcuts/projects');
      this.roles = await http.get('/shortcuts/roles');
      // this.options = await http.get('/calls/filter_options');
      return !this.isNew ? await http.get('/users/' + this.id) : null;
    })());
  },

  methods: {
    onSend() {
      this.updateItem((async () => {
        if (!this.item.automated) {
          if (this.isNew && !this.password1) throw Error("Должен быть задан пароль для нового пользователя.");
          if (this.password1 && this.password1 != this.password2) throw Error("Пароли не совпадают.");
          this.item.password = this.password1;
        } else {
          this.item.password = "";
        }

        const result = await http.post("/users" + (this.id == "new" ? '' : '/' + this.id), this.item);
        this.$store.dispatch("auth/loadUser");
        return result;
      })(), "user", true);
    },

    async onGetToken() {
      try {
        this.token = await http.get("/users/" + this.id + "/token");
        // await navigator.clipboard.writeText(result);
        // alert("Скопировано в буфер обмена");
      } catch (e) {
        this.error = e.message;
      }
    },

    ...mapGetters("auth", ["userId"]),
    format_datetime,
    updateItem

  },

  computed: {
    title() {
      return "Пользователь \"" + this.item.login + "\"";
    },

    isNew() {
      return this.id == "new";
    },

    // isSelf() {
    //   return this.userId() == this.id
    // }
  },


}
</script>

