<template>
  <page-header title="Шаблоны" icon="bi-journal-text">
    <icon-button icon="bi-plus-circle" @click="$router.push({name: 'template', params: {id: 'new'}})"/>
  </page-header>

  <templates-filter
      @filter="$refs.table.doFilter($event)"
      store="templatesFilter"
  />

  <live-table
      url="/templates"
      @click-item="$router.push({name: 'template', params: {id: $event.item.id}})"
      ref="table"
      store="reports" :reload="true"
      :format="[
      ['id','ID', (i)=>i.id],
      ['name','Название', (i)=>i.name],
      ['mode','Тип', (i)=>({report:'Отчет', dashboard:'Дашборд'}[i.mode])],
      ]"/>

</template>

<script>
import LiveTable from "@/components/LiveTable";
import PageHeader from "@/components/PageHeader";
import IconButton from "@/components/IconButton";
import {format_datetime} from '@/misc/util';
import TemplatesFilter from "@/pages/templates/TemplatesFilter";

export default {
  components: {TemplatesFilter, IconButton, PageHeader, LiveTable},
  methods: {
    format_datetime
  },
}
</script>

