<template>
  <page-header title="Компании" icon="bi-people-fill">
    <icon-button icon="bi-plus-circle" @click="$router.push({name: 'project', params: {id: 'new'}})"/>
  </page-header>
  <projects-filter
      @filter="$refs.table.doFilter($event)"
      store="projectsFilter"
  />
  <live-table
      url="/projects"
      @click-item="$router.push({name: 'project', params: {id: $event.item.id}})"
      ref="table"
      store="projects" :reload="true"
      :format="[
        ['id','ID', (i)=>i.id],
        ['name','Название', (i)=>i.name],
        ['domain','Домен', (i)=>i.domain],
        // ['comment','Комментарий', (i)=>i.comment],
        // ['created_at','Создан', (i)=>format_datetime(i.created_at)],
        ['enabled','Активная', (i)=>i.enabled?'Да':'Нет'],
      ]"
  />

</template>

<script>
import LiveTable from "@/components/LiveTable";
import PageHeader from "@/components/PageHeader";
import {format_datetime} from '@/misc/util';
import IconButton from "@/components/IconButton";
import ProjectsFilter from "@/pages/projects/ProjectsFilter";

export default {
  components: {ProjectsFilter, IconButton, PageHeader, LiveTable},
  methods: {
    format_datetime
  }
}
</script>

