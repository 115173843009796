<template>
  <page-header :title="title" icon="bi-telephone-inbound" :back="!$route.query.alone" sticky="1">
    <!--    <icon-button v-if="prevCall" @click="navigate(prevCall)" icon="bi-arrow-left-square" title="Предыдущий вызов"/>-->
    <!--    <icon-button v-if="nextCall" @click="navigate(nextCall)" icon="bi-arrow-right-square" title="Следующий вызов"/>-->

    <template v-slot:raw>
      <div class="flex-grow-1 d-flex justify-content-end align-items-center">
        <audio v-if="item.audio" ref="player" controls controlslist="noplaybackrate nofullscreen" style="width: 45vw">
          <source :src="'/api/v1/audio/'+item.audio">
        </audio>
        <div class="d-flex flex-column fs-5">
          <icon-button v-if="prevCall" @click="navigate(prevCall)" icon="bi-arrow-up-square" title="Предыдущий вызов"/>
          <icon-button v-if="nextCall" @click="navigate(nextCall)" icon="bi-arrow-down-square" title="Следующий вызов"/>
        </div>
      </div>

    </template>
  </page-header>
  <!--  {{ JSON.stringify(item) }}-->
  <div class="row">
    <div class="col">
      <table v-if="ready" class="table">
        <tbody>
        <tr v-for="i in call" :key="i">
          <td>{{ i.name }}</td>
          <td>{{ i.val }}</td>
        </tr>
        </tbody>
      </table>


    </div>
    <div class="col">
      <transcript
          v-for="t in item.transcripts"
          @click="rewind(t);"
          :key="t.id"
          :t="t"
      />

    </div>
  </div>

  <div v-if="item?.raw">
    <button @click="toggleCdr" class="btn btn-outline-primary">{{ cdrVisible ? "Скрыть CDR" : "Показать CDR" }}</button>
    <pre v-if="cdrVisible">{{ JSON.stringify(item.raw, null, 4) }}</pre>
  </div>

</template>

<script>
import {http} from "@/misc/http";
import {format_datetime} from "@/misc/util";
import PageHeader from "@/components/PageHeader";
import {tstore} from "@/tstore";
import IconButton from "../../components/IconButton";
import {mapActions, mapGetters} from "vuex";
import Transcript from "@/pages/calls/Transcript";
import {persistent} from "@/tstore";

export default {
  components: {Transcript, IconButton, PageHeader},
  props: ["id", "callsStore"],
  data() {
    return {
      item: {
        id: "",
      },
      error: "",
      projectCode: null,
      projectConfig: null,
      ready: false,
    }
  },

  async created() {
    this.store = persistent('call', {cdrVisible: false});
    await this.setCookie();
    try {
      this.item = await http.get('/calls/' + this.id);
      await this.tryLoadProject(this.item.dl_project_id);
      this.ready = true;
    } catch (e) {
      this.error = e.message;
    }
  },

  methods: {
    toggleCdr() {
      this.store.cdrVisible = !this.store.cdrVisible;
    },


    rewind(trans) {
      const call_start = Date.parse(this.item.started_at);
      const trans_start = Date.parse(trans.started_at);
      this.$refs.player.currentTime = (trans_start - call_start) / 1000;
      this.$refs.player.play();
    },

    navigate(id) {
      const store = tstore[this.callsStore || 'calls'];
      if (store) store.selectedItem = id;
      this.$router.replace({name: this.$route.name, params: {id: id}});
    },

    async tryLoadProject(dl_project_id) {
      try {
        this.projectCode = await http.get('/codes_view/' + dl_project_id);
        this.projectConfig = JSON.parse(this.projectCode.config);

      } catch (e) {
        this.projectCode = null;
        this.projectConfig = null;
      }
    },

    format_datetime,
    ...mapActions("auth", ["setCookie"]),
  },

  computed: {
    cdrVisible() {
      return this.store.cdrVisible;
    },
    title() {
      return "Вызов " + this.item.id;
    },

    call() {
      const result = [];
      if (this.item) {
        result.push({name: "ID", val: this.item.id});
        result.push({name: "GUID", val: this.item.guid});
        result.push({name: "Домен", val: this.item.domain_name});
        result.push({name: "Проект", val: this.projectCode ? this.projectCode.name + ' (' + this.item.dl_project_id + ')' : this.item.dl_project_id});

        if (!this.projectConfig || this.projectConfig.call?.default?.destination_number)
          result.push({name: "Номер", val: this.item.destination_number});

        if (!this.projectConfig || this.projectConfig.call?.default?.phone)
          result.push({name: "Абонент", val: this.item.phone});

        if (!this.projectConfig || this.projectConfig.call?.default?.started_at)
          result.push({name: "Начало", val: format_datetime(this.item.started_at)});

        if (!this.projectConfig || this.projectConfig.call?.default?.finished_at)
          result.push({name: "Завершение", val: format_datetime(this.item.finished_at)});

        if (!this.projectConfig || this.projectConfig.call?.default?.duration)
          result.push({name: "Длительность", val: this.item.duration + ' секунд'});

        if (!this.projectConfig || this.projectConfig.call?.default?.call_status)
          result.push({name: "Статус", val: this.item.call_status?.name_ru});

        if (!this.projectConfig || this.projectConfig.call?.default?.conversation_release_reason)
          result.push({name: "Завершил", val: this.item.conversation_release_reason?.name_ru});

        if (!this.projectConfig || this.projectConfig.call?.default?.dl_topic)
          result.push({name: "Тематика", val: this.item.dl_topic});

        if (!this.projectConfig || this.projectConfig.call?.default?.dl_result)
          result.push({name: "Результат", val: this.item.dl_result});

        for (const v of this.projectConfig?.call?.variables || []) {
          result.push({name: v.description, val: this.item?.variables?.[v.name]});

        }
      }
      return result
    },

    prevCall() {
      const a = tstore[this.callsStore || 'calls']?.items || [];
      for (let i = 0; i < a.length; i++) {
        if (a[i].id == this.id && i > 0) return a[i - 1].id;
      }
      return null;
    },
    nextCall() {
      const a = tstore[this.callsStore || 'calls']?.items || [];
      for (let i = 0; i < a.length; i++) {
        if (a[i].id == this.id && i < a.length - 1) return a[i + 1].id;
      }
      return null;
    },
    ...mapGetters('auth', ['token'])

  },

}
</script>

<style>

html.dark audio::-webkit-media-controls-panel {
  background-color: #222222;
}

audio::-webkit-media-controls-panel {
  background-color: white;
}

</style>