<template>
  <!--eslint-disable vue/no-mutating-props-->
  <table>
    <tr v-if="data.length">
      <td class="form-text" style="width: 50%">Имя</td>
      <td class="form-text">Операция</td>
      <td class="form-text" style="width: 50%">Знач</td>
    </tr>
    <tr
        v-for="(v, i) in data"
        :key="i"
    >
      <td><input v-model="data[i].lvalue" type="text" class="form-control form-control-sm"></td>
      <td>
        <select v-model="data[i].op" class="form-select form-select-sm">
          <option value="==">==</option>
          <option value="!=">!=</option>
          <option value=">">&gt;</option>
          <option value="<">&lt;</option>
          <option value=">=">&gt;=</option>
          <option value="<=">&lt;=</option>
        </select>
      </td>
      <td><input v-model="data[i].rvalue" type="text" class="form-control form-control-sm"></td>
      <td>
        <icon-button @click="data.splice(i, 1)" icon="bi-trash" title="Удалить"/>
      </td>
    </tr>
  </table>
  <icon-button @click="$emit('do-add')" icon="bi-plus-circle" title="Добавить"/>

</template>

<script>
import IconButton from "../../../../components/IconButton";

export default {
  components: {IconButton},
  props: ["data"],
  emits: ["do-add"]
}
</script>

<style scoped>

</style>