<template>
  <div class="card mb-2">
    <div class="card-body">
      <!--      <h5 class="w100">Внимание ! <button @click="visible=false" type="button" class="btn-close float-end"/></h5>-->
      <h5 class="w100"><i @click="visible=!visible" class="bi" :class="'bi-chevron-' + (visible?'down':'right')"/>&nbsp;Внимание ! </h5>
      <ul v-if="visible">
        <!-- todo: про переменные -->
        <li>
          Шаблоны отчетов разрабатываются на языке SQL, все колонки, которые будут получены,
          имеют формат и имя соответствующие колонке в базе данных (в отличии от колонок,
          которые представлены в списке вызовов, которые приводятся к виду для пользователя).
        </li>
        <li>
          Язык SQL позволяет проводить произвольные манипуляции в базе данных.
          Ответственность администраторов предоставить доступ к базе данных только на чтение.
          <b class="text-danger">Разработчик отчета несет ответственность за неповреждение данных в базе.</b>
          Разрабатывать отчеты могут только суперадминистраторы.
        </li>
        <li>
          Ответственность разработчика шаблона - обеспечить фильтрацию данных по домену.
          <b class="text-danger">
            Разработчик отчета несет ответственность за фильтрацию по недопущению попадания
            данных из других доменов в отчет пользователя!
          </b>
          Для этого необходимо использовать
          колонку <b>domain_name</b> и параметр <b>:domain</b> в тексте sql.
          В параметре <b>:domain</b> содержится домен проекта, к которму привязан
          авторизованный пользователь.
          Не следует путать с параметром <b>:domain_name</b> который можно выбрать из фильтра
          и обеспечить дополнительную фильтрацию для отчетов, доступных только суперадминам,
          например для составления сводных отчетов по проектам.
          Также в целях унификации отчетов для админов и пользователей доступен параметр
          <b>:domain_authorized</b>, он содержит домен пользователя или '',
          если отчет просматривает суперадминистратор.
          В отчетах для суперадминистраторов фильтрацию по домену можно не использовать.
        </li>
        <li>
          Отчеты могут быть разбиты на страницы. Для этого необходимо заполнить поле
          выражения для подсчета общего количества элементов (без учета разбивки на страницы).
          В основном выражении необходимо в этом случае использовать параметры
          <b>:start</b> <b>:count</b>.
          Можно добавить поддержку сортировки по параментам <b>:sort</b> и <b>:desc</b>.
          <br>
          <b>Отчеты возвращающие данные из базы в неаггрегрированном виде <b class="text-danger">ДОЛЖНЫ</b> быть рабиты на страницы!</b>
        </li>
        <li>
          Если отчет содержит список вызовов, то он может быть кликабельным, в этом случае отчет должен
          содержать колонку с именем <b>id</b> (именно так - маленькими буквами) и должен быть
          установлен соответствующий флажок. В этом случае при щелчке на строке таблицы с
          запущенным отчетом производится переход на соответствующий вызов, при этом доступны
          кнопки предыдущий/следующий вызов (переходы производятся по списку из отчета).
        </li>
        <li>
          Разработчик шаблона может использовать параметры из фильтра вызовов.
          <ul>
            <li><b>:domain_name</b> - домен авторизованного пользователя.</li>
            <li><b>:dl_project_id</b> - код подпроекта</li>
            <li><b>:destination_number</b> - номер назначения</li>
            <li><b>:contains</b> - строка поиска</li>
            <li><b>:dl_result</b> - результат</li>
            <li><b>:dl_topic</b> - тематика</li>
            <li><b>:t_from</b> - дата/время начала для интервального отчета</li>
            <li><b>:t_to</b> - дата/время завершения (не включая) для интервального отчета</li>
            <li><b>:interval</b> - интервал агрегации;
              <b>Внимание!</b> При разработке агрегирующих отчетов по времени, в т.ч. с использованием этого параметра
              необходимо принудительно лимитировать выдачу, чтобы не получать слишком больших таблиц/графиков
              при больших периодах и малых интервалов агрегации.
            </li>
            Следующие параметры использовать не рекомендуется во избежании путанницы с
            преобразованием sql-представление для пользователя.
            <li><b>:call_status_name</b> - соответствует колонке <b>hangup_cause</b></li>
            <li><b>:conversation_release_reason_name</b> - содержит 'user' или 'system'.
              Для соответствовия колонке <b>sip_hangup_dispositions</b> разработчик
              отчета сам должен привести это значение к
              'system'->['send_bye', 'send_cancel', 'send_refuse'] и
              'user'->['recv_bye', 'recv_cancel', 'recv_refuse'].
            </li>
          </ul>
        </li>
        <li>
          Отчет может использовать дополнительные параметры, которые необходимо задать
          в соответствующем поле в JSON формате, например мы хотим использовать в отчете
          целочисленный параметр <b>:some</b>, для этого его необходимо прописать в JSON как
          { "some": 10 }. Обратите внимание, что для всех дополнительных параметров
          необходимо задать значение по умолчанию, иначе отчет не запустится. Если установить
          флажок "Специальный", то параметры можно будет редактировать во время исполнения отчета.
          Специальные отчеты не отображаются в списке "Отчеты", однако пользователь
          имеет возможность его запустить используя идентификатор отчета. Функционал реализован
          для интеграции с внешними системами.
        </li>
        <li>
          Для внешних пользователей (пользователей с ограничением по проекту)
          необходимо разрабатывать отдельные отчеты, в которых фильтрация
          по проекту осуществляется в коде sql жестко, без использования параметра
          <b>:dl_project_id</b>. При этом что бы внешний пользователь такой отчет увидел
          необходимо в шаблоне прописать имя проекта, соответствующего привязке пользователя.
        </li>
        <li>
          Схема данных таблицы <b>v_xml_cdr</b> с примерами значений приведена ниже.
        </li>
      </ul>
      <small v-if="!!error" class="text-danger">{{ error }}</small>
      <table v-if="schema && visible" class="table">
        <tbody>
        <tr v-for="(v,k) in schema" :key="k">
          <td>{{ k }}</td>
          <td>{{ v }}</td>
        </tr>
        </tbody>
      </table>

    </div>
  </div>

</template>

<script>
import {persistent} from "@/tstore";
import {http} from "@/misc/http";

export default {
  setup() {
    return persistent("templateAttention", {
      visible: true,
      schema: null,
      error: ""
    });
  },

  async created() {
    if (!this.schema) {
      try {
        this.schema = await http.get('/templates/schema');
      } catch (e) {
        this.error = e.message;
      }
    }
  },
  // data() {
  //   return {
  //     visible: true
  //   }
  // }
}
</script>