import SayForm from "./forms/SayForm";
import InputForm from "./forms/InputForm";
import PauseForm from "./forms/PauseForm";
import VariableSetForm from "./forms/VariableSetForm";
import FunctionsForm from "./forms/FunctionsForm";
import RedirectForm from "./forms/RedirectForm";
import HttpForm from "./forms/HttpForm";
import StartForm from "./forms/StartForm";
import VariableCheckForm from "./forms/VariableCheckForm";
import TimeCheckForm from "./forms/TimeCheckForm";
import SubScenarioForm from "./forms/SubScenarioForm";
import EmailForm from "./forms/EmailForm";
import ReturnForm from "./forms/ReturnForm";

const conn_data = {
  name: "По умолчанию",
  autoname: true,
  type: "default", // error, values, logic, time
  equal: [],
  in: [],
  not_in: [],
  pcre2: [], // требуется поддержка обратной совместимости #BCR

  ops_logic: 'or', // может отсутствовать в старых скриптах, корректно отрабатывается в форме, undefined обозначает или в режиме совместимости
  ops: [], // [{logic: '==', lvalue: "%var%", rvalue: "10"}]

  subdata: {  // требуется поддержка обратной совместимости #BCR
    ops_logic: 'or',
    ops: [],
    subops: [], // [subdata...]
  },

  // logic: '==', // Заменено на ops
  // lvalue: "%var%",
  // rvalue: "10",
  priority: "10",
  tfrom: "10:00",
  tto: "22:00"

}

export {conn_data}


const tts_default = {
  voice: {
    id: "alyona", // "alyona",

    // yandex
    speed: 1,
    emotion: "neutral",

    // tinkoff
    rate: null,
    pitch: null,
  },
  language: "ru-RU",
  provider: "tinkoff", // "tinkoff",
}

export {tts_default}

const asr_default = {
  grammar: "any",
  language: "ru-RU",
  provider: "tinkoff",
  // asr_denorm - добавляется формой блока Input при редактировании
}

export {asr_default}

// const tts_default = {
//   voice: {
//     id: "alena", // "alyona",
//
//     // yandex
//     speed: 1,
//     emotion: "neutral",
//
//     // tinkoff
//     rate: null,
//     pitch: null,
//   },
//   language: "ru-RU",
//   provider: "yandex", // "tinkoff",
// }
//

const color_schemas = {
  default: {
    Start: {
      color: '#88e09f',
    },
    Say: {
      color: '#f2a712',
    },
    Input: {
      color: '#f2a712',
    },
    Pause: {
      color: '#f2a712',
    },
    VariableSet: {
      color: '#657bdb',
    },
    Functions: {
      color: '#657bdb',
    },
    VariableCheck: {
      color: '#657bdb',
    },
    Redirect: {
      color: '#9086c6',
    },
    Http: {
      color: '#9086c6',
    },
    Email: {
      color: '#f3663f',
    },
    TimeCheck: {
      color: '#f3663f',
    },
    SubScenario: {
      color: '#88e09f',
    },
    Return: {
      color: '#88e09f',
    },
  },
  mono: {
    Start: {
      color: '#6c757d',
      shadow: '#0d6efd'
    },
    Say: {
      color: '#6c757d',
      shadow: '#0d6efd'
    },
    Input: {
      color: '#6c757d',
      shadow: '#0d6efd'
    },
    Pause: {
      color: '#6c757d',
      shadow: '#0d6efd'
    },
    VariableSet: {
      color: '#6c757d',
      shadow: '#0d6efd'
    },
    Functions: {
      color: '#6c757d',
      shadow: '#0d6efd'
    },
    VariableCheck: {
      color: '#6c757d',
      shadow: '#0d6efd'
    },
    Redirect: {
      color: '#6c757d',
      shadow: '#0d6efd'
    },
    Http: {
      color: '#6c757d',
      shadow: '#0d6efd'
    },
    Email: {
      color: '#6c757d',
      shadow: '#0d6efd'
    },
    TimeCheck: {
      color: '#6c757d',
      shadow: '#0d6efd'
    },
    SubScenario: {
      color: '#6c757d',
      shadow: '#0d6efd'
    },
    Return: {
      color: '#6c757d',
      shadow: '#0d6efd'
    },
  },
}

export {color_schemas}

const functionData = {
  name: "~str_replace",
  regex: ".*",
  flag: "",
  var: "var_1",
  varstr: '"строка"',
  explode_id: "",
  join_id: "",
  arr: "arr",
  arrel: "arr.list.1.dt",
}

export {functionData}

export default {
  Start: {
    icon: "flag",
    form: StartForm,
    data: {
      name: "Старт",
      comment: "",
      undeletable: true,
      settings: {},
    }
  },

  Say: {
    icon: "chat-dots",
    form: SayForm,
    data: {
      name: "Сказать",
      comment: "",
      settings: {
        say_type: "tts",
        tts_params: Object.assign({}, tts_default),
        message_params: {
          ssml: false,
          text: "Здравствуйте.",
        },
        audio_record_id: null,
      },
    }
  },

  Input: {
    icon: "clock-history",
    form: InputForm,
    // links: ["default", "error", "error2", "values"],
    links: ["default", "values"],
    data: {
      name: "Ожидание ввода",
      comment: "",
      settings: {
        say_type: "no_question",
        variable: "var_10",
        asr_params: asr_default,
        input_type: "voice",
        timeout_start: 5,
        timeout_end: 1,
        tts_params: tts_default,
        message_params: {
          text: "Здравствуйте.",
          ssml: false,
        },
        allow_interrupt: false,
        record_users_response: true,

        audio_record_id: null,
        tts_dictionary_id: null,
        recognition_failure_phrase: {
          type: "default", // tts audio
          ssml: false,
          text: "Ошибка распознавания",
          audio_record_id: null

        },
        recognition_failure_repeat_count: 3,
        recognition_failure_repeat_phrase: {
          type: "default", // tts audio
          ssml: false,
          text: "Повторная ошибка распознавания",
          audio_record_id: null
        }
      },

    }
  },

  Pause: {
    icon: "pause-btn",
    form: PauseForm,
    data: {
      name: "Пауза",
      comment: "",
      settings: {
        timeout: 5,
      }
    }
  },

  VariableSet: {
    icon: "box-arrow-in-down-left",
    form: VariableSetForm,
    data: {
      name: "Переменные",
      comment: "",
      variables: [],
      settings: {
        variables: {}
      }
    }
  },

  VariableCheck: {
    icon: "box-arrow-up-right",
    form: VariableCheckForm,
    links: ["default", "logic"],
    data: {
      name: "Проверка",
      comment: "",
      settings: {}
    }
  },

  Functions: {
    icon: "braces",
    form: FunctionsForm,
    data: {
      name: "Функции",
      comment: "",
      functions: [
        Object.assign({}, functionData),
      ],
      settings: {}
    }
  },

  Redirect: {
    icon: "telephone-forward",
    form: RedirectForm,
    links: ["default", "error"],
    data: {
      name: "Перенаправить",
      comment: "",
      settings: {
        redirect_type: "phone", // sip phone variable
        wait_audio: "silence", // silence beeps [audio]
        destination: "sip:",
        message: "Здравствуйте.",
        sip_headers: {}, // computed
        audio_record_id: null, // ?
      },
      redirect_type: "sip", // sip phone variable?
      destination: "",

      sip_headers: [],
    }
  },

  Http: {
    icon: "arrow-left-right",
    form: HttpForm,
    // links: ["default", "error"],
    links: ["default"],
    data: {
      name: "Запрос",
      comment: "",
      settings: {
        url: "http://...",
        body: "{\"foo\": \"bar\"}",
        timeout: 15,
        request_type: "post",
        parse_json_response: true,
      },
    }
  },

  Email: {
    icon: "envelope",
    form: EmailForm,
    data: {
      name: "Email",
      comment: "",
      settings: {
        domain: "optimalcity.ru",
        address: [],
        subject: "Заголовок",
        message: "Сообщение",
        recipient: "manual",
        send_method: "infobot",
        integration_id: null
      }
    }
  },

  TimeCheck: {
    icon: "alarm",
    form: TimeCheckForm,
    links: ["default", "time"],
    data: {
      name: "Текущее время",
      comment: "",
      settings: {},
    }
  },

  SubScenario: {
    icon: "diagram-2",
    form: SubScenarioForm,
    links: ["default", "logic"],
    data: {
      name: "Подсценарий",
      comment: "",
      settings: {
        scenarioId: null,
      },
    }
  },

  Return: {
    icon: "arrow-return-left",
    form: ReturnForm,
    links: [],
    data: {
      name: "Возврат",
      comment: "",
      settings: {
        return_type: "default",
        return_name: ""
      },
    }
  },

}

