<template>
  <div class="d-flex flex-column px-3 w-100">
    <page-header :title="title" icon="bi-diagram-3-fill" :back="{name: 'script', params: {id: id}}">
      <icon-button @click="onSave" class="fs-3" icon="bi-save" title="Сохранить"/>
      <span v-if="spinner" class="spinner-grow spinner-grow-sm"/>
      <!--        <span v-if="spinner" style="width: 1.5rem; height: 1.5rem" class="spinner-border"/>-->
    </page-header>

    <small v-if="!!error" class="text-danger">{{ error }}</small>

    <div class="d-flex flex-grow-1 gap-3" style="height: calc(100vh - var(--pg-header-height) - 1rem)">
      <div class="d-flex flex-column gap-1">
        <menu-item v-for="(b, name) in menu_items" :key="name" :icon="b.icon" :color="$options.color_schemas[prefs.schema][name].color" :name="name" :title="b.data.name"/>
      </div>

      <editor
          :prefs="prefs"
          ref="editor"
          @drop-here="addItem($event.type, $event.x, $event.y)"
          @do-select="this.selected = $event"
          @add-connection="addConnection($event.src, $event.dst)"
      />

      <div class="shadow" :style="'flex: 0 0 '+paneWidth+'%'" style="overflow-y: scroll">
        <div v-if="!selected" class="m-0 px-2 pt-2 d-flex gap-1 fs-5" style="position: sticky; top: 0">
          <expand-button @expand="expand" :levels="paneWidths.length"/>
          <icon-button icon="bi-plus-circle" title="Создать сценарий" @click="addScenario"/>
          <icon-button v-if="scenariosData.selectedScenario && scenariosData.selectedScenario.user" icon="bi-trash" title="Удалить сценарий" @click="deleteScenario"/>
          <icon-button icon="bi-cloud-download" title="Сохранить сценарий" @click="saveScenario"/>
          <icon-button icon="bi-cloud-upload" title="Загрузить сценарий" @click="loadScenario"/>
          <icon-button v-if="$refs.editor?.undo_buffer" icon="bi-arrow-counterclockwise" title="Undo" @click="$refs.editor.undoDo()"/>
          <icon-button v-if="$refs.editor?.redo_buffer" icon="bi-arrow-clockwise" title="Redo" @click="$refs.editor.undoRedo()"/>
        </div>
        <div v-if="selected && selected.what=='item'" class="m-0 px-2 pt-2 d-flex gap-1 fs-5" style="position: sticky; top: 0">
          <expand-button @expand="expand"/>
          <icon-button icon="bi-check-circle" title="Готово" @click="$refs.editor.unselectAll()"/>
          <icon-button icon="bi-trash" title="Удалить" @click="$refs.editor.deleteSelected()"/>
          <icon-button icon="bi-chevron-bar-left" title="Соединить отсюда" @click="$refs.editor.connection(true)"/>
          <icon-button icon="bi-chevron-bar-right" title="Соединить сюда" @click="$refs.editor.connection(false)"/>
          <icon-button icon="bi-box-arrow-in-up" title="Копировать" @click="doCopy"/>
          <icon-button v-if="canPaste()" icon="bi-box-arrow-down" title="Вставить" @click="doPaste"/>
        </div>
        <div v-else-if="selected && selected.what=='connection'" class="m-0 px-2 pt-2 d-flex gap-1 fs-5" style="position: sticky; top: 0">
          <expand-button @expand="expand"/>
          <icon-button icon="bi-check-circle" title="Готово" @click="$refs.editor.unselectAll()"/>
          <icon-button icon="bi-trash" title="Удалить" @click="$refs.editor.deleteSelected();"/>
          <icon-button icon="bi-box-arrow-in-up" title="Копировать" @click="doCopy"/>
          <icon-button v-if="canPaste()" icon="bi-box-arrow-down" title="Вставить" @click="doPaste"/>
        </div>

        <!--suppress JSValidateTypes -->
        <component
            v-if="script"
            :is="form"
            :data="data"
            :script="script"
            :scenariosData="scenariosData"
            :prefs="prefs"
            @do-filter="onFilter"
            :links="links"
            @keydown.esc="$refs.editor.unselectAll()"
            @submit.prevent="$refs.editor.unselectAll()"
        />


      </div>
    </div>
  </div>

  <!--      <pre>{{ JSON.stringify(this.script?.flow, null, 4) }}</pre>-->

</template>

<script>
import PageHeader from "../../components/PageHeader"
import MenuItem from "./MenuItem"
import Editor from "./Editor";
import FlForm from "./forms/components/FlForm";
import FlGroup from "./forms/components/FlGroup";
import FlInput from "./forms/components/FlInput";

import ConnectionForm from "./forms/ConnectionForm";
import blocks, {conn_data, tts_default, asr_default} from "./blocks";
import IconButton from "../../components/IconButton";
import {http} from "@/misc/http";
import {computed} from "vue";
import GlobalForm from "./forms/GlobalForm";
import {clickDownloadJson, readTextFile, selectFile} from "@/misc/util";
import ExpandButton from "./ExpandButton";
import {persistent, update_local} from "@/tstore";
import {color_schemas} from "@/pages/flow/blocks";

const defaultCommon = {
  tts: false,
  asr: false,
  settings: {
    tts_params: Object.assign({}, tts_default),
    asr_params: Object.assign({}, asr_default),
  }
}

const paneWidths = [25, 50, 75];

export default {
  components: {GlobalForm, ExpandButton, IconButton, FlInput, FlGroup, FlForm, Editor, MenuItem, PageHeader},
  props: ["id"],

  data() {
    return {
      selected: null, // null - nor item, nor connection, else {what: 'item'|'connection', id: id}
      script: null,
      error: "",
      spinner: false,
      paneWidths: paneWidths,
      paneWidth: paneWidths[0],
      clipboard: null,
      scenariosData: {
        scenarios: [
          {id: "answered", name: "@Ответ (основной)", type: "subscenario", version: "1.0.0"},
          {id: "busy", name: "@Занято", hiddable: true, type: "subscenario", version: "1.0.0"},
          {id: "before_call", name: "@Перед звонком", hiddable: true, type: "subscenario", version: "1.0.0"},
          {id: "after_call", name: "@После звонка", hiddable: true, type: "subscenario", version: "1.0.0"},
          {id: "failed_call", name: "@Неудачный звонок", hiddable: true, type: "subscenario", version: "1.0.0"},
          {id: "no_answer", name: "@Нет ответа", hiddable: true, type: "subscenario", version: "1.0.0"},
          {id: "voice_mail", name: "@Голосовая почта", hiddable: true, type: "subscenario", version: "1.0.0"},
          {id: "wrong_number", name: "@Неправильный номер", hiddable: true, type: "subscenario", version: "1.0.0"},
          {id: "intent", name: "#Глобальный интент", intent: true, type: "subscenario", version: "1.0.0"},
        ],
        selectedScenario: null,
        showStandard: false,
        sid: 1,
        findWhat: "",
        filteredItems: null,
      },
      prefs: persistent('prefs', {
        show_grid: true,
        schema: 'default',
      }, true),

    }
  },

  provide() {
    return {
      // script: computed(() => this.script),
      // scenariosData: this.scenariosData,
      editor: computed(() => this.$refs.editor),
    }
  },

  async mounted() {
    await this.load()
    if (this.script) {
      this.timer = setInterval(this.save, 5000);
      this.collectScenarios();
      this.scenariosData.selectedScenario = this.scenariosData.scenarios[0];
    }
  },

  async beforeUnmount() {
    if (this.timer) clearInterval(this.timer);
  },

  computed: {
    form() {
      if (!this.selected) return GlobalForm;
      if (this.selected.what == 'item') {
        const item = this.$refs.editor.items[this.selected.id];
        return this.$options.blocks[item.type].form;
      } else if (this.selected.what == 'connection') {
        return ConnectionForm;
      }
      return null;
    },
    data() {
      if (this.selected?.what == 'item') {
        const item = this.$refs.editor.items[this.selected.id];
        return item.data;
      } else if (this.selected?.what == 'connection') {
        const con = this.$refs.editor.connections[this.selected.id];
        return con.data;
      }
      return null;
    },
    links() { // возвращает список вариантов соединений для выделенного блока, а не сами соединения
      if (!this.selected || this.selected.what == 'item') return;
      const con = this.$refs.editor.connections[this.selected.id];
      const item = this.$refs.editor.items[con.src];
      const block = this.$options.blocks[item.type];
      return block.links || [];
    },
    selectedScenario() {
      return this.scenariosData.selectedScenario;
    },
    title() {
      if (!this.script) return "Конструктор";
      if (!this.scenariosData.selectedScenario) return this.script.name;
      return this.scenariosData.selectedScenario.name + ' < ' + this.script.name;
    },

    menu_items() {
      const blocks = {}
      for (const b in this.$options.blocks) {
        if (b == 'Start') continue;
        // if (b == "SubScenario" && (this.selectedScenario?.user || this.selectedScenario?.intent)) continue;
        if (b == "SubScenario" && this.selectedScenario?.intent) continue;
        if (b == "Return" && !(this.selectedScenario?.user || this.selectedScenario?.intent)) continue;
        blocks[b] = this.$options.blocks[b];
      }
      return blocks;

    }

  },

  watch: {
    selectedScenario(scenario, prevScenario) {
      if (!this.script) return;
      if (prevScenario) this.updateFlow(prevScenario);

      if (!this.script.flow.scenarios[scenario.id]) {
        this.script.flow.scenarios[scenario.id] = {
          items: {},
          connections: {},
          meta: scenario
        }
      }
      this.refillEditor();
    },
    prefs: {
      deep: true,
      handler() {
        update_local('prefs');

        if (this.prefs.dark) {
          if (!document.documentElement.classList.contains('dark'))
            document.documentElement.classList.add('dark');
          document.documentElement.style.setProperty("color-scheme", 'dark');
        } else {
          if (document.documentElement.classList.contains('dark'))
            document.documentElement.classList.remove('dark');
          document.documentElement.style.setProperty("color-scheme", 'light');
        }
      }

    },
  },

  methods: {
    async load() {
      this.spinner = true;
      try {
        this.script = await http.get('/scripts/' + this.id);
        if (JSON.stringify(this.script.flow) == "{}") {
          this.script.flow = {
            meta: {
              type: "scenario",
              name: this.script.name,
              version: "1.0.0",
            },
            scenarios: {},
            common: Object.assign({}, defaultCommon),
          }
        } else {
          if (!this.script.flow.meta || this.script.flow.meta.type != "scenario" || this.script.flow.meta.version != "1.0.0") {
            throw Error("Неподдерживаемый формат сценария.");
          }
          // миграции
          if (!this.script.flow.common) {
            this.script.flow.common = Object.assign({}, defaultCommon);
          } else {
            if (!('asr' in this.script.flow.common)) {
              this.script.flow.common.asr = false;
              this.script.flow.common.settings.asr_params = Object.assign({}, asr_default);
            }
          }
        }
        this.error = "";
      } catch (e) {
        this.script = null;
        this.error = e.message;
      }
      this.spinner = false;
    },

    // todo: забиндить едитор на объект скрипта? Да. Надо это все рефакторить.
    updateFlow(fromScenario = null) {
      if (!fromScenario) fromScenario = this.scenariosData.selectedScenario;
      if (this.script.flow.scenarios[fromScenario.id]) {
        this.script.flow.scenarios[fromScenario.id].items = JSON.parse(JSON.stringify(this.$refs.editor.items));
        this.script.flow.scenarios[fromScenario.id].connections = JSON.parse(JSON.stringify(this.$refs.editor.connections));
        this.script.flow.scenarios[fromScenario.id].meta = JSON.parse(JSON.stringify(fromScenario));
      }

    },

    async save() {
      if (!this.script) return;
      this.updateFlow();

      let result = false;

      try {
        this.script = await http.post('/scripts/' + this.id, this.script);
        this.error = "";
        result = true;
      } catch (e) {
        this.error = e.message;
      }
      return result;
    },

    async onSave() {
      this.spinner = true;
      const result = await this.save();
      this.spinner = false;
      return result;
    },

    // todo: перенести логику в Editor?
    addItem(type, x, y, data = null, id = null, force_undeletable = false) {
      const block = this.$options.blocks[type];
      const block_data = data || JSON.parse(JSON.stringify(block.data));
      if (force_undeletable) block_data.undeletable = true;
      this.$refs.editor.addItem(
          type, block.icon,
          block_data,
          x, y, id);
    },

    addConnection(src, dst, data = null, id = null) {
      this.$refs.editor.addConnection(
          src, dst,
          data || JSON.parse(JSON.stringify(conn_data)),
          id);
    },

    addScenario() {
      // noinspection JSCheckFunctionSignatures
      const i = this.scenariosData.scenarios.push({
        id: this.scenariosData.sid,
        name: "Сценарий " + this.scenariosData.sid,
        user: true,
        type: "subscenario",
        version: "1.0.0"
      });
      this.scenariosData.sid++;
      this.scenariosData.selectedScenario = this.scenariosData.scenarios[i - 1];
    },

    deleteScenario() {
      if (!confirm("Вы уверены?")) return;
      for (let i = 0; i < this.scenariosData.scenarios.length; i++) {
        if (this.scenariosData.scenarios[i] == this.scenariosData.selectedScenario) {
          this.scenariosData.selectedScenario = this.scenariosData.scenarios[0];
          delete this.script.flow.scenarios[this.scenariosData.scenarios[i].id];
          this.scenariosData.scenarios.splice(i, 1);
          break;
        }
      }
    },

    collectScenarios() {
      for (const sid in this.script.flow.scenarios) {
        const meta = this.script.flow.scenarios[sid].meta;
        if (!meta) continue;
        if (meta.user) {
          this.scenariosData.scenarios.push(meta);
          if (meta.id >= this.scenariosData.sid) this.scenariosData.sid = meta.id + 1;
        }
      }
    },

    refillEditor() {
      const flow = this.script.flow.scenarios[this.scenariosData.selectedScenario.id];

      this.$refs.editor.clear();
      if (JSON.stringify(flow.items) == "{}") {
        if (this.scenariosData.selectedScenario.intent) this.addItem('Input', 200, 100, null, null, true);
        else this.addItem('Start', 200, 100);
      } else {
        for (const id in flow.items) {
          const item = flow.items[id];
          this.addItem(item.type, item.x, item.y, item.data, item.id);
        }
        for (const id in flow.connections) {
          const con = flow.connections[id];
          this.addConnection(con.src, con.dst, con.data, con.id);
        }
      }

      // грязноватый хак чтобы обеспечить корректность работы Навигации в GlobalForm
      // this.store.filteredItems = null;
      // this.store.findWhat = "";

      this.$refs.editor.positionOnItem();

    },

    loadScenario() {
      selectFile(file => {
        readTextFile(file, async content => {
          try {
            const subscenario = JSON.parse(content);
            if (!subscenario.meta || subscenario.meta.type != "subscenario" || subscenario.meta.version != "1.0.0")
              throw Error("Неподдерживаемый формат файла.");
            this.script.flow.scenarios[this.scenariosData.selectedScenario.id] = subscenario;
            // this.scenariosData.selectedScenario.name = subscenario.meta.name;
            this.refillEditor();
          } catch (e) {
            alert("Ошибка: " + e.message);
          }
        })
      });
    },

    saveScenario() {
      clickDownloadJson(
          this.script.flow.scenarios[this.scenariosData.selectedScenario.id],
          this.script.name + "_" + this.scenariosData.selectedScenario.name
      );

    },

    expand(e) {
      this.paneWidth = paneWidths[e];
    },


    doCopy() {
      if (!this.selected) return;

      if (this.selected.what == "item") {
        this.clipboard = {
          what: "item",
          item: JSON.parse(JSON.stringify(this.$refs.editor.items[this.selected.id])),
        }

      } else if (this.selected.what == "connection") {
        const con = this.$refs.editor.connections[this.selected.id];
        this.clipboard = {
          what: "connection",
          connection: JSON.parse(JSON.stringify(con)),
          item: JSON.parse(JSON.stringify(this.$refs.editor.items[con.src])),
        }

      }
    },
    doPaste() {
      if (!this.canPaste()) return;
      if (this.clipboard.what == "item") {
        const item = this.$refs.editor.items[this.selected.id];
        item.data = JSON.parse(JSON.stringify(this.clipboard.item.data));
      } else if (this.clipboard.what == "connection") {
        const con = this.$refs.editor.connections[this.selected.id];
        con.data = JSON.parse(JSON.stringify(this.clipboard.connection.data));
      }
    },

    canPaste() {
      if (!(this.clipboard && this.selected)) return false;
      if (this.clipboard.what != this.selected.what) return false;

      if (this.selected.what == "item") {
        const item = this.$refs.editor.items[this.selected.id];
        if (item.type != this.clipboard.item.type) return false;
      } else if (this.selected.what == "connection") {
        const con = this.$refs.editor.connections[this.selected.id];
        const item = this.$refs.editor.items[con.src];
        if (item.type != this.clipboard.item.type) return false;
      }
      return true;
    },

    onFilter() {
      if (!this.scenariosData.findWhat) {
        this.scenariosData.filteredItems = null;
        return;
      }
      this.updateFlow();
      this.scenariosData.filteredItems = {};
      for (let sid in this.script.flow.scenarios) {
        const scenario = this.script.flow.scenarios[sid];
        const filteredItems = [];
        for (let id in scenario.items) {
          const item = scenario.items[id];
          const findWhere = [item.data.name, item.data.comment, item.data.settings];
          for (let cid in scenario.connections) {
            const con = scenario.connections[cid];
            if (con.src == id) findWhere.push(con.data);
          }
          if (JSON.stringify(findWhere).includes(this.scenariosData.findWhat)) filteredItems.push(item);
        }
        if (filteredItems.length) this.scenariosData.filteredItems[sid] = filteredItems;
      }
      // console.log(JSON.stringify(this.scenariosData.filteredItems));
    },

  },

  async beforeRouteLeave(rto, rfrom, next) {
    await this.onSave();
    next();
    // if (await this.onSave()) next();
  },

  blocks,
  color_schemas
}
</script>

