<!--eslint-disable vue/no-mutating-props-->
<template>
  <fl-form>
    <fl-group name="Блок 'Сказать'">
      <fl-input v-model="data.name" label="Название блока"/>
      <fl-input type="textarea" v-model="data.comment" label="Комментарий"/>
    </fl-group>

    <fl-group name="Сообщение">

      <fl-input v-model="data.settings.say_type" type="select" label="Режим">
        <option value="tts">TTS</option>
        <option value="audio">Аудиофайл</option>
      </fl-input>

      <say-subform
          :script="script"
          :type="data.settings.say_type"
          :message_params="data.settings.message_params"
          :audio_settings="data.settings"
      />

    </fl-group>

    <tts v-if="!script.flow.common.tts && data.settings.say_type=='tts'" :data="data"/>

  </fl-form>
</template>

<script>
import FlForm from "./components/FlForm";
import FlGroup from "./components/FlGroup";
import FlInput from "./components/FlInput";
import Tts from "./subforms/Tts";
import SaySubform from "./subforms/SaySubform";

export default {
  components: {SaySubform, Tts, FlInput, FlGroup, FlForm},
  props: ["data", "script"],

}
</script>

