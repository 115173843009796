<template>
  <div :class="'form-floating '+'col-'+w || 12">
    <textarea v-if="type=='textarea'" v-model="inputVal" :disabled="disabled" ref="input" class="form-control" style="height: auto" :rows="rows || 5"/>
    <select v-else-if="type=='select'" v-model="inputVal" :disabled="disabled" ref="input" class="form-select">
      <slot/>
    </select>
    <input v-else v-model="inputVal" :type="type" :disabled="disabled" ref="input" class="form-control" autocomplete="new-password">
    <label>{{ label }}</label>
  </div>

</template>

<script>
export default {
  name: "FInput",
  props: ["label", "modelValue", "w", "type", "disabled", "rows"],
  computed: {
    inputVal: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit('update:modelValue', val);
      }
    }
  }
}
</script>

