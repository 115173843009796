<template>
  <div>
    <div v-if="error" class="form-text text-danger">{{ error }}</div>
    <form @submit.prevent="onFilter(false)">
      <div class="d-flex gap-2">
        <fl-input v-model="filter.contains" label="Искать"/>
        <div class="d-flex gap-2">
          <icon-button @click="onFilter(false)" icon="bi-check-lg" class="fs-5 align-self-center" style="position: relative; top:0.6rem;" title="Применить"/>
          <icon-button @click="onFilter(true)" icon="bi-x-lg" class="fs-5 align-self-center" style="position: relative; top:0.6rem;" title="Сбросить"/>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import IconButton from "../../components/IconButton";
import FlInput from "../flow/forms/components/FlInput";
import {persistent} from "@/tstore";

const clean_filter = {
  contains: "",
};

export default {
  components: {FlInput, IconButton},
  props: ["store"],
  emits: ["filter"],

  // eslint-disable-next-line
  setup({store, default_filter, default_time_mode}) {
    return persistent(store, {
      error: "",
      loading: false,
      filter: Object.assign({}, clean_filter),
    });
  },

  methods: {
    onFilter(clear) {
      if (clear)
        this.filter = Object.assign({}, clean_filter);

      this.$emit('filter', Object.assign({}, this.filter));
    },
  },

}
</script>
