<!--<template>-->
<!--  <div style="height: var(&#45;&#45;pg-header-height)">-->
<!--    <span class="lead fs-1">-->
<!--      <i :class="['bi', icon]"/><span v-if="back" >&nbsp;&nbsp;<icon-button icon="bi-arrow-left-circle fs-2" @click="typeof back==='boolean'?$router.go(-1):$router.push(back)"/></span>&nbsp;&nbsp;&nbsp;{{ title }}&nbsp;&nbsp;-->
<!--    </span>-->
<!--    <slot/>-->
<!--    <hr>-->
<!--  </div>-->
<!--</template>-->

<template>
  <div :class="sticky?'header':''" style="height: var(--pg-header-height)">
    <div class="lead fs-1 d-flex gap-3">
      <div>
        <i :class="['bi', icon]"/>
      </div>
      <div v-if="back">
        <icon-button icon="bi-arrow-left-circle fs-2" @click="typeof back==='boolean'?$router.go(-1):$router.push(back)"/>
      </div>
      <div style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis">
        {{ title }}
      </div>
      <div class="fs-2 d-flex gap-2 align-items-center" style="position: relative; top:0.12rem">
        <slot/>
      </div>
        <slot name="raw"/>
    </div>
    <hr>
  </div>
</template>

<script>
import IconButton from "./IconButton";

export default {
  components: {IconButton},
  props: ['title', 'icon', 'back', 'sticky'],

}
</script>

<style>
:root {
  --pg-header-height: calc(1.375rem + 1.5vw + 3rem + 1px) !important;
}

</style>

<style scoped>
.header {z-index:10000; position: -webkit-sticky;position: sticky;top:0;background-color: var(--bs-body-bg)}
.dark .header {z-index: 10000; position: -webkit-sticky;position: sticky;top:0;background-color: var(--bs-body-bg-alt)}

</style>