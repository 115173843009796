<template>
  <!--eslint-disable vue/no-mutating-props-->
  <fl-form>
    <fl-group name="Блок 'Переменные'">
      <fl-input v-model="data.name" label="Название блока"/>
      <fl-input type="textarea" v-model="data.comment" label="Комментарий"/>
    </fl-group>
    <fl-group name="Переменные">
      <fl-pairs
          :data="data.variables"
          :nameValidator="checkVariableName"
          @do-add="data.variables.push({name: 'var_10', value: '100'})"
      />
    </fl-group>
  </fl-form>

</template>

<script>
/* eslint-disable vue/no-mutating-props */
import FlForm from "./components/FlForm";
import FlGroup from "./components/FlGroup";
import FlInput from "./components/FlInput";
import FlPairs from "./components/FlPairs";

export default {
  components: {FlPairs, FlInput, FlGroup, FlForm},
  props: ["data"],

  methods:{
    checkVariableName(name){
      if (!name.match(/^[a-zA-Z]\w{2,}$/)) return 'Некорректное имя.';
      // if (name.match(/str_replace|arr_explode|array_explode|array_join|array_size|string_digits|string_len|string_set|string_get/)) return 'Название функции в имени.';
      // return null;
    },
  },
  computed: {
    variables() {
      return this.data.variables;
    }
  },
  watch: {
    variables: {
      handler() {
        this.data.settings.variables = {}
        for (const i in this.data.variables) {
          this.data.settings.variables[this.data.variables[i].name] = this.data.variables[i].value
        }

      },
      deep: true
    },
  }

}
</script>

