<template>
  <i
      :class="['bi ', icon, ((hover || active) && !disabled) && 'text-primary']"
      @mouseover="onMouseOver"
      @mouseleave="onMouseLeave"
      @click="tooltip.hide();"
      style="cursor: pointer"
      :title="title"
      ref="icon"
  />
</template>

<script>
import {Tooltip} from "bootstrap";

export default {
  props: ["icon", "title", "active", "disabled"],
  data() {
    return {
      hover: false
    }

  },
  mounted() {
    this.tooltip = new Tooltip(this.$refs.icon, {
      trigger: 'manual',
      title: this.title || '',
    });
    this.timer=null;
  },
  beforeUnmount() {
      this.hover=false;
      clearTimeout(this.timer);
      this.tooltip.hide();
  },
  methods: {
    onMouseOver(){
      this.hover=true;
      clearTimeout(this.timer);
      this.timer = setTimeout(()=>this.tooltip.show(),1000);
    },
    onMouseLeave(){
      this.hover=false;
      clearTimeout(this.timer);
      this.tooltip.hide();
    },
  }


}
</script>
