<template>
  <form class="m-2">
    <slot/>
    <button type="submit" style="display: none"/>
  </form>

</template>

<script>
export default {

}
</script>
