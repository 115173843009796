<template>
  <page-header title="Проекты" icon="bi-gear-fill">
    <icon-button icon="bi-plus-circle" @click="$router.push({name: 'code', params: {id: 'new'}})"/>
  </page-header>
  <codes-filter
      @filter="$refs.table.doFilter($event)"
      store="codesFilter"
  />
  <live-table
      url="/codes"
      @click-item="$router.push({name: 'code', params: {id: $event.item.id}})"
      ref="table"
      store="codes" :reload="true"
      :format="[
        ['id','ID', (i)=>i.id],
        ['project_id','Компания', (i)=>projects?.[i.project_id]?.name],
        ['name','Название', (i)=>i.name],
      ]"
  />

</template>

<script>
import LiveTable from "@/components/LiveTable";
import PageHeader from "@/components/PageHeader";
import IconButton from "@/components/IconButton";
import {http} from "@/misc/http";
import CodesFilter from "@/pages/codes/CodesFilter";

export default {
  components: {CodesFilter, IconButton, PageHeader, LiveTable},
  data() {
    return {
      projects: null,
      error: "",
    }
  },
  async created() {
    try {
      this.projects = await http.get('/shortcuts/projects');
    } catch (e) {
      this.error = e.message;
    }
  },
}
</script>

