<template>
  <!--eslint-disable vue/no-mutating-props-->
  <fl-form>
    <fl-group name="Блок 'Перенаправить'">
      <fl-input v-model="data.name" label="Название блока"/>
      <fl-input type="textarea" v-model="data.comment" label="Комментарий"/>
    </fl-group>
    <fl-group name="Параметры">
      <fl-input v-model="data.redirect_type" type="select" label="Перенаправить на">
        <option value="sip">SIP адрес</option>
        <option value="phone">Телефон</option>
      </fl-input>

      <fl-input v-model="data.destination" :label="data.redirect_type=='sip'?'sip:':'Телефон'"/>
      <div class="form-text mb-2">Пример: {{ data.redirect_type == 'sip' ? '123@domain.com' : '79261234567' }}</div>

      <fl-input v-model="data.settings.wait_audio" type="select" label="Озвучивание">
        <option value="silence">Тишина</option>
        <option value="beeps">Гудки</option>
      </fl-input>
      <fl-input type="textarea" v-model="data.settings.message" label="Сообщение оператору"/>
    </fl-group>

    <fl-group v-if="data.redirect_type=='sip'" name="SIP заголовки X-">
      <fl-pairs
          :data="data.sip_headers"
          @do-add="this.data.sip_headers.push({name: 'caller_id', value: '0000'})"
      />
    </fl-group>
  </fl-form>

</template>

<script>
/* eslint-disable vue/no-mutating-props */
import FlForm from "./components/FlForm";
import FlGroup from "./components/FlGroup";
import FlInput from "./components/FlInput";
import FlPairs from "./components/FlPairs";

export default {
  components: {FlPairs, FlInput, FlGroup, FlForm},
  props: ["data"],

  computed: {
    sip_headers() {
      return this.data.sip_headers;
    },
    destination_and_redirect_type() {
      return {
        redirect_type: this.data.redirect_type,
        destination: this.data.destination,
      }
    },
  },

  watch: {
    sip_headers: {
      deep: true,
      handler() {
        this.data.settings.sip_headers = {}
        for (const i in this.data.sip_headers) {
          this.data.settings.sip_headers[this.data.sip_headers[i].name] = this.data.sip_headers[i].value
        }
      }
    },
    destination_and_redirect_type() {
      this.data.settings.destination = (this.data.redirect_type == 'sip' ? 'sip:' : '') + this.data.destination;
    }
  }

}
</script>

