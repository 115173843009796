<template>

  <div class="d-inline-flex pad ">
    <div @click="$emit('zoom', -1)" class="zbutton">-</div>
    <div @click="$emit('zoom', null)" class="zbutton" style="width: 50px">{{ Math.floor(zoom*100)}}%</div>
    <div @click="$emit('zoom', 1)" class="zbutton">+</div>
  </div>
</template>

<script>
export default {
  props: ["zoom"],
  emits: ["zoom"],
}
</script>

<style scoped>
.pad {
  border: 1px solid lightgray
}
html.dark .pad {
  border: 1px solid #303030
}

.zbutton {
  width: 24px;
  height: 24px;
  background: white;
  text-align: center;
  line-height: 24px;
  font-family: var(--bs-font-sans-serif);
  font-size: 14px;
  color: gray;
}

html.dark .zbutton {
  width: 24px;
  height: 24px;
  background: #222222;
  text-align: center;
  line-height: 24px;
  font-family: var(--bs-font-sans-serif);
  font-size: 14px;
  color: gray;
}

</style>