<template>
  <div class="w-75 mb-3" :class="[t.side=='user'&&'ms-auto text-end']">
    <div
        @mouseover="onMouseOver"
        @mouseleave="onMouseLeave"
        style="cursor: pointer"

        class="card p-2 chat"
        :class="{'highlight': hover}"
    >
      <div class="row">
        <div class="col-2 text-center fs-3">
          <i class="bi" :class="t.side=='user'?'bi-person-video3':'bi-robot'"/>
        </div>
        <div class="col-10">
          {{ t.text }}
        </div>
      </div>
    </div>
    <small>{{ format_datetime(t.started_at) }}</small>
  </div>
</template>

<script>
import {format_datetime} from "@/misc/util";

export default {
  props: ['t'], // transcript
  data() {
    return {
      hover: false
    }
  },
  methods: {
    onMouseOver() {
      this.hover = true
    },
    onMouseLeave() {
      this.hover = false
    },
    format_datetime
  }

}
</script>

<style scoped>
.chat {
  background: #FAFAFAFA;
}

.highlight {
  background: rgba(235, 243, 255, 0.98);

}

</style>