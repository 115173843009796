<template>
  <router-link :to="{name: to}" custom v-slot="{ navigate, isActive }">
    <li :class="isActive && 'nav-item'">
      <a :class="isActive?'nav-link active':'nav-link text-white'" href="#" @click="navigate">
        <span class="text-nowrap"><i :class="'bi '+ icon"/> {{ name }}</span>
      </a>
    </li>
  </router-link>
</template>

<script>
export default {
  name: "NavLink",
  props: ['to', 'name', 'icon']
}
</script>

