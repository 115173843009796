<!--eslint-disable vue/no-mutating-props-->
<template>
  <fl-form>
    <fl-group name="Блок 'Запрос'">
      <fl-input v-model="data.name" label="Название блока"/>
      <fl-input type="textarea" v-model="data.comment" label="Комментарий"/>
    </fl-group>
    <fl-group name="Параметры">
      <fl-input v-model="data.settings.url" label="Url"/>
      <fl-input v-model.number="data.settings.timeout" label="Таймаут"/>
      <fl-input v-model="data.settings.request_type" type="select" label="Метод">
        <option value="get">GET</option>
        <option value="post">POST</option>
        <option value="put">PUT</option>
        <option value="delete">DELETE</option>
      </fl-input>
      <fl-input v-if="data.settings.request_type != 'get'" v-model="data.settings.body" type="textarea" label="JSON"/>
      <fl-input v-model="data.settings.parse_json_response" type="checkbox" label="Парсить ответ"/>
    </fl-group>
  </fl-form>

</template>

<script>
import FlForm from "./components/FlForm";
import FlGroup from "./components/FlGroup";
import FlInput from "./components/FlInput";

export default {
  components: {FlInput, FlGroup, FlForm},
  props: ["data"],
}
</script>

