<template>
  <page-header :title="title" icon="bi-journal-text" :back="true"/>
  <!--    {{ item }}-->
  <!--  {{ reports }}-->

  <f-form ref="form" :error="error" :new-mode="id=='new'" @send="onSend" @delete="onDelete">
    <template v-slot:default="form">
      <f-input v-model="item.name" label="Название" w="6" :disabled="!form.edit"/>
      <f-input v-model="item.mode" type="select" label="Тип" :w="item.mode=='dashboard'?3:6" :disabled="!form.edit">
        <option value="report">Отчет</option>
        <option value="dashboard">Дашборд</option>
      </f-input>


      <f-input v-if="item.mode=='report'" v-model="item.raw_sql" type="textarea" label="Запрос" :disabled="!form.edit"/>
      <f-input v-if="item.mode=='report'" v-model="item.count_sql" type="textarea" label="Подсчет (необязательно)" :disabled="!form.edit"/>
      <f-input v-if="item.mode=='report'" v-model="item.chart" type="select" label="График" w="6" :disabled="!form.edit">
        <option :value="null">Нет</option>
        <option value="line">Линии</option>
        <option value="bar">Столбики</option>
      </f-input>
      <f-input v-if="item.mode=='report'" v-model="item.single_mode" type="select" label="Показывать" w="6" :disabled="!form.edit || !item?.chart">
        <option :value="false">Несколько графиков</option>
        <option :value="true">Один график с подсказкой</option>
      </f-input>

      <f-input v-if="item.mode=='dashboard'" v-model="item.columns" type="select" label="Колонок" w="3" :disabled="!form.edit">
        <option :value="1">1</option>
        <option :value="2">2</option>
        <option :value="3">3</option>
        <option :value="4">4</option>
        <option :value="6">6</option>
      </f-input>

      <template v-if="item.mode=='dashboard'">
        <template v-for="(r,i) in item.reports" :key="i">
          <f-input v-model="item.reports[i]" type="select" label="Отчет" :disabled="!form.edit" :w="9">
            <option v-for="rs in reports" :key="rs" :value="rs.id">{{ rs.id }}-{{ rs.name }}</option>
          </f-input>
          <f-input v-model="item.reports_params[i].mode" type="select" label="Отображать" :disabled="!form.edit" :w="3">
            <option value="chart">График</option>
            <option value="table">Таблицу</option>
            <option value="both">График+таблица</option>
          </f-input>
        </template>
        <div v-if="form.edit" class="d-flex gap-2">
          <button @click.prevent="item.reports.push(null);item.reports_params.push({mode: 'chart'})" type="button" class="btn btn-primary">Добавить отчет</button>
          <button v-if="item.reports.length" @click.prevent="item.reports.pop();item.reports_params.pop()" type="button" class="btn btn-danger">Удалить отчет</button>
        </div>
      </template>


      <f-input v-model="item.comment" type="textarea" label="Комментарий" :disabled="!form.edit"/>
      <f-input v-model="item.help" type="textarea" label="Подсказка" :disabled="!form.edit"/>
      <f-input v-model="item.report_params" type="textarea" label="Дополнительные параметры (JSON)" :disabled="!form.edit"/>
      <f-input v-model="item.default_range" type="select" label="Период по умолчанию" w="6" :disabled="!form.edit">
        <option :value="null">Нет</option>
        <option value="hour">Текущий час</option>
        <option value="day">Сегодня</option>
        <option value="month">Текущий месяц</option>
      </f-input>
      <f-input v-model="item.default_interval" type="select" label="Интервал по умолчанию" w="6" :disabled="!form.edit">
        <option value="year">Год</option>
        <option value="month">Месяц</option>
        <option value="week">Неделя</option>
        <option value="day">День</option>
        <option value="hour">Час</option>
      </f-input>
      <div class="col">
        <div class="form-check form-switch">
          <input class="form-check-input" type="checkbox" v-model="item.show_filter" :disabled="!form.edit">
          <label class="form-check-label">Показывать фильтр</label>
        </div>
      </div>
      <div class="col">
        <div class="form-check form-switch">
          <input class="form-check-input" type="checkbox" v-model="item.sortable" :disabled="!form.edit || item.transpose">
          <label class="form-check-label">Поддерживает сортировку</label>
        </div>
      </div>
      <div class="col">
        <div class="form-check form-switch">
          <input class="form-check-input" type="checkbox" v-model="item.clickable" :disabled="!form.edit || item.transpose">
          <label class="form-check-label">Кликабельно</label>
        </div>
      </div>
      <div class="col">
        <div class="form-check form-switch">
          <input class="form-check-input" type="checkbox" v-model="item.transpose" :disabled="!form.edit">
          <label class="form-check-label">Транспонировать</label>
        </div>
      </div>

      <div class="col">
        <div class="form-check form-switch">
          <input class="form-check-input" type="checkbox" v-model="item.privileged" :disabled="!form.edit">
          <label class="form-check-label">Доступен только суперадминам</label>
        </div>
      </div>
      <div class="col">
        <div class="form-check form-switch">
          <input class="form-check-input" type="checkbox" v-model="item.limited" :disabled="!form.edit || item.privileged">
          <label class="form-check-label">Ограничено компаниями</label>
        </div>
      </div>
      <div class="col">
        <div class="form-check form-switch">
          <input class="form-check-input" type="checkbox" v-model="item.special" :disabled="!form.edit">
          <label class="form-check-label">Специальный</label>
        </div>
      </div>

      <div v-if="item.show_filter & form.edit" class="d-flex flex-wrap">
        <div class="form-text align-self-center">Показывать:</div>
        <fl-input v-model="item.show_what.domain_name" type="checkbox" label="Домен" class="mx-3"/>
        <fl-input v-model="item.show_what.dl_project_id" type="checkbox" label="Код проекта" class="mx-3"/>
        <fl-input v-model="item.show_what.destination_number" type="checkbox" label="Номер" class="mx-3"/>
        <fl-input v-model="item.show_what.call_status_name" type="checkbox" label="Статус" class="mx-3"/>
        <fl-input v-model="item.show_what.conversation_release_reason_name" type="checkbox" label="Причина" class="mx-3"/>
        <fl-input v-model="item.show_what.dl_result" type="checkbox" label="Результат" class="mx-3"/>
        <fl-input v-model="item.show_what.dl_topic" type="checkbox" label="Тема" class="mx-3"/>
        <fl-input v-model="item.show_what.t_from" type="checkbox" label="От" class="mx-3"/>
        <fl-input v-model="item.show_what.t_to" type="checkbox" label="До" class="mx-3"/>
        <fl-input v-model="item.show_what.contains" type="checkbox" label="Искать" class="mx-3"/>
        <fl-input v-model="item.show_what.interval" type="checkbox" label="Интервал" class="mx-3"/>
        <fl-input v-model="item.show_what.variables" type="checkbox" label="Переменные" class="mx-3"/>
      </div>

      <div v-if="item.limited" class="d-flex gap-4">
        <div class="form-text align-self-center">Компании:</div>
        <fl-input v-for="p in projects" :key="p.id" v-model="item.projects[p.id]" type="checkbox" :label="p.name" :disabled="!form.edit"/>
      </div>
      <f-input v-if="item.limited" v-model="item.dl_project_id" label="Проект (если доступен внешним пользователям)" :disabled="!form.edit"/>

      <div v-if="form.edit">
        <template-attention/>
      </div>
    </template>
    <template v-slot:actions="form">
      <button v-if="!form.edit" @click.prevent="onExecute" class="btn btn-outline-primary px-2 m-1">Запустить</button>
    </template>

  </f-form>
  <!--  <pre>{{ JSON.stringify(item, null, 4) }}</pre>-->
</template>

<script>
import {http} from "@/misc/http";
import FInput from "@/components/FInput";
import {format_datetime} from "@/misc/util";
import PageHeader from "@/components/PageHeader";
import FForm from "@/components/FForm";
import TemplateAttention from "./TemplateAttention";
import FlInput from "../flow/forms/components/FlInput";
import {updateItem} from "../../misc/util";
import {tstore} from "../../tstore";
import IconButton from "@/components/IconButton";

export default {
  components: {IconButton, FlInput, TemplateAttention, FForm, PageHeader, FInput},
  props: ["id"],
  data() {
    return {
      item: {
        name: "Новый",
        mode: "report",
        raw_sql: "",
        count_sql: "",
        chart: null,
        reports: [],
        reports_params: [],
        columns: 1,
        show_filter: false,
        show_what: {},
        privileged: true,
        limited: false,
        projects: {},
        dl_project_id: "",
        special: false,
        report_params: "",
        default_range: null,
        default_interval: "day",
        single_mode: false

      },
      error: "",
      projects: [],
      reports: [],
      options: [],

    }
  },

  created() {
    this.updateItem((async () => {
      this.projects = await http.get('/shortcuts/projects');
      this.reports = await http.get('/shortcuts/reports');
      return !this.isNew ? await http.get('/templates/' + this.id) : null;
    })());
  },

  methods: {
    onSend() {
      this.updateItem(
          http.post("/templates" + (this.id == "new" ? '' : '/' + this.id), this.item),
          "template", true
      );
    },

    onDelete() {
      this.updateItem(http.delete("/templates/" + this.id), "templates");
    },

    onExecute() {
      delete tstore.report; // очищаем стор так как мог поменяться формат выдачи
      this.$router.push({name: 'report', params: {id: this.id}});
    },

    format_datetime,
    updateItem
  },

  computed: {
    title() {
      return "Шаблон \"" + this.item.name + "\"";
    },
    isNew() {
      return this.id == "new";
    },
  },


}
</script>

