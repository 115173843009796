<template>
  <fl-input v-model="inputVal" type="select" :label="label" :help="help" :help-url="helpUrl">
    <option v-for="(v,k) in options" :key="k" :value="options.length===undefined?k:v">{{ format ? format(v) : v }}</option>
  </fl-input>
</template>

<script>
import FlInput from "./FlInput";

export default {
  components: {FlInput},
  props: [
    "label",
    "modelValue",
    "options", // object: { value: name || item_to_format, ...}, array: [name_value, ...]
    "format",
    "help",
    "helpUrl"
  ],
  computed: {
    inputVal: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit('update:modelValue', val);
      }
    }
  }
}
</script>

