<template>
  <page-header title="Запрещенные номера" icon="bi-telephone-x"/>
  <div class="form-text text-danger">{{ error }}</div>
  <div class="d-flex gap-2 mb-3">

    <select @change="$refs.table.doReload()" v-model="blacklist_id" class="form-select">
      <option v-for="i in blacklists" :key="i.id" :value="i.id">{{ i.name }}</option>
    </select>
    <input @keydown.enter="numberValid?addNumber(number, false, true):()=>{}" v-if="blacklist_id" v-model="number" class="form-control" type="text" placeholder="+79261234567">
    <button v-if="blacklist_id" @click="addNumber(number, false, true)" :disabled="!numberValid" class="btn btn-primary">Добавить</button>
    <button v-if="blacklist_id" @click="addNumber(number, true, true)" :disabled="!numberValid" class="btn btn-danger">Удалить</button>
    <button v-if="blacklist_id && !filter" @click="doFilter" :disabled="!numberValid" class="btn btn-outline-primary">Найти</button>
    <button v-if="blacklist_id && filter" @click="doFilter" class="btn btn-outline-primary">Сбросить</button>
  </div>
  <live-table
      ref="table"
      v-if="blacklist_id"
      :url="'/blacknumbers/'+blacklist_id"
      :format="[
        ['number','Номер', i=>i.number],
        ['modified_by', 'Пользователь', i=>i.modified_by_user?i.modified_by_user.fname + ' '+i.modified_by_user.lname : ''],
        ['modified_at', 'Изменено', i=>format_datetime(i.modified_at)],
      ]"
      :nonsortable="true"
      :menu="[
          {name: 'delete', icon:'bi-x-circle', title:'Удалить'},
          ]"
      @click-menu="addNumber($event.item.number, true)"
  />
</template>

<script>
import {http} from "@/misc/http"
import PageHeader from "@/components/PageHeader";
import LiveTable from "@/components/LiveTable";
import {format_datetime} from "@/misc/util";

export default {
  components: {LiveTable, PageHeader},
  data() {
    return {
      error: "",
      number: "",
      blacklists: null,
      blacklist_id: null,
      filter: null,
    }
  },

  async created() {
    try {
      this.blacklists = await http.get('/blacknumbers');
      if (this.blacklists.length) this.blacklist_id = this.blacklists[0].id;
    } catch (e) {
      this.error = e.message;
    }
  },

  methods: {
    async addNumber(number, deleted, clear) {
      this.error = "";
      try {
        await http.put('/blacknumbers', {
          numbers: number.split(' '),
          blacklist_id: this.blacklist_id,
          deleted: deleted,
        });
        this.$refs.table.doReload();
        if (clear) this.number = "";
      } catch (e) {
        this.error = e.message;
      }
    },
    doFilter() {
      if (this.filter) {
        this.filter = null;
      } else {
        this.filter = {
          number: this.number,
        };
      }
      this.$refs.table.doFilter(this.filter);
    },
    format_datetime,
  },

  computed: {
    numberValid() {
      if (!this.number) return false;
      const numbers = this.number.split(' ');
      return numbers.every(n => n.match(/^\+\d\d\d\d\d\d\d\d\d\d\d\d?\d?\d?\d?\d?$/));
    },
  },

  // watch: {
  //   blacklist_id() {
  //     this.$nextTick(() => {
  //       this.$refs.table?.doReload();
  //     });
  //
  //   }
  // }
  //

}
</script>

