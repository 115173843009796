<!--eslint-disable vue/no-mutating-props-->
<template>
  <fl-form>
    <fl-group name="Блок 'Ожидание ввода'">
      <fl-input v-model="data.name" label="Название блока"/>
      <fl-input type="textarea" v-model="data.comment" label="Комментарий"/>
    </fl-group>
    <fl-group name="Вопрос">

      <fl-input v-model="data.settings.say_type" type="select" label="Режим">
        <option value="no_question">Без вопроса</option>
        <option value="tts">TTS</option>
        <option value="audio">Аудиофайл</option>
      </fl-input>

      <say-subform
          :script="script"
          :type="data.settings.say_type"
          :message_params="data.settings.message_params"
          :audio_settings="data.settings"
      />

      <fl-input v-if="data.settings.say_type!='no_question'" v-model="data.settings.allow_interrupt" type="checkbox" label="Разрешать перебивать"/>
    </fl-group>

    <fl-group name="Распознавание">
      <fl-input v-model="data.settings.input_type" type="select" label="Режим">
        <option value="voice">Речь</option>
        <option value="dtmf">Цифры</option>
      </fl-input>
      <fl-input v-model="data.settings.variable" label="Переменная"/>
      <fl-input v-model.number="data.settings.recognition_failure_repeat_count" label="Повторов распознававния"/>
      <fl-input
          v-if="(script.flow.common.asr?script.flow.common:data).settings.asr_params.provider=='tinkoff'"
          v-model="data.settings.asr_params.asr_denorm"
          type="checkbox" label="Денормализация"
      />
    </fl-group>
    <fl-group name="Время ожидания">
      <fl-input v-model.number="data.settings.timeout_start" label="Начала ввода"/>
      <fl-input v-model.number="data.settings.timeout_end" label="Окончания ввода"/>
    </fl-group>
    <input-error
        name="Ошибка"
        :data="data.settings.recognition_failure_phrase"
        :script="script"
    />
    <input-error
        v-if="data.settings.recognition_failure_phrase.type!='default'"
        name="Повторная ошибка"
        :data="data.settings.recognition_failure_repeat_phrase"
        :script="script"
    />

    <tts v-if="!script.flow.common.tts && ((
    data.settings.recognition_failure_phrase.type!='default' &&
    (data.settings.recognition_failure_phrase.type == 'tts' ||
    data.settings.recognition_failure_repeat_phrase.type == 'tts')) ||
    data.settings.say_type == 'tts')" :data="data"/>

    <asr v-if="!script.flow.common.asr" :data="data"/>

  </fl-form>

</template>

<script>
import FlForm from "./components/FlForm";
import FlGroup from "./components/FlGroup";
import FlInput from "./components/FlInput";
import InputError from "./subforms/InputError";
import Tts from "./subforms/Tts";
import Asr from "@/pages/flow/forms/subforms/Asr";
import SaySubform from "@/pages/flow/forms/subforms/SaySubform";

export default {
  components: {SaySubform, Asr, Tts, InputError, FlInput, FlGroup, FlForm},
  props: ["data", "script"],
  created() {
    // eslint-disable-next-line vue/no-mutating-props
    if (this.data.settings.asr_params.asr_denorm === undefined) this.data.settings.asr_params.asr_denorm = true;
  }
}
</script>

