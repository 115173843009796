<template>
  <div v-if="coords">
    <svg style="overflow: visible!important;position: absolute;">
      <defs>
        <marker :id="id" markerWidth="12" markerHeight="6" refX="12" refY="3" orient="auto" markerUnits="userSpaceOnUse">
          <polygon
              :class="conClass"
              fill="none"
              points="0 6, 12 3, 0 0"/>
        </marker>
      </defs>
      <path
          @click="onClick"
          @mouseenter="$emit('hover-con', {'id': id, hover: true})"
          @mouseleave="$emit('hover-con', {'id': id, hover: false})"
          :id="id"
          :d="path"
          :class="conClass"
          fill="none"
          :marker-end="'url(#'+id+')'"
      />
    </svg>
    <div
        v-if="mode"
        @mousedown="$event.stopPropagation()"
        @click="onClick"
        ref="text"
        class="bg-body"
        style="z-index:1000; position: absolute;white-space: nowrap;transform: translate(-50%, -50%)"
        :style="textPosition"

    >{{ data.name }}
    </div>
  </div>
</template>

<script>


export default {
  props: ['id', 'data', 'mode', 'coords', 'hovered'],
  emits: ['select-con', "hover-con"],
  // data() {
  //   return {
  //     hovered: false
  //   }
  // },

  methods: {
    onClick() {
      this.$emit('select-con', {id: this.id});
    },
    p(factor = 5) {
      const p = {
        x0: this.coords.x0,
        y0: this.coords.y0,
        x1: this.coords.x1,
        y1: this.coords.y1,
        w: this.coords.x1 - this.coords.x0,
        h: this.coords.y1 - this.coords.y0,
      }

      if (!(p.x1 < p.x0) ^ !(p.y1 < p.y0)) {
        p.x = (p.x0 + p.x1) / 2 - (p.y1 - p.y0) / factor;
        p.y = (p.y0 + p.y1) / 2 + (p.x1 - p.x0) / factor;
      } else {
        p.x = (p.x0 + p.x1) / 2 + (p.y1 - p.y0) / factor;
        p.y = (p.y0 + p.y1) / 2 - (p.x1 - p.x0) / factor;
      }

      return p;
    },

  },

  computed: {

    conClass() {
      return this.mode || (this.hovered ? 'hovered' : 'unselected');
    },

    textPosition() {
      const p = this.p(15);
      return {
        left: p.x + 'px',
        top: p.y + 'px',
      }
    },

    path() {
      const p = this.p();
      return `M ${p.x0} ${p.y0} Q ${p.x} ${p.y} ${p.x1} ${p.y1}`;
    }
  }

}
</script>
<style scoped>

path, polyline {
  stroke-width: 1;
}


.unselected {
  stroke: lightgray;
}

.default {
  stroke: #4ea9ff;
  stroke-width: 2;
}

.error {
  stroke: red;
  stroke-width: 2;
}

.logic {
  stroke: limegreen;
  stroke-width: 2;
}

.in {
  stroke: black;
}

.hovered {
  stroke: gold;
  stroke-width: 2;
}

</style>