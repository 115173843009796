<template>
  <!--  <icon-button :icon="expanded?'bi-chevron-compact-right':'bi-chevron-compact-left'" @click="onClick" :title="expanded?'Свернуть':'Развернуть'"/>-->
  <icon-button v-if="level<levels-1" icon="bi-chevron-compact-left" @click="onClick(level+1)" title="Развернуть"/>
  <icon-button v-if="level>0" icon="bi-chevron-compact-right" @click="onClick(0)" title="Свернуть"/>
</template>

<script>
import IconButton from "../../components/IconButton";

export default {
  components: {IconButton},
  props: {
    levels: {
      type: Number,
      default: 3
    }
  },
  emits: ["expand"],
  data() {
    return {
      level: 0,
    }
  },
  methods: {
    onClick(n) {
      this.level = n;
      this.$emit('expand', this.level);
    }
  },

  // methods: {
  //   onClick() {
  //     this.level += 1;
  //     if (this.level >= (this.levels || 3)) this.level = 0;
  //     this.$emit('expand', this.level)
  //   }
  // },
  // computed: {
  //   expanded() {
  //     return this.level >= ((this.levels || 3) - 1)
  //   }
  // }
}
</script>
