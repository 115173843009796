<template>
  <page-header :title="title" icon="bi-diagram-3-fill" :back="{name:'scripts'}"/>

  <f-form ref="form" :error="error" :new-mode="id=='new'" @send="onSend" @delete="onDelete">
    <template v-slot:default="form">

      <f-input v-model="item.name" label="Название сценария" :disabled="!form.edit"/>
      <f-input v-model="item.comment" type="textarea" label="Комментарий" :disabled="!form.edit"/>

      <div v-if="!isNew">
        <label v-if="item && item.files && item.files.length" class="form-label">Файлы</label>
        <chips
            @clicked="deleteFile($event.item)"
            :data="sortedFiles"
            :disabled="!form.edit"
        />
      </div>
    </template>

    <template v-slot:actions="form">
      <button v-if="!isNew" @click.prevent="onUpload" class="btn btn-outline-primary px-2 m-1">Загрузить аудио</button>
      <button v-if="!isNew && !form.edit" @click.prevent="$router.push({name:'flow', params:{id:id}})" class="btn btn-outline-primary px-2 m-1">Конструктор</button>


      <!--      <button v-if="!isNew && !form.edit" @click.prevent="onSave" class="btn btn-outline-primary px-2 m-1">Сохранить</button>-->
      <!--      <button v-if="!isNew && !form.edit" @click.prevent="onExport(true)" class="btn btn-outline-primary px-2 m-1">Экспорт</button>-->
      <!--      <button v-if="!isNew && !form.edit" @click.prevent="onExport(false)" class="btn btn-outline-primary px-2 m-1">Экспорт без аудио</button>-->
      <!--      <button v-if="!isNew && !form.edit" @click.prevent="onStoreAudio" class="btn btn-outline-primary px-2 m-1">Сохранить аудио</button>-->

<!--      <d-button-->
<!--          v-if="!isNew && !form.edit"-->
<!--          @click="$event.name == 'all'?onExport(true): $event.name=='script_only'?onExport(false):$event.name=='audio_only'?onStoreAudio():onSave()"-->
<!--          class="m-1"-->
<!--          :items="[-->
<!--              {name: 'all', title: 'Экспорт'},-->
<!--              {name: 'script_only', title: 'Без аудио'},-->
<!--              {name: 'audio_only', title: 'Только аудио'},-->
<!--              {name: 'store', title: 'Сохранить'},-->
<!--              ]"-->
<!--      />-->

      <d-button
          v-if="!isNew && !form.edit"
          @click="$event.name=='store'?onSave():onExport2($event)"
          class="px-2 m-1"
          :items="[
              {name: 'all', title: 'Экспорт'},
              {name: 'script_only', title: 'Без аудио'},
              {name: 'audio_only', title: 'Только аудио'},
              {name: 'store', title: 'Сохранить'},
              ]"
      />

      <button v-if="!isNew && form.edit" @click.prevent="onLoad" class="btn btn-outline-primary px-2 m-1">Восстановить</button>
      <!--      <button v-if="!isNew && form.edit" @click.prevent="onImport" class="btn btn-outline-primary px-2 m-1">Импортировать</button>-->
    </template>
  </f-form>

  <!--  <pre>{{ JSON.stringify(item, null, 4) }}</pre>-->
  <!--  {{ item }}-->
</template>

<script>
import {http} from "@/misc/http";
import FInput from "@/components/FInput";
import {format_datetime, updateItem, clickDownload} from "@/misc/util";
import PageHeader from "@/components/PageHeader";
import FForm from "@/components/FForm";
import Chips from "../../components/Chips";
import {clickDownloadJson, readTextFile, selectFile} from "@/misc/util";
import {mapGetters} from "vuex";
import DButton from "@/components/DButton";

export default {
  components: {DButton, Chips, FForm, PageHeader, FInput},
  props: ["id"],
  data() {
    return {
      item: {
        id: null,
        name: "Новый",
        flow: {},
        comment: ""
      },
      error: "",
    }
  },

  timer: null,

  created() {
    if (!this.isNew) this.updateItem(http.get('/scripts/' + this.id));
  },

  methods: {
    onSend() {
      this.updateItem(
          http.post("/scripts" + (this.isNew ? '' : '/' + this.id), this.item),
          "script", true
      )
    },

    onDelete() {
      this.updateItem(
          http.delete("/scripts/" + this.id),
          "scripts"
      );
    },

    onUpload() {
      selectFile(file => {
        this.updateItem(
            http.post_form('/scripts/' + this.id + '/upload', {'file': file})
        )
      });
    },

    async onExport(withAudio) {
      try {
        const [blob, fn] = await http.getBlob('/scripts/' + this.id + '/export?with_audio=' + withAudio);
        clickDownload(blob, fn);
      } catch (e) {
        this.error = e.message;
      }
    },

    async onStoreAudio() {
      try {
        const [blob, fn] = await http.getBlob('/scripts/' + this.id + '/audio');
        clickDownload(blob, fn);
      } catch (e) {
        this.error = e.message;
      }
    },

    onSave() {
      clickDownloadJson(this.item.flow, this.item.name.replace('.', '_'));
    },

    onLoad() {
      selectFile(file => {
        readTextFile(file, async content => {
          try {
            const flow = JSON.parse(content);
            if (!flow.meta || flow.meta.type != "scenario" || flow.meta.version != "1.0.0")
              throw Error("Неподдерживаемый формат файла.");
            this.item.flow = flow;
            this.item.name = flow.meta.name;
            alert("Восстановлено.");
          } catch (e) {
            alert("Ошибка: " + e.message);
          }
        })
      });
    },

    onImport() {
      selectFile(file => {
        readTextFile(file, async content => {
          try {
            const infobot = JSON.parse(content);
            const resp = await http.post('/scripts/import', infobot);
            this.item.flow = resp.flow;
            this.item.name = resp.flow.meta.name;
            alert(resp.warnings || "Импортировано.");
          } catch (e) {
            alert("Ошибка: " + e.message);
          }
        })
      });
    },

    async onExport2(e) {
      try {
        this.error = '';
        await http.post('/scripts/' + this.id + '/export?mode=' + e.name);
        alert("Экспорт начат, проверьте раздел Файлы.");
      } catch (e) {
        this.error = e.message;
      }
    },

    deleteFile(filename) {
      this.updateItem(
          http.delete('/scripts/' + this.id + '/file', {'file': filename})
      )
    },

    format_datetime,
    updateItem,
    ...mapGetters("auth", ["user"]),

  },

  computed: {
    title() {
      return "Сценарий \"" + this.item.name + "\"";
    },
    isNew() {
      return this.id == "new";
    },

    sortedFiles() {
      if (!this.item?.files) return null;
      return Object.keys(this.item.files).sort();
    },

  },


}
</script>

