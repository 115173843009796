<template>
  <page-header title="Пользователи" icon="bi-person-lines-fill">
    <icon-button icon="bi-plus-circle" @click="$router.push({name: 'user', params: {id: 'new'}})"/>
  </page-header>

  <div v-if="error" class="form-text text-danger">{{ error }}</div>

  <users-filter
      @filter="$refs.table.doFilter($event)"
      store="usersFilter"
  />

  <live-table
      url="/users"
      @click-item="$router.push({name: 'user', params: {id: $event.item.id}})"
      ref="table"
      store="users" :reload="true"
      :format="[
        ['id','ID', (i)=>i.id],
        ['login','Логин', (i)=>i.login],
        ['project_id','Компания', (i)=>projects?.[i.project_id]?.name],
        ['role','Роль', (i)=>i.role],
        ['dl_project_id','Проект', (i)=>i.dl_project_id],
        ['fname','Имя', (i)=>i.fname],
        ['lname','Фамилия', (i)=>i.lname],
        ['enabled','Активный', (i)=>i.enabled?'Да':'Нет'],
        ['automated','Сервис', (i)=>i.automated?'Да':'Нет'],
      ]"/>

</template>

<script>
import LiveTable from "@/components/LiveTable";
import PageHeader from "@/components/PageHeader";
import IconButton from "@/components/IconButton";
import {format_datetime} from "@/misc/util";
import {http} from "@/misc/http";
import UsersFilter from "@/pages/users/UsersFilter";

export default {
  components: {UsersFilter, IconButton, PageHeader, LiveTable},
  data() {
    return {
      // projects: {1:{id:1, name: 'qq'}},
      projects: null,
      error: "",
    }
  },
  async created() {
    try {
      this.projects = await http.get('/shortcuts/projects');
    } catch (e) {
      this.error = e.message;
    }
  },
  methods: {
    format_datetime,
  }
}
</script>

