<template>
  <!--eslint-disable vue/no-mutating-props-->
  <table>
    <tr v-if="data.length">
      <td class="form-text" style="width: 50%">Имя</td>
      <td class="form-text" style="width: 50%">Значение</td>
    </tr>
    <tr
        v-for="(v, i) in data"
        :key="i"
        @do-delete="data.splice(i, 1)"
    >
      <td style="word-break: break-word;"><fl-input v-model="data[i].name" :error="nameValidator?nameValidator(data[i].name):''"/></td>
      <td style="vertical-align: top"><fl-input v-model="data[i].value"/></td>
<!--      <td><input v-model="data[i].name" type="text" class="form-control form-control-sm" ></td>-->
<!--      <td><input v-model="data[i].value" type="text" class="form-control form-control-sm"></td>-->
      <td style="vertical-align: top">
        <icon-button @click="data.splice(i, 1)" icon="bi-trash" title="Удалить"/>
      </td>
    </tr>
  </table>
  <icon-button @click="$emit('do-add')" icon="bi-plus-circle" title="Добавить"/>

</template>

<script>
import IconButton from "../../../../components/IconButton";
import FlInput from "@/pages/flow/forms/components/FlInput";

export default {
  components: {FlInput, IconButton},
  props: ["data", "nameValidator"],
  emits:["do-add"]
}
</script>

