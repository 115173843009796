import {createApp} from 'vue'
import App from './App.vue'
import 'bootstrap'
import "bootstrap-icons/font/bootstrap-icons.css"
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-dark-5/dist/css/bootstrap-nightshade.min.css'

import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);


import store from "@/store"
import router from "@/router"


const app = createApp(App);
app.use(store);
app.use(router);

app.mount('#app');

