<template>
  <page-header title="Сценарии" icon="bi-diagram-3-fill">
    <icon-button icon="bi-plus-circle" @click="$router.push({name: 'script', params: {id: 'new'}})"/>
  </page-header>
  <scripts-filter
      @filter="$refs.table.doFilter($event)"
      store="scriptsFilter"
  />
  <live-table
      url="/scripts"
      @click-item="$router.push({name: 'script', params: {id: $event.item.id}})"
      ref="table"
      store="scripts" :reload="true"
      :format="[
        ['id','ID', (i)=>i.id],
        ['name','Название', (i)=>i.name],
        ['owner','Владелец', (i)=>i.owner?i.owner.lname + ' ' + i.owner.fname:''],
      ]"
  />

</template>

<script>
import LiveTable from "@/components/LiveTable";
import PageHeader from "@/components/PageHeader";
import {format_datetime} from '@/misc/util';
import IconButton from "@/components/IconButton";
import ScriptsFilter from "@/pages/scripts/ScriptsFilter";

export default {
  components: {ScriptsFilter, IconButton, PageHeader, LiveTable},
  methods: {
    format_datetime
  }
}
</script>

