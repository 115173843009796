<template>
  <!--eslint-disable vue/no-mutating-props-->
  <fl-form>
    <fl-group name="Блок 'Функции'">
      <fl-input v-model="data.name" label="Название блока"/>
      <fl-input type="textarea" v-model="data.comment" label="Комментарий"/>
    </fl-group>
    <fl-group name="Функции">
      <small v-if="error" class="text-danger">{{ error }}</small>

      <div v-for="(f,i) in data.functions" :key="i">
        <div class="d-flex gap-2">
          <fl-select
              class="flex-grow-1"
              v-model="f.name"
              :options="[
                '~str_replace',
                '~arr_explode',
                '~array_explode',
                '~array_join',
                '~string_digits',
                '~string_len',
                '~array_set',
                '~array_get',
                '~array_size',
            ]"
              label="Функция"
              :help-url="'http://10.20.10.18/books/digital-link/page/spisok-funktsiy-dd#bkmrk-'+f.name"
          />
          <icon-button @click="data.functions.splice(i, 1)" icon="bi-trash" title="Удалить" style="position: relative;top:28px"/>
        </div>
        <div v-if="f.name == '~str_replace'" class="d-flex gap-2">
          /
          <fl-input v-model="f.regex" label="regex"/>
          /
          <fl-input v-model="f.flag" label="flag"/>
          <fl-input v-model="f.var" label="var"/>
          <fl-input v-model="f.varstr" label="var/str"/>
        </div>

        <div v-if="f.name == '~arr_explode'" class="d-flex gap-2">
          /
          <fl-input v-model="f.regex" label="regex"/>
          /
          <fl-input v-model="f.flag" label="flag"/>
          <fl-input v-model="f.var" label="var"/>
          <fl-input v-model="f.explode_id" label="explode_id"/>
        </div>

        <div v-if="f.name == '~array_explode'" class="d-flex gap-2">
          <fl-input v-model="f.regex" label="lua"/>
          <fl-input v-model="f.var" label="var"/>
          <fl-input v-model="f.explode_id" label="explode_id"/>
        </div>

        <div v-if="f.name == '~array_join'" class="d-flex gap-2">
          <fl-input v-model="f.sep" label="sep"/>
          <fl-input v-model="f.join_id" label="join_id"/>
          <fl-input v-model="f.var" label="var"/>
        </div>

        <div v-if="f.name == '~string_digits'" class="d-flex gap-2">
          <fl-input v-model="f.varstr" label="var/str"/>
          <fl-input v-model="f.var" label="var"/>
        </div>

        <div v-if="f.name == '~string_len'" class="d-flex gap-2">
          <fl-input v-model="f.varstr" label="var/str"/>
          <fl-input v-model="f.var" label="var"/>
        </div>

        <div v-if="f.name == '~array_set'" class="d-flex gap-2">
          <fl-input v-model="f.varstr" label="var/str"/>
          <fl-input v-model="f.arrel" label="arr_el"/>
        </div>

        <div v-if="f.name == '~array_get'" class="d-flex gap-2">
          <fl-input v-model="f.arrel" label="arr_el"/>
          <fl-input v-model="f.var" label="var"/>
        </div>

        <div v-if="f.name == '~array_size'" class="d-flex gap-2">
          <fl-input v-model="f.arr" label="arr"/>
          <fl-input v-model="f.var" label="var"/>
        </div>

        <hr class="bi-hr">

      </div>

      <icon-button @click="addFunction" icon="bi-plus-circle" title="Добавить"/>

    </fl-group>
  </fl-form>

</template>

<script>
/* eslint-disable vue/no-mutating-props */
import FlForm from "./components/FlForm";
import FlGroup from "./components/FlGroup";
import FlInput from "./components/FlInput";
import IconButton from "@/components/IconButton";
import FlSelect from "@/pages/flow/forms/components/FlSelect";
import {functionData} from "@/pages/flow/blocks";

export default {
  components: {FlSelect, IconButton, FlInput, FlGroup, FlForm},
  props: ["data"],

  methods: {
    addFunction() {
      this.data.functions.push(Object.assign({}, functionData))
    },

    updateSettings() {
      function convert(f) {
        switch (f.name) {
          case '~str_replace':
            return '"/' + f.regex + '/' + f.flag + '" , ' + f.var + ', ' + f.varstr;
          case '~arr_explode':
            return '"/' + f.regex + '/' + f.flag + '" , ' + f.var + ', ' + f.explode_id;
          case '~array_explode':
            return '"' + f.regex + '" , ' + f.var + ', ' + f.explode_id;
          case '~array_join':
            return '"' + f.sep + '" , ' + f.join_id + ', ' + f.var;
          case '~string_digits':
            return f.varstr + ', ' + f.var;
          case '~string_len':
            return f.varstr + ', ' + f.var;
          case '~array_set':
            return f.varstr + ', ' + f.arrel;
          case '~array_get':
            return f.arrel + ', ' + f.var;
          case '~array_size':
            return f.arr + ', ' + f.var;
        }
        throw Error('Unknown function');
      }

      this.data.settings = {
        variables: this.data.functions.reduce((o, f) => ({...o, [f.name]: convert(f)}), {})
      };

      this.error = Object.keys(this.data.settings.variables).length == this.data.functions.length ? '' : 'Нельзя использовать одинаковые типы функций в одном блоке.';
    },

  },

  computed: {
    functions() {
      return this.data.functions;
    }
  },

  created() {
    this.updateSettings();
  },

  watch: {
    functions: {
      deep: true,
      handler() {
        this.updateSettings();
      }
    }
  },


}
</script>

