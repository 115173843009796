<template>
  <table class="table table-hover">

    <thead>
    <tr>
      <th v-for="f in format" :key="f[0]" scope="col" class="text-nowrap">{{ f[1] }}</th>
    </tr>
    </thead>

    <tbody>
    <tr v-for="item in items" :key="item.id" @click="$emit('clickItem', item)" style="cursor: pointer">
      <td v-for="(f, index) in format" :key="item.id+'-'+index">{{ f[2](item) }}</td>
    </tr>
    </tbody>

  </table>

</template>

<script>
export default {
  props: [
    "items",
    "format",   // table format, [[column_field, column_title, (row_item)=>what_to_display ], ...]
  ],
  emits: ["clickItem"],
  data() {
    return {}
  },


}
</script>
