<template>
  <!--eslint-disable vue/no-mutating-props-->
  <fl-form>
    <fl-group name="Навигация">
      <div class="d-flex gap-2">
        <fl-input @keydown.enter="$emit('do-filter')" v-model="scenariosData.findWhat" :label="scenariosData.filteredItems?'Фильтр (применен)':'Фильтр'" class="flex-grow-1" :disabled="!!scenariosData.filteredItems"/>
        <icon-button @click="$emit('do-filter')" icon="bi-check-circle" class="fs-5 align-self-center" style="position: relative; top:0.6rem;" title="Применить"/>
        <icon-button @click="scenariosData.filteredItems=null;scenariosData.findWhat=''" icon="bi-x-circle" class="fs-5 align-self-center" style="position: relative; top:0.6rem;" title="Очистить" :active="!!scenariosData.filteredItems"/>
      </div>

      <fl-input type="checkbox" v-model="scenariosData.showStandard" label="Показывать стандартные" :disabled="!!scenariosData.filteredItems"/>

      <fl-input type="select" v-model="scenariosData.selectedScenario" label="Cценарии" :size="Math.min(10, Math.max(scenarios?.length||0, 5))">
        <option v-for="i in scenarios" :key="i.id" :value="i">
          {{ i.name }}
        </option>
      </fl-input>

      <fl-input type="select" label="Блоки" :size="Math.min(10, Math.max(items?Object.keys(items).length:0, 5))" v-model="selectedItem">
        <option v-for="i in items" :key="i.id" :value="i">
          {{ i.data.name }}
        </option>
      </fl-input>

    </fl-group>

    <fl-group name="Настройки">
      <fl-input type="checkbox" v-model="script.flow.common.tts" label="Глобальные настройки TTS"/>
      <fl-input type="checkbox" v-model="script.flow.common.asr" label="Глобальные настройки ASR"/>
    </fl-group>

    <fl-group v-if="scenariosData.selectedScenario?.user" name="Сценарий">
      <fl-input v-model="scenariosData.selectedScenario.name" label="Имя"/>
    </fl-group>
    <tts v-if="script.flow.common.tts" :data="script.flow.common"/>
    <asr v-if="script.flow.common.asr" :data="script.flow.common"/>

    <fl-group name="Настройки пользователя">
      <fl-input type="select" v-model="prefs.schema" label="Цветовая схема">
        <option value="default">По умолчанию</option>
        <option value="mono">Монохромная</option>
      </fl-input>
      <fl-input type="checkbox" v-model="prefs.show_grid" label="Показывать сетку"/>
      <fl-input type="checkbox" v-model="prefs.dark" label="Темная схема"/>
    </fl-group>

  </fl-form>

</template>

<script>
/* eslint-disable vue/no-mutating-props */
import FlForm from "./components/FlForm";
import FlGroup from "./components/FlGroup";
import FlInput from "./components/FlInput";
import Tts from "./subforms/Tts";
import Asr from "./subforms/Asr";
import IconButton from "@/components/IconButton";

export default {
  components: {IconButton, Tts, Asr, FlInput, FlGroup, FlForm},
  props: ["scenariosData", "script", "prefs"],
  emits: ["do-filter"],
  inject: ["editor"], // todo: prop?

  data() {
    return {
      selectedItem: null,
    }
  },

  computed: {

    scenarios() {
      return this.scenariosData.scenarios.filter(
          (scenario) => this.scenariosData.filteredItems ?
              this.scenariosData.filteredItems?.[scenario.id] ||
              this.scenariosData.selectedScenario?.id == scenario.id
              :
              this.scenariosData.showStandard ||
              !scenario.hiddable
      );
    },

    items() {
      if (this.scenariosData.filteredItems)
        return this.scenariosData.filteredItems[this.scenariosData.selectedScenario?.id];
      else return this.editorItems;
    },

    showStandard() {
      return this.scenariosData.showStandard;
    },
    editorItems() {
      return this.editor.value.items;
    },
    editorConnections() {
      return this.editor.value.connections;
    },
  },

  watch: {
    showStandard() {
      if (!this.showStandard && this.scenariosData.selectedScenario.hiddable)
        this.scenariosData.selectedScenario = this.scenariosData.scenarios[0];
    },

    selectedItem(i) {
      if (i) {
        this.editor.value.positionOnItem(i.id);
        this.$nextTick(() => this.selectedItem = null);
      }
    },

  },


}
</script>

