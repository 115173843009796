<template>
  <div class="filter">
    <div v-if="error" class="form-text text-danger">{{ error }}</div>
    <form @submit.prevent="onFilter(false)">
      <div class="d-flex gap-2">

        <fl-input v-if="show('domain_name') && !userDomain" v-model="filter.domain_name" :disabled="!options?.domain_name" type="select" label="Домен" @change="changeDomain" :style="showOptions?{}:{width:'200px'}" class="highlight">
          <option value="">--</option>
          <option v-for="i in options?.domain_name" :key="i" :value="i">{{ i }}</option>
        </fl-input>

        <fl-input v-if="show('dl_project_id') && !userProject" v-model="filter.dl_project_id" :disabled="!options?.dl_project_id" type="select" label="Проект" :style="showOptions?{}:{width:'200px'}">
          <option value="">--</option>
          <option v-for="i in options?.dl_project_id" :key="i" :value="i">{{ formatProject(i) }}</option>
        </fl-input>

        <div v-if="showOptions" class="d-flex gap-2">

          <fl-input v-if="show('destination_number')" v-model="filter.destination_number" :disabled="!options?.destination_number" type="select" label="Номер">
            <option value="">--</option>
            <option v-for="i in options?.destination_number" :key="i" :value="i">{{ i }}</option>
          </fl-input>

          <fl-input v-if="show('call_status')" v-model="filter.call_status_name" :disabled="!options?.call_status" type="select" label="Статус">
            <option value="">--</option>
            <option v-for="i in options?.call_status" :key="i.name" :value="i.name">{{ i.name_ru }}</option>
          </fl-input>

          <fl-input v-if="show('conversation_release_reason')" v-model="filter.conversation_release_reason_name" :disabled="!options?.conversation_release_reason" type="select" label="Завершил">
            <option value="">--</option>
            <option v-for="i in options?.conversation_release_reason" :key="i.name" :value="i.name">{{ i.name_ru }}</option>
          </fl-input>

          <fl-input v-if="show('dl_topic')" v-model="filter.dl_topic" :disabled="!options?.dl_topic" type="select" label="Тема">
            <option value="">--</option>
            <option v-for="i in options?.dl_topic" :key="i" :value="i">{{ i }}</option>
          </fl-input>

          <fl-input v-if="show('dl_result')" v-model="filter.dl_result" :disabled="!options?.dl_result" type="select" label="Результат">
            <option value="">--</option>
            <option v-for="i in options?.dl_result" :key="i" :value="i">{{ i }}</option>
          </fl-input>

        </div>

        <fl-input v-if="showRequested('t_from') && !timeMode" v-model="filter.t_from" ref="dFromRef" type="date" label="От" style="width:8rem"/>
        <fl-input v-if="showRequested('t_to')&& !timeMode" v-model="filter.t_to" ref="dToRef" type="date" label="До" style="width:8rem"/>

        <fl-input v-if="showRequested('t_from') && timeMode" v-model="filter.t_from" ref="dFromRef" type="datetime-local" label="От" style="width:12rem"/>
        <fl-input v-if="showRequested('t_to') && timeMode" v-model="filter.t_to" ref="dToRef" type="datetime-local" label="До" style="width:12rem"/>

        <fl-input v-if="showRequested('contains')" v-model="filter.contains" label="Искать"/>

        <fl-input v-if="showRequested('interval')" v-model="filter.interval" type="select" label="Интервал">
          <option value="year">Год</option>
          <option value="month">Месяц</option>
          <option value="week">Неделя</option>
          <option value="day">День</option>
          <option value="hour">Час</option>
        </fl-input>

        <div class="d-flex gap-2">
          <icon-button v-if="!isApplyEnlarged" @click="onFilter(false)" icon="bi-check-lg" class="fs-5 align-self-center" style="position: relative; top:0.6rem;" title="Применить"/>
          <icon-button v-else @click="onFilter(false)" icon="bi-check-lg" :active="true" class="fs-4 align-self-center" style="position: relative; top:0.6rem;" title="Применить и загрузить опции"/>
          <icon-button @click="onFilter('all')" icon="bi-x-lg" class="fs-5 align-self-center" style="position: relative; top:0.6rem;" title="Сбросить"/>
          <icon-button v-if="showOptions" @click="onFilter('vars')" icon="bi-trash" class="fs-5 align-self-center" style="position: relative; top:0.6rem;" title="Очистить"/>
          <icon-button @click="changeTimeMode" :icon="timeMode?'bi-calendar':'bi-clock'" class="fs-5 align-self-center" style="position: relative; top:0.6rem;" title="Режим ввода времени"/>
          <icon-button v-if="filter.domain_name && filter.dl_project_id" @click="changeSearchTranscripts" :active="filter.search_transcripts" icon="bi-chat-left-text" class="fs-5 align-self-center" style="position: relative; top:0.6rem;" title="Поиск по транскрипциям"/>
          <icon-button v-if="showOptions && show('variables') && variables && variables.length" @click="changeShowVars" :icon="showVars?'bi-caret-down':'bi-caret-right'" class="fs-5 align-self-center" style="position: relative; top:0.6rem;" title="Дополнительные фильтры"/>
          <span v-if="loading" class="spinner-grow spinner-grow-sm align-self-center" style="position: relative; top:0.6rem;"/>
        </div>

      </div>

      <div v-if="showOptions && show('variables') && variables && variables.length && showVars" class="d-flex gap-2">
        <fl-input
            v-for="v in variables"
            :key="v"
            v-model="filter[(v.select === true)?'variables':'variables_contains'][v.name]"
            :type="v.select===false?'text':(v.datalist?'datalist':'select')"
            :label="v.description"
        >
          <option v-if="!v.datalist" :value="undefined">--</option>
          <option v-for="o in options?.variables?.[v.name]" :key="o" :value="o"> {{ o }}</option>
        </fl-input>
      </div>
    </form>
  </div>

  <!--            :disabled="!options?.variables?.[v.name]"-->

</template>

<script>
import IconButton from "../../components/IconButton";
import FlInput from "../flow/forms/components/FlInput";
import {persistent} from "@/tstore";
import {http} from "@/misc/http";
import {mapGetters} from "vuex";
import {uuid4} from "@/misc/util";

const clean_filter = {
  domain_name: "",
  dl_project_id: "",
  destination_number: "",
  call_status_name: "",
  conversation_release_reason_name: "",
  dl_result: "",
  dl_topic: "",
  contains: "",
  t_from: "",
  t_to: "",
  interval: "day",
  variables: {},
  variables_contains: {},
  search_transcripts: false,
};

export default {
  components: {FlInput, IconButton},
  props: ["store", "show_what", "default_filter", "default_time_mode"],
  emits: ["filter"],

  // eslint-disable-next-line
  setup({store, default_filter, default_time_mode}) {
    return persistent(store, {
      options: null,
      error: "",
      loading: false,
      filter: JSON.parse(JSON.stringify(Object.assign({}, default_filter ? Object.assign(clean_filter, default_filter) : clean_filter))),
      timeMode: !!default_time_mode,
      populated: null,
      showVars: true,
    });
  },

  created() {
    if (this.populated) return;
    this.filter.domain_name = this.userDomain || "";
    this.filter.dl_project_id = this.userProject || "";
    this.loadOptions();
  },

  methods: {
    changeSearchTranscripts() {
      this.filter.search_transcripts = !this.filter.search_transcripts;
    },
    showRequested(what) {
      return this.show_what ? this.show_what[what] : what != 'interval';
    },
    showConfigured(what) {
      const conf = this.projectConfig?.filter?.default?.[what];
      return conf !== undefined ? conf : true;
    },

    show(what) {
      const showVarsRequested = [
        'destination_number',
        'call_status',
        'conversation_release_reason',
        'dl_topic',
        'dl_result',
        'variables'
      ].some(v => this.showRequested(v))

      switch (what) {
        case 'domain_name':
          return (
              this.showRequested('domain_name')
              || this.showRequested('dl_project_id')
              || showVarsRequested
          )

        case 'dl_project_id':
          return (
              this.showRequested('dl_project_id')
              || showVarsRequested
          )

        default:
          return this.showRequested(what) && this.showConfigured(what);

      }
    },


    formatProject(i) {
      return this.options?.codes?.[i]?.name ? this.options?.codes?.[i]?.name + ' (' + i + ')' : i
    },

    changeShowVars() {
      this.showVars = !this.showVars;
    },


    async loadOptions() {
      this.loading = true;
      this.populated = null;
      this.error = "";
      const update_uuid = uuid4();
      this.update_uuid = update_uuid;

      const domain_name = this.filter.domain_name;
      const dl_project_id = this.filter.dl_project_id;

      try {
        const options = await http.get('/calls/filter_options', {
          domain_name: domain_name,
          dl_project_id: dl_project_id,
        });
        if (this.update_uuid == update_uuid) {
          this.options = options;
          this.populated = {
            domain_name: this.filter.domain_name,
            dl_project_id: this.filter.dl_project_id,
          }
          this.loading = false;
        }
      } catch (e) {
        if (this.update_uuid == update_uuid) {
          this.error = e.message;
          this.loading = false;
        }
      }
    },

    discardOptions() {
      this.filter.destination_number = "";
      this.filter.dl_result = "";
      this.filter.dl_topic = "";
      this.filter.variables = {};
      this.filter.variables_contains = {};
      this.filter.call_status_name = "";
      this.filter.conversation_release_reason_name = "";
    },

    onFilter(clear) {

      if (clear) {
        this.filter = JSON.parse(JSON.stringify(Object.assign({}, clean_filter)));
        if (clear == 'all') {
          this.filter.domain_name = this.userDomain || "";
          this.filter.dl_project_id = this.userProject || "";
          this.options = null;
          this.loadOptions();
        } else {
          this.filter.domain_name = this.populated?.domain_name || "";
          this.filter.dl_project_id = this.populated?.dl_project_id || "";
          this.discardOptions();
          return;
        }
      } else {
        if (this.filter.domain_name != this.populated?.domain_name
            || this.filter.dl_project_id != this.populated?.dl_project_id) {
          this.discardOptions();
          this.loadOptions();
        }
      }

      if (!(this.filter.domain_name && this.filter.dl_project_id))
        this.filter.search_transcripts = false;

      const filter = Object.assign({}, this.filter);
      if (filter.t_from) {

        // valueAsDate возвращает момент 00:00 по UTC но в текущей таймзоне, т.е.
        // для москвы возвращается что то вроде 2022-01-20T03:00:00+03:00
        // getTimezoneOffset() в этом случае -180
        // нам надо получить значение 00:00 по локали (например Москве),
        // для этого getTimezoneOffset надо добавить к дате
        // дальше передаем это время в utc так как pydantic чо то глючит с недефолтной таймзоной

        // let date = this.$refs.dFromRef.$refs.input.valueAsDate;
        // date = new Date(date.valueOf() + (date.getTimezoneOffset() * 60000));
        // filter.t_from = date.toISOString();

        let date = new Date(this.filter.t_from);
        if (!this.timeMode)
          date = new Date(date.valueOf() + (date.getTimezoneOffset() * 60000));
        // todo new Date(new Date().toDateString()).toISOString()
        filter.t_from = date.toISOString();

      } else {
        delete filter.t_from;
      }

      if (filter.t_to) {
        // let date = this.$refs.dToRef.$refs.input.valueAsDate;
        // date = new Date(date.valueOf() + (date.getTimezoneOffset() * 60000));
        // filter.t_to = date.toISOString();

        let date = new Date(this.filter.t_to);
        if (!this.timeMode)
          date = new Date(date.valueOf() + (date.getTimezoneOffset() * 60000));
        filter.t_to = date.toISOString();
      } else {
        delete filter.t_to;
      }

      // filter._projectConfig = this.projectConfig;
      this.$emit('filter', filter);
    },

    changeTimeMode() {
      this.timeMode = !this.timeMode;
      this.filter.t_from = "";
      this.filter.t_to = "";
    },


    changeDomain() {
      if (this.filter.domain_name == this.populated?.domain_name) return;
      this.filter.dl_project_id = "";
      this.loadOptions();
    },

    // changeProject() {
    //   if (this.filter.domain_name == this.populated?.domain_name
    //       && this.filter.dl_project_id == this.populated?.dl_project_id) return;
    //   this.clearDynamicFields(false);
    //   this.loadOptions();
    // },


  },

  computed: {

    isApplyEnlarged() {
      return !this.showOptions && this.filter.domain_name && this.filter.dl_project_id;
      // return false;
    },
    projectConfig() {
      if (!this.populated?.dl_project_id) return;
      let projectConfig = this.options?.codes?.[this.populated.dl_project_id]?.config;
      if (projectConfig) projectConfig = JSON.parse(projectConfig);
      return projectConfig;
    },

    variables() {
      return this.projectConfig?.filter?.variables;
    },

    showOptions() {
      if (!(this.populated?.domain_name && this.populated?.dl_project_id)) return false;
      return this.populated.domain_name == this.filter.domain_name
          && this.populated.dl_project_id == this.filter.dl_project_id;
    },
    ...mapGetters("auth", ["userDomain", "userProject"])
  },
}
</script>

<style scoped>
.filter {
  z-index: 1000;
  position: -webkit-sticky;
  position: sticky;
  top: 2rem;
  background-color: var(--bs-body-bg)
}

.dark .filter {
  z-index: 1000;
  position: -webkit-sticky;
  position: sticky;
  top: 2rem;
  background-color: var(--bs-body-bg-alt)
}
</style>