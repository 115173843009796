<template>
  <page-header :title="title" icon="bi-person-circle" :back="true"/>

  <f-form ref="form" :error="error" :new-mode="id=='new'" @send="onSend" :nondeletable="true">
    <template v-slot:default="form">

      <f-input v-model="item.login" label="Логин" :disabled="true"/>
      <f-input v-model="item.fname" label="Имя" w="3" :disabled="!form.edit"/>
      <f-input v-model="item.lname" label="Фамилия" w="3" :disabled="!form.edit"/>
      <f-input v-model="item.email" label="Email" w="3" :disabled="!form.edit"/>
      <f-input v-model="item.phone" label="Телефон" w="3" :disabled="!form.edit"/>

      <div v-if="form.edit" class="col-12">
        <small>Оставьте поля пустыми или введите данные, чтобы поменять пароль. </small>
      </div>

      <f-input v-if="form.edit" v-model="password1" type="password" label="Пароль" w="6" :disabled="!form.edit"/>
      <f-input v-if="form.edit" v-model="password2" type="password" label="Пароль повторно" w="6" :disabled="!form.edit"/>

    </template>
  </f-form>
</template>

<script>
import {http} from "@/misc/http";
import FInput from "@/components/FInput";
import {format_datetime} from "@/misc/util";
import PageHeader from "@/components/PageHeader";
import FForm from "@/components/FForm";
import {updateItem} from "../../misc/util";
import store from "../../store";

export default {
  components: {FForm, PageHeader, FInput},
  data() {
    return {
      id: null,
      item: {
        login: "user",
        email: "",
        fname: "",
        lname: "",
        phone: "",
      },
      error: "",
      projects: null,
      roles: null,
      password1: "",
      password2: "",
    }
  },

  created() {
    this.id = this.$store.getters["auth/userId"];
    this.updateItem(http.get('/profiles/' + this.id));
  },

  methods: {
    onSend() {
      this.updateItem((async () => {
        if (this.isNew && !this.password1) throw Error("Должен быть задан пароль для нового пользователя.");
        if (this.password1 && this.password1 != this.password2) throw Error("Пароли не совпадают.");
        this.item.password = this.password1;
        const result= await http.post("/profiles/"  + this.id, this.item);
        await this.$store.dispatch("auth/loadUser");
        return result;
      })(), "profile", true);
   },

    format_datetime,
    updateItem

  },

  computed: {
    title() {
      return this.item.fname + ' ' + this.item.lname;
    },
  },


}
</script>

