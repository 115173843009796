<!--eslint-disable vue/no-mutating-props-->
<template>
  <fl-form>
    <fl-group name="Блок 'Возврат'">
      <fl-input v-model="data.name" label="Название блока"/>
      <fl-input type="textarea" v-model="data.comment" label="Комментарий"/>
      <fl-input v-if="scenariosData?.selectedScenario?.intent" type="select" v-model="data.settings.return_type" label="Возврат">
        <option value="default">По умолчанию</option>
        <option value="same">Тот же блок</option>
        <option value="prev">Предыдущий блок</option>
        <option value="name">По названию блока</option>
      </fl-input>
      <fl-input v-if="scenariosData?.selectedScenario?.intent && data.settings.return_type=='name'" v-model="data.settings.return_name" label="Название блока возврата"/>
    </fl-group>
  </fl-form>

</template>

<script>
import FlForm from "./components/FlForm";
import FlGroup from "./components/FlGroup";
import FlInput from "./components/FlInput";

export default {
  components: {FlInput, FlGroup, FlForm},
  props: ["data", "scenariosData"],


}
</script>

