<template>
  <page-header title="Обзвон" icon="bi-phone-vibrate">
    <icon-button icon="bi-plus-circle fs-2" @click="$router.push({name: 'camp', params: {id: 'new'}})"/>
  </page-header>
  <live-table
      url="/camps"
      @click-item="$router.push({name: 'camp', params: {id: $event.item.id}})"
      store="camps" :reload="true"
      :format="[
        ['id','ID', (i)=>i.id],
        ['name','Название', (i)=>i.name],
        ['enabled','Активирован', (i)=>i.enabled?'Да':'Нет'],
      ]"
  />

</template>

<script>
import LiveTable from "@/components/LiveTable";
import PageHeader from "@/components/PageHeader";
import {format_datetime} from '@/misc/util';
import IconButton from "@/components/IconButton";

export default {
  components: {IconButton, PageHeader, LiveTable},
  methods: {
    format_datetime
  }
}
</script>

