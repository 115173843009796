function sleep(t) {
  return new Promise((resolve) => setTimeout(resolve, t))
}

function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

/* Port of strftime() by T. H. Doan (https://thdoan.github.io/strftime/)
 *
 * Day of year (%j) code based on Joe Orost's answer:
 * http://stackoverflow.com/questions/8619879/javascript-calculate-the-day-of-the-year-1-366
 *
 * Week number (%V) code based on Taco van den Broek's prototype:
 * http://techblog.procurios.nl/k/news/view/33796/14863/calculate-iso-8601-week-and-year-in-javascript.html
 */
function strftime(sFormat, date) {
  if (!(date instanceof Date)) date = new Date();
  var nDay = date.getDay(),
    nDate = date.getDate(),
    nMonth = date.getMonth(),
    nYear = date.getFullYear(),
    nHour = date.getHours(),
    aDays = ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'],
    aMonths = ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
    aDayCount = [0, 31, 59, 90, 120, 151, 181, 212, 243, 273, 304, 334],
    isLeapYear = function () {
      return (nYear % 4 === 0 && nYear % 100 !== 0) || nYear % 400 === 0;
    },
    getThursday = function () {
      var target = new Date(date);
      target.setDate(nDate - ((nDay + 6) % 7) + 3);
      return target;
    },
    zeroPad = function (nNum, nPad) {
      return ((Math.pow(10, nPad) + nNum) + '').slice(1);
    };
  return sFormat.replace(/%[a-z]/gi, function (sMatch) {
    return (({
      '%a': aDays[nDay].slice(0, 3),
      '%A': aDays[nDay],
      '%b': aMonths[nMonth].slice(0, 3),
      '%B': aMonths[nMonth],
      '%c': date.toUTCString(),
      '%C': Math.floor(nYear / 100),
      '%d': zeroPad(nDate, 2),
      '%e': nDate,
      '%F': date.toISOString().slice(0, 10),
      '%G': getThursday().getFullYear(),
      '%g': (getThursday().getFullYear() + '').slice(2),
      '%H': zeroPad(nHour, 2),
      '%I': zeroPad((nHour + 11) % 12 + 1, 2),
      '%j': zeroPad(aDayCount[nMonth] + nDate + ((nMonth > 1 && isLeapYear()) ? 1 : 0), 3),
      '%k': nHour,
      '%l': (nHour + 11) % 12 + 1,
      '%m': zeroPad(nMonth + 1, 2),
      '%n': nMonth + 1,
      '%M': zeroPad(date.getMinutes(), 2),
      '%p': (nHour < 12) ? 'AM' : 'PM',
      '%P': (nHour < 12) ? 'am' : 'pm',
      '%s': Math.round(date.getTime() / 1000),
      '%S': zeroPad(date.getSeconds(), 2),
      '%u': nDay || 7,
      '%V': (function () {
        var target = getThursday(),
          n1stThu = target.valueOf();
        target.setMonth(0, 1);
        var nJan1 = target.getDay();
        if (nJan1 !== 4) target.setMonth(0, 1 + ((4 - nJan1) + 7) % 7);
        return zeroPad(1 + Math.ceil((n1stThu - target) / 604800000), 2);
      })(),
      '%w': nDay,
      '%x': date.toLocaleDateString(),
      '%X': date.toLocaleTimeString(),
      '%y': (nYear + '').slice(2),
      '%Y': nYear,
      '%z': date.toTimeString().replace(/.+GMT([+-]\d+).+/, '$1'),
      '%Z': date.toTimeString().replace(/.+\((.+?)\)$/, '$1')
    }[sMatch] || '') + '') || sMatch;
  });
}

function format_datetime(iso_date, onlyDate) {
  if (!iso_date) return '';
  const date = new Date(iso_date);
  return onlyDate ? strftime("%d.%m.%Y", date) : strftime("%d.%m.%Y %H:%M:%S", date);
}

function format_datetime_for_filename(iso_date, onlyDate) {
  let s = format_datetime(iso_date, onlyDate).replaceAll('.', '-').replaceAll(' ', '_').replaceAll(':', '');
  if (!onlyDate)
    s = s.slice(0, -2);
  return s;
}


function getFileName(path) {
  return path.split('\\').pop().split('/').pop();
}

async function updateItem(promise, route = null, accept = false) {
  this.loading = true;
  try {
    const result = await promise;
    if (result) this.item = result; // do not assign if DELETE called
    this.error = "";
    this.loading = false;
    if (route) {
      await this.$router.replace({name: route, params: {id: this.item.id}});
      if (accept) this.$refs.form.accept(); // ну и фигня же переделать нафик это все todo
    }
    return true;
  } catch (e) {
    this.error = e.message;
    this.loading = false;
    return false;
  }
}

function uuid4() {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  );
}

function clickDownload(blob, fn) {
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  fn = fn.replaceAll(' ', '_').replaceAll(/[^\d\w-_а-яА-Я@.]/g, '');
  link.download = fn;
  link.click();
  link.remove();
}

function clickDownloadJson(o, fn) {
  const blob = new Blob([JSON.stringify(o, null, 4)], {
    type: 'application/json'
  });
  clickDownload(blob, fn);
}

function escape(s, delim = ';') {
  s = '' + s;
  return s.includes(delim) ?
    '"' + s.replace('"', '""') + '"'
    :
    s
}

function makeCSV(data, column_names) {
  const header = column_names?.length ? column_names.map((s) => escape(s)).join(";") + "\n" : "";
  return header + data.map(i => i.map((s) => escape(s)).join(";")).join("\n");
}


function clickDownloadCSV(fileName, content) {
  const blob = new Blob(['\uFEFF', content], {
    type: 'text/csv'
  });
  clickDownload(blob, fileName);
}


function selectFile(onSelect) {
  const input = document.createElement('input');
  input.type = 'file';
  input.onchange = e => {
    const file = e.target.files[0];
    onSelect(file);
  }
  input.click();
  input.remove();
}

function readTextFile(file, onRead) {
  const reader = new FileReader();
  reader.readAsText(file, 'UTF-8');
  reader.onload = readerEvent => {
    const content = readerEvent.target.result;
    onRead(content);
  }
}

function toIsoString(date) {
  const tzo = -date.getTimezoneOffset(),
    dif = tzo >= 0 ? '+' : '-',
    pad = function (num) {
      var norm = Math.floor(Math.abs(num));
      return (norm < 10 ? '0' : '') + norm;
    };

  return date.getFullYear() +
    '-' + pad(date.getMonth() + 1) +
    '-' + pad(date.getDate()) +
    'T' + pad(date.getHours()) +
    ':' + pad(date.getMinutes()) +
    ':' + pad(date.getSeconds()) +
    dif + pad(tzo / 60) +
    ':' + pad(tzo % 60);
}


function replaceKeyReq(o, key, val) {
  if (o[key] !== undefined) o[key] = Object.assign({}, val);
  for (const k in o)
    if (typeof o[k] === 'object')
      replaceKeyReq(o[k], key, val);
}

function removeReactivity(o) {
  return JSON.parse(JSON.stringify(o));
}

function truncDate(date_, by, utc) {
  // by: hour, day, month
  const date = new Date(date_);
  switch (by) {
    case 'hour':
      date.setMinutes(0, 0, 0);
      break;
    case 'day':
      if (utc)
        date.setUTCHours(0, 0, 0, 0);
      else
        date.setHours(0, 0, 0, 0);
      break;
    case 'month':
      if (utc) {
        date.setUTCDate(1);
        date.setUTCHours(0, 0, 0, 0);
      } else {
        date.setDate(1);
        date.setHours(0, 0, 0, 0);
      }
      break;

    default:
      throw new Error('Bad truncate by.')
  }

  return date;
}

export {
  validateEmail,
  sleep,
  strftime,
  format_datetime,
  getFileName,
  updateItem,
  uuid4,
  clickDownload,
  clickDownloadJson,
  selectFile,
  readTextFile,
  makeCSV,
  clickDownloadCSV,
  toIsoString,
  replaceKeyReq,
  removeReactivity,
  truncDate,
  format_datetime_for_filename
}
