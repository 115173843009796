<!--eslint-disable vue/no-mutating-props-->
<template>
  <fl-form>
    <fl-group name="Блок 'Подсценарий'">
      <fl-input v-model="data.name" label="Название блока"/>
      <fl-input type="textarea" v-model="data.comment" label="Комментарий"/>
      <fl-input v-if="supported" v-model="data.settings.scenarioId" type="select" label="Сценарий">
        <option v-for="s in scenarios" :key="s.id" :value="s.id"> {{ s.name }}</option>
      </fl-input>
      <div v-else class="form-text">Вложенные сценарии не поддерживаются.</div>
      <div class="form-text">Воспроизводит выбранный подсценарий. В переходах можно задать логику проверки переменных.</div>
    </fl-group>
  </fl-form>

</template>

<script>
import FlForm from "./components/FlForm";
import FlGroup from "./components/FlGroup";
import FlInput from "./components/FlInput";

export default {
  components: {FlInput, FlGroup, FlForm},
  props: ["data", "scenariosData"],

  computed: {
    scenarios() { // todo: по идее в режиме редактирования подсценария блока подсценарий не будет и логику можно упростить
      const result = [];
      for (const i of this.scenariosData.scenarios) {
        if (i.user && i != this.scenariosData.selectedScenario)
          result.push(i);
      }
      return result;
    },

    supported() {
      // return !this.scenariosData.selectedScenario.user;
      return true;

    },

  },
}
</script>

