import {reactive} from "vue"

const tstore = {};

function persistent(store, data, permanent) {
  if (!store) return reactive(data);
  if (!tstore[store]) {
    if (permanent) {
      const local_data = localStorage.getItem(store);
      if (local_data) {
        data = Object.assign(data, JSON.parse(local_data));
      }
    }
    tstore[store] = reactive(data);
  }
  return tstore[store];
}

function clear_tstore() {
  for (const i in tstore) delete tstore[i];
}

function update_local(store) {
  const data = tstore[store];
  if (data) {
    localStorage.setItem(store, JSON.stringify(data));
  }
}

export {tstore, persistent, clear_tstore, update_local};

