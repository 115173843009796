<template>
  <!--      :class="selected?['border-primary','selected']:hovered?['shadow-sm', 'border-warning']:'shadow-sm'"-->
  <div
      class="flow-item rounded-3 px-2 py-1"
      style="position: absolute;white-space: nowrap;transform: translate(-50%, -50%)"
      :style="{
        'left': x+'px',
        'top': y+'px',
        'border': color + ' solid 1px',
        'border-left': color + ' solid 6px',
        'box-shadow': (selected || hovered)?'0 0 0.5rem ' + (shadow||color) : '',

      }"
      ref="main"
      @mousedown.prevent="onMouseDown"
      @mousemove="onMouseMove"
      @click="onClick"
      @mouseenter="onMouseEnter"
      @mouseover="onMouseOver"
      @mouseleave="onMouseLeave"
  >

    <span :style="{color: color}"><i :class="['bi', 'bi-'+icon]"/>&nbsp;&nbsp;{{ data.name }} </span>
  </div>
</template>

<script>
import {Tooltip} from "bootstrap";

export default {
  props: ['id', 'icon', 'data', 'x', 'y', 'selected', 'hovered', 'color', 'shadow'],
  emits: ['move-item', 'select-item', 'hover-item'],
  data() {
    return {
      move: null
    }
  },

  created() {
    this.timer = null;
    this.tooltip = null;
  },

  beforeUnmount() {
    this.disarmTooltip();
  },

  methods: {

    armTooltip() {
      if (!this.tooltip) {
        this.tooltip = new Tooltip(this.$refs.main, {
          trigger: 'manual',
          title: this.data.comment,
        });
      }

      if (this.timer) clearTimeout(this.timer);
      this.timer = setTimeout(() => this.tooltip.show(), 1000);
    },

    disarmTooltip() {
      clearTimeout(this.timer);
      this.timer = null;

      if (this.tooltip) {
        this.tooltip.hide();
        this.tooltip = null;
      }
    },

    onMouseDown(event) {
      if (event.button != 0) return;
      this.move = {x: event.clientX, y: event.clientY}
      event.stopPropagation();
    },
    onMouseMove() {
      if (this.move) {
        this.disarmTooltip();
        this.$emit('move-item', {'id': this.id, ...this.move});
        this.move = null;
      }
    },

    onClick() {
      this.disarmTooltip();
      if (this.move) {
        this.$emit('select-item', {'id': this.id});
        this.move = null;
      }
    },

    getDims() {
      return {
        w: this.$refs.main.offsetWidth,
        h: this.$refs.main.offsetHeight,
      }
    },

    onMouseEnter() {
      this.$emit('hover-item', {'id': this.id, hover: true});
    },

    onMouseOver() {
      if (!this.selected) this.armTooltip();
    },

    onMouseLeave() {
      this.$emit('hover-item', {'id': this.id, hover: false})
      this.disarmTooltip();
    },
  },

}
</script>

<style scoped>

.flow-item {
  background-color: #f8f9fa;
}

html.dark .flow-item {
  background-color: #222222;
}

</style>