<template>
  <div class="container mt-5">
    <form class="row g-2 col-4 mx-auto">
      <h3 class="text-center"><i class="bi bi-lock"/>&nbsp;Войти</h3>
      <small v-if="!!error" class="text-danger text-center">{{ error }}</small>
      <f-input v-model="username" label="Логин"/>
      <f-input v-model="password" label="Пароль" type="password"/>
      <div class="text-center">
        <button type="submit" class="btn btn-primary px-5 m-1" :disabled="!isValid || isLoading" @click.prevent="signIn">
          <span v-if="isLoading" class="spinner-border spinner-border-sm"></span>
          Войти
        </button>
      </div>
      <div class="text-center mt-5 fw-lighter">
        Copyright © Optimal City Technologies. v{{ version }}
      </div>
    </form>
  </div>

</template>

<script>
import {mapActions} from "vuex";
import FInput from "@/components/FInput";
import {clear_tstore} from "../../tstore";
import {version} from "../../../package.json"

export default {
  name: "AuthForm",
  components: {FInput},
  props: ["redirect"],
  data() {
    return {
      username: '',
      password: '',
      loading: false,
      error: '',
    }
  },

  computed: {
    isValid() {
      return this.username && this.password;
    },
    isLoading() {
      return this.loading;
    },
    version() {
      return version;
    },
  },

  created() {
    clear_tstore(); // хз где это правильно сделать, так как нас сюда выбрасывает то тут
  },

  methods: {
    async signIn() {

      this.loading = true;

      try {
        await this.login({
          username: this.username,
          password: this.password
        });
        await this.$router.replace(this.redirect ? {path: this.redirect} : {name: "calls"});
      } catch (e) {
        this.error = e.message;
      }

      this.loading = false;

    },

    ...mapActions('auth', ['login'])

  }


}
</script>
