<!--eslint-disable vue/no-mutating-props-->
<template>

  <div v-if="type=='audio'">
    <fl-select
        v-model="audio_settings.audio_record_id"
        :options="Object.keys(script.files).sort()"
        label="Аудиофайл"
    />
  </div>

  <fl-input v-if="type=='audio' || type=='tts'" type="textarea" v-model="message_params.text" :label="type=='tts'?'Фраза':'Транскрибация'"/>
  <fl-input v-if="type=='tts'" type="checkbox" v-model="message_params.ssml" label="SSML"/>

</template>

<script>
import FlInput from "../components/FlInput";
import FlSelect from "../components/FlSelect";

export default {
  components: {FlSelect, FlInput},
  props: ["type", "message_params", "audio_settings", "script"],
}
</script>
