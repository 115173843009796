import {createRouter, createWebHistory} from "vue-router"
import store from "../store"
import AuthForm from "../pages/auth/AuthForm"
import MainScreen from "../navigation/MainScreen"
import Calls from "../pages/calls/Calls"
import Users from "../pages/users/Users"
import User from "../pages/users/User"
import Projects from "../pages/projects/Projects"
import Project from "../pages/projects/Project"
import Camps from "../pages/camps/Camps"
import Camp from "../pages/camps/Camp"
import Call from "../pages/calls/Call"
import Flow from "../pages/flow/Flow"
import Scripts from "../pages/scripts/Scripts";
import Script from "../pages/scripts/Script";
import Templates from "../pages/templates/Templates";
import Template from "../pages/templates/Template";
import Reports from "../pages/reports/Reports";
import Report from "../pages/reports/Report";
import Profile from "../pages/users/Profile";
import Tasks from "@/pages/tasks/Tasks";
import Codes from "@/pages/codes/Codes";
import Code from "@/pages/codes/Code";
import BlackLists from "@/pages/blacklists/BlackLists";
import BlackList from "@/pages/blacklists/BlackList";
import BlackNumbers from "@/pages/blacknumbers/BlackNumbers";

// noinspection JSCheckFunctionSignatures
const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/", name: "main", component: MainScreen, children: [
        {path: "/", redirect: {name: "calls"}},
        {path: 'calls', name: "calls", component: Calls},
        {path: 'calls/:id', name: "call", component: Call, props: true},
        {
          path: 'rcalls/:id', name: "rcall", component: Call,
          props: route => ({
            id: route.params.id,
            callsStore: "report"
          })

        },
        {path: 'reports', name: "reports", component: Reports},
        {path: 'reports/:id', name: "report", component: Report, props: true},
        {path: 'users', name: "users", component: Users},
        {path: 'users/:id', name: "user", component: User, props: true},
        {path: 'templates', name: "templates", component: Templates},
        {path: 'templates/:id', name: "template", component: Template, props: true},
        {path: 'projects', name: "projects", component: Projects},
        {path: 'projects/:id', name: "project", component: Project, props: true},
        {path: 'tasks', name: "tasks", component: Tasks},
        {path: 'camps', name: "camps", component: Camps},
        {path: 'camps/:id', name: "camp", component: Camp, props: true},
        {path: 'scripts', name: "scripts", component: Scripts},
        {path: 'scripts/:id', name: "script", component: Script, props: true},
        {path: 'profile', name: "profile", component: Profile},
        {path: 'codes', name: "codes", component: Codes},
        {path: 'codes/:id', name: "code", component: Code, props: true},
        {path: 'blacklists', name: "blacklists", component: BlackLists},
        {path: 'blacklist/:id', name: "blacklist", component: BlackList, props: true},
        {path: 'blacknumbers', name: "blacknumbers", component: BlackNumbers},
      ]
    },
    {path: '/flow/:id', name: "flow", component: Flow, props: true},
    {path: "/signin", name: "signin", component: AuthForm, props: route => ({redirect: route.query.redirect})},
    {path: "/:notFound(.*)", redirect: {name: "calls"}},
  ]
})

router.beforeEach((to, from, next) => {
  if (!store.getters["auth/isLoggedIn"] && to.name != 'signin') {
    next({name: "signin", query: {redirect: window.location.pathname}});
  } else if (store.getters["auth/isLoggedIn"] && to.name == 'signin') {
    next({name: "calls"});
  } else {
    next();
  }
})

export default router;

