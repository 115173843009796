<template>
  <page-header :title="title" icon="bi-phone-vibrate" :back="true"/>

  <f-form ref="form" :error="error" :new-mode="id=='new'" @send="onSend" @delete="onDelete">
    <template v-slot:default="form">

      <f-input v-model="item.name" label="Название" :disabled="!form.edit"/>
      <f-input v-if="!isNew" v-model="item.dialstring" label="Формат набора" w="6" :disabled="!form.edit"/>
      <f-input v-if="!isNew" v-model="item.terminate" label="Приложение" w="6" :disabled="!form.edit"/>
      <!--      <f-input v-if="!isNew" v-model="item.originate_timeout" label="Время набора (сек.)" w="12" :disabled="!form.edit"/>-->


      <div v-if="!isNew">
        <div class="form-check form-switch">
          <input class="form-check-input" type="checkbox" v-model="item.enabled" :disabled="!form.edit">
          <label class="form-check-label">Активирован</label>
        </div>
        <label class="form-label">Состояние обзвона:</label>
        <div class="form-text">{{ item.state }}</div>
        <label class="form-label">Статистика:</label>
        <div class="form-text">{{ item.stat }}</div>
        <label class="form-label">Загруженные номера:</label>
        <chips
            :data="item.files"
            :nondeletable="true"
            :disabled="!form.edit"
            :format="i=>(i.includes('\\')?i.split('\\').at(-1):i.split('/').at(-1)).substring(37)"
        />
<!--        <div v-for="f in item.files" :key="f" class="form-text">{{ f }}</div>-->
      </div>
    </template>
    <template v-slot:actions="form">
      <button v-if="!isNew && form.edit" @click.prevent="onUpload" class="btn btn-outline-primary px-5 m-1">Загрузить файл</button>
    </template>
  </f-form>
  <!--{{item}}-->
</template>

<script>
import {http} from "@/misc/http";
import FInput from "@/components/FInput";
import {format_datetime} from "@/misc/util";
import PageHeader from "@/components/PageHeader";
import FForm from "@/components/FForm";
import {selectFile, updateItem} from "../../misc/util";
import Chips from "../../components/Chips";

export default {
  components: {Chips, FForm, PageHeader, FInput},
  props: ["id"],
  data() {
    return {
      item: {
        name: "Новый",
        enabled: false,
        dialstring: "sofia/gateway/${distributor(mera_list)}/9#",
        terminate: "loopback/5011/10.20.10.118",
        // dialstring: "loopback/6#/10.20.10.118",
        // terminate: "loopback/5011/10.20.10.118",
        originate_timeout: 60,
        files: null,
        state: "",
      },
      error: "",
    }
  },

  timer: null,

  async created() {
    if (!this.isNew) {
      if (await this.updateItem(http.get('/camps/' + this.id))) {
        this.timer = setInterval(async () => {
              try {
                const item = await http.get('/camps/' + this.id);
                this.item.state = item.state;
                this.item.stat = item.stat;
              } catch (e) {
                this.error = e.message;
              }
            }, 5000
        )
      }
    }
  },

  beforeUnmount() {
    if (this.timer) clearInterval(this.timer);
  },

  methods: {
    onSend() {
      this.updateItem(
          http.post("/camps" + (this.id == "new" ? '' : '/' + this.id), this.item),
          "camp", true
      );
    },

    onDelete() {
      this.updateItem(
          http.delete("/camps/" + this.id),
          "camps"
      );
    },

    onUpload() {
      selectFile(file => {
        this.updateItem(
            http.post_form('/camps/' + this.id + '/upload', {'file': file})
        )
      });
    },
    format_datetime,
    updateItem
  },

  computed: {
    title() {
      return "Обзвон \"" + this.item.name + "\"";
    },
    isNew() {
      return this.id == "new";
    },
  },


}
</script>

